import { NucleusVault } from '@/types/nucleus'
import {
  TOKEN_LIST_APXETH,
  TOKEN_LIST_EARN_BTC,
  TOKEN_LIST_EARN_ETH,
  TOKEN_LIST_ETH,
  TOKEN_LIST_PXETH,
  TOKEN_LIST_RSWETH,
  TOKEN_LIST_SWETH,
  TOKEN_LIST_WBTC,
  TOKEN_LIST_WETH,
  TOKEN_LIST_WSTETH,
  TOKEN_LIST_SWBTC,
  TOKEN_LIST_CBBTC,
  TOKEN_LIST_FBTC,
  TOKEN_LIST_PUMPBTC,
  TOKEN_LIST_TBTC,
  TOKEN_LIST_SOLV_BTC,
  TOKEN_LIST_LBTC,
  TOKEN_LIST_LORENZO_STBTC,
  TOKEN_LIST_BINANCE_BTCB,
} from './tokens'
import { StrategyProvider } from '@/types/vaults'
import nucleusLogo from '@/assets/images/nucleus_logo_64x64.png'

export const NUCLEUS_ATOMIC_QUEUE_ADDRESS =
  '0xc7287780bfa0c5d2dd74e3e51e238b1cd9b221ee'

export const NUCLEUS_VAULT_EARN_BTC: NucleusVault = {
  name: 'Earn BTC Vault',
  vaultToken: TOKEN_LIST_EARN_BTC,
  baseAsset: TOKEN_LIST_WBTC,
  depositAssets: [
    TOKEN_LIST_WBTC,
    TOKEN_LIST_SWBTC,
    TOKEN_LIST_CBBTC,
    TOKEN_LIST_FBTC,
    TOKEN_LIST_PUMPBTC,
    TOKEN_LIST_TBTC,
    TOKEN_LIST_LBTC,
    TOKEN_LIST_SOLV_BTC,
    TOKEN_LIST_LORENZO_STBTC,
    TOKEN_LIST_BINANCE_BTCB,
  ],
  withdrawAssets: [
    TOKEN_LIST_WBTC,
    TOKEN_LIST_SWBTC,
    TOKEN_LIST_CBBTC,
    TOKEN_LIST_FBTC,
    TOKEN_LIST_PUMPBTC,
    TOKEN_LIST_TBTC,
    TOKEN_LIST_LBTC,
    TOKEN_LIST_SOLV_BTC,
    TOKEN_LIST_LORENZO_STBTC,
    TOKEN_LIST_BINANCE_BTCB,
  ],
  accountantAddress: '0x6bBf58f1A95D22f497fD2e7f640fAE94481b1A08',
  rolesAuthorityAddress: '0x05FAE28773ab9dAfD5C8997AcEe5099fa0D1f219',
  tellerAddress: '0x1DA9ceFA0F46b2087b2cF7dbF9E62Dd94076Ece1',
}

export const NUCLEUS_VAULT_EARN_ETH: NucleusVault = {
  name: 'Earn ETH Vault',
  vaultToken: TOKEN_LIST_EARN_ETH,
  baseAsset: TOKEN_LIST_ETH,
  depositAssets: [
    TOKEN_LIST_WETH,
    TOKEN_LIST_SWETH,
    TOKEN_LIST_RSWETH,
    TOKEN_LIST_WSTETH,
    TOKEN_LIST_PXETH,
    TOKEN_LIST_APXETH,
  ],
  withdrawAssets: [
    TOKEN_LIST_WETH,
    TOKEN_LIST_SWETH,
    TOKEN_LIST_RSWETH,
    TOKEN_LIST_WSTETH,
    TOKEN_LIST_PXETH,
    TOKEN_LIST_APXETH,
  ],
  accountantAddress: '0x411c78BC8c36c3c66784514f28c56209e1DF2755',
  rolesAuthorityAddress: '0x1F5dddF627C3796a589c6271b36A570f18d3a016',
  tellerAddress: '0x685aDb4797fb38D4Fc4a69750aa048B398160429',
}

export const NUCLEUS_STRATEGY_PROVIDER_NUCLEUS_EARN: StrategyProvider = {
  name: 'Nucleus Earn',
  link: 'https://www.nucleusearn.io/',
  logoURI: nucleusLogo,
}
