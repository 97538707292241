import { BigNumber } from 'ethers'
import { parseEther } from 'ethers/lib/utils'

export function airdropOption2Vesting({
  totalAmount: totalAmountWithLoyalty,
  loyaltyAmount,
}: {
  totalAmount: BigNumber
  loyaltyAmount: BigNumber
}) {
  const totalAmount = totalAmountWithLoyalty.sub(loyaltyAmount)
  const oneQuarter = parseEther('0.25')
  const vestingSize = oneQuarter.mul(totalAmount).div(parseEther('1'))

  return {
    firstVestingSize: vestingSize,
    secondVestingSize: vestingSize,
  }
}

export function airdropOption1Vesting({
  totalAmount: totalAmountWithLoyalty,
  vestingTier,
  loyaltyAmount,
}: {
  totalAmount: BigNumber
  vestingTier: number
  loyaltyAmount: BigNumber
}) {
  const totalAmount = totalAmountWithLoyalty.sub(loyaltyAmount)

  let firstVestingSize: BigNumber
  let secondVestingSize: BigNumber
  let thirdVestingSize: BigNumber

  if (vestingTier === 1) {
    const thirty = parseEther('0.3')
    const twenty = parseEther('0.2')

    const thirtyPercent = thirty.mul(totalAmount).div(parseEther('1'))
    const twentyPercent = twenty.mul(totalAmount).div(parseEther('1'))

    firstVestingSize = thirtyPercent
    secondVestingSize = twentyPercent
    thirdVestingSize = twentyPercent
  } else if (vestingTier === 2) {
    const twentyFive = parseEther('0.25')
    const twenty = parseEther('0.2')

    const twentyFivePercent = twentyFive.mul(totalAmount).div(parseEther('1'))
    const twentyPercent = twenty.mul(totalAmount).div(parseEther('1'))

    firstVestingSize = twentyFivePercent
    secondVestingSize = twentyPercent
    thirdVestingSize = twentyPercent
  } else if (vestingTier === 3) {
    const twenty = parseEther('0.2')
    const twentyPercent = twenty.mul(totalAmount).div(parseEther('1'))

    firstVestingSize = twentyPercent
    secondVestingSize = twentyPercent
    thirdVestingSize = twentyPercent
  } else if (vestingTier === 4) {
    const twenty = parseEther('0.2')
    const fifteen = parseEther('0.15')

    const twentyPercent = twenty.mul(totalAmount).div(parseEther('1'))
    const fifteenPercent = fifteen.mul(totalAmount).div(parseEther('1'))

    firstVestingSize = twentyPercent
    secondVestingSize = twentyPercent
    thirdVestingSize = fifteenPercent
  } else if (vestingTier === 5) {
    const twenty = parseEther('0.2')
    const fifteen = parseEther('0.15')

    const twentyPercent = twenty.mul(totalAmount).div(parseEther('1'))
    const fifteenPercent = fifteen.mul(totalAmount).div(parseEther('1'))

    firstVestingSize = twentyPercent
    secondVestingSize = fifteenPercent
    thirdVestingSize = fifteenPercent
  } else {
    return {
      firstVestingSize: BigNumber.from(0),
      secondVestingSize: BigNumber.from(0),
      thirdVestingSize: BigNumber.from(0),
    }
  }

  return {
    firstVestingSize,
    secondVestingSize,
    thirdVestingSize,
  }
}
