import { BigNumber } from 'ethers'
import {
  StaticAirdropData,
  StaticAirdropDataRaw,
  StaticAirdropResult,
} from './types'

export function parseRawStaticAirdropResult(
  raw: StaticAirdropDataRaw
): StaticAirdropResult {
  if (raw.isSybil) {
    return {
      exists: false,
      pearls: raw.pearlAmount,
      isSybil: true,
    }
  }

  const data: StaticAirdropData = {
    merkleRoot: raw.merkleRoot,
    cumulativeAmount: BigNumber.from(raw.cumulativeAmount),
    totalAmount: BigNumber.from(raw.totalAmount),
    loyaltyAmount: BigNumber.from(raw.loyaltyAmount),
    vestingTier: raw.vestingTier,
    selectedOption2: raw.selectedOption2,
    proofsHex: raw.proofsHex,
  }

  return {
    exists: true,
    data,
    pearls: raw.pearlAmount,
    isSybil: raw.isSybil,
  }
}
