import { useEffect, useRef, useState } from 'react'
import { useNowMsAnimation } from './useTimeCountdown'
import { scaleLinear } from '@/util/charts'

const DEFAULT_FLASH_DURATION = 480

export function useFlash({
  flash,
  flashDurationMs = DEFAULT_FLASH_DURATION,
  flashOn,
  flashOnMount,
}: {
  flash: boolean
  flashDurationMs?: number
  flashOn: 'on' | 'off' | 'toggle'
  flashOnMount?: boolean
}) {
  const nowMs = useNowMsAnimation()
  const [lastChangedTimestamp, setLastChangedTimestamp] = useState(
    flashOnMount ? nowMs : 0
  )

  const lastValue = useRef<boolean>(flash)

  useEffect(() => {
    const newValue = flash

    const didChange = lastValue.current !== newValue
    const didTurnOn = newValue && !lastValue.current
    const didTurnOff = !newValue && lastValue.current

    let shouldSetLastChangedTimestamp = false

    if (flashOn === 'on' && didTurnOn) {
      shouldSetLastChangedTimestamp = true
    } else if (flashOn === 'off' && didTurnOff) {
      shouldSetLastChangedTimestamp = true
    } else if (flashOn === 'toggle' && didChange) {
      shouldSetLastChangedTimestamp = true
    } else {
      shouldSetLastChangedTimestamp = false
    }

    if (shouldSetLastChangedTimestamp) {
      setLastChangedTimestamp(nowMs)
      lastValue.current = newValue
    }
  }, [flash, flashOn, nowMs])

  const t = nowMs - lastChangedTimestamp
  let flashProgress = scaleLinear(t, [0, flashDurationMs], [0, 1])
  flashProgress = Math.min(1, Math.max(0, flashProgress))
  const flashAmount = Math.sin(flashProgress * Math.PI)

  return { flashAmount }
}
