import { YearnBalances, YearnVaultStats } from '@/state/yearnVault/types'
import {
  YearnHoldingsSummary,
  YearnPointsSummary,
  YearnVaultSummary,
} from './types'
import { displayFiat } from '@/util/displayFiat'
import {
  YearnAeraUserPoints,
  YearnAeraVault,
  YearnAeraVaultPoints,
} from '@/types/yearnAera'
import { formatWithConfig } from '@/util/number'
import { displayPercentFromPercent } from '@/util/displayNumbers'
import { applyRateBigNumberToFloat } from '@/util/big'
import { PointsCampaign } from '@/types/points'
import { displayCryptoLocale } from '@/util/displayCrypto'
import { PriceReading } from '@/state/prices/types'

export function makeYearnPointsSummary({
  account,
  blackPearlsCampaign,
  userPoints,
  vaultPoints,
}: {
  account: string | undefined
  blackPearlsCampaign: PointsCampaign
  userPoints: YearnAeraUserPoints | undefined
  vaultPoints: YearnAeraVaultPoints | undefined
}): YearnPointsSummary {
  let vaultSymbioticPoints = ''
  if (vaultPoints) {
    vaultSymbioticPoints = formatWithConfig(vaultPoints.vaultSymbioticPoints, {
      localize: true,
      precision: 0,
    })
  }

  let blackPearls = ''
  if (!account) {
    blackPearls = '-'
  }

  if (userPoints) {
    blackPearls = formatWithConfig(userPoints.blackPearls, {
      localize: true,
      precision: 0,
    })

    if (userPoints.blackPearls > 0) {
      if (
        blackPearlsCampaign.multiplier &&
        blackPearlsCampaign.multiplier !== 1
      ) {
        blackPearls += ` (x${blackPearlsCampaign.multiplier} Boost)`
      }
    }
  }

  return {
    vaultSymbioticPoints,
    blackPearls,
  }
}

export function makeYearnVaultSummary({
  depositAssetUsdRate,
  vault,
  vaultStats,
  vaultFeesWillBePercent,
}: {
  vault: YearnAeraVault
  vaultStats: YearnVaultStats | undefined
  depositAssetUsdRate: PriceReading | undefined
  vaultFeesWillBePercent?: number
}): YearnVaultSummary {
  let formattedTVL = ''
  if (vaultStats && depositAssetUsdRate?.priceUsd !== undefined) {
    if (
      depositAssetUsdRate.priceUsd === 0 ||
      depositAssetUsdRate.priceUsd < 0
    ) {
      formattedTVL = 'TBD'
    } else {
      formattedTVL = displayFiat(
        applyRateBigNumberToFloat(
          vaultStats.totalAssets,
          depositAssetUsdRate.priceUsd,
          {
            base: vault.vaultToken,
          }
        )
      )
    }
  }

  let totalDepositorsStr = ''
  if (vaultStats) {
    totalDepositorsStr = formatWithConfig(vaultStats.totalDepositors, {
      localize: true,
      precision: 0,
    })
  }

  let apyPercentStr = ''
  if (vaultStats?.apr) {
    if (vaultStats.apr.indicative) {
      if (vaultStats.apr.indicativeAprPercent) {
        apyPercentStr = displayPercentFromPercent(
          vaultStats.apr.indicativeAprPercent,
          { precision: 2 }
        )
      } else {
        apyPercentStr = 'TBD'
      }
    } else {
      apyPercentStr = displayPercentFromPercent(vaultStats.apr.aprPercent, {
        precision: 2,
      })
    }
  }

  let vaultFees = ''
  if (vaultStats) {
    vaultFees = displayPercentFromPercent(vaultStats.feePercent, {
      precision: 2,
    })
  }

  let vaultFeesWillBe = ''
  if (vaultFeesWillBePercent) {
    vaultFeesWillBe = displayPercentFromPercent(vaultFeesWillBePercent, {
      precision: 2,
    })
  }

  return {
    vaultTvl: formattedTVL,
    totalDepositors: totalDepositorsStr,
    vaultApr: apyPercentStr,
    vaultFees,
    vaultFeesWillBe,
    vaultAprIndicative: Boolean(vaultStats?.apr?.indicative),
  }
}

export function makeYearnHoldingsSummary({
  account,
  balances,
  vault,
  isBtc,
  depositAssetUsdRate,
}: {
  account: string | undefined
  balances: YearnBalances | undefined
  depositAssetUsdRate: PriceReading | undefined
  vault: YearnAeraVault
  isBtc?: boolean
}): YearnHoldingsSummary {
  let vaultTokenStr = ''
  if (balances) {
    const amountStr = displayCryptoLocale(
      balances.vaultToken,
      vault.vaultToken.decimals,
      { btc: isBtc }
    )

    let usdValueStr = ''
    if (depositAssetUsdRate) {
      usdValueStr = displayFiat(
        applyRateBigNumberToFloat(
          balances.vaultToken,
          depositAssetUsdRate.priceUsd,
          { base: vault.vaultToken }
        )
      )
    }

    if (usdValueStr) {
      vaultTokenStr = `${amountStr} (${usdValueStr})`
    } else {
      vaultTokenStr = amountStr
    }
  }

  if (!account) {
    vaultTokenStr = '-'
  }

  return {
    vaultToken: vaultTokenStr,
  }
}
