// @generated by protoc-gen-connect-es v1.1.2 with parameter "target=ts"
// @generated from file swell/v3/nucleus.proto (package swell.v3, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import { NucleusServiceFulfilledEventsRequest, NucleusServiceFulfilledEventsResponse, NucleusServiceGlobalStatsRequest, NucleusServiceGlobalStatsResponse, NucleusServicePointsRequest, NucleusServicePointsResponse, NucleusServiceVaultStateRequest, NucleusServiceVaultStateResponse, NucleusServiceVaultStatsRequest, NucleusServiceVaultStatsResponse, NucleusVerifyWithdrawalRequest, NucleusVerifyWithdrawalResponse } from "./nucleus_pb.js";
import { MethodIdempotency, MethodKind } from "@bufbuild/protobuf";

/**
 * @generated from service swell.v3.NucleusService
 */
export const NucleusService = {
  typeName: "swell.v3.NucleusService",
  methods: {
    /**
     * @generated from rpc swell.v3.NucleusService.VaultStats
     */
    vaultStats: {
      name: "VaultStats",
      I: NucleusServiceVaultStatsRequest,
      O: NucleusServiceVaultStatsResponse,
      kind: MethodKind.Unary,
      idempotency: MethodIdempotency.NoSideEffects,
    },
    /**
     * @generated from rpc swell.v3.NucleusService.GlobalStats
     */
    globalStats: {
      name: "GlobalStats",
      I: NucleusServiceGlobalStatsRequest,
      O: NucleusServiceGlobalStatsResponse,
      kind: MethodKind.Unary,
      idempotency: MethodIdempotency.NoSideEffects,
    },
    /**
     * @generated from rpc swell.v3.NucleusService.VaultState
     */
    vaultState: {
      name: "VaultState",
      I: NucleusServiceVaultStateRequest,
      O: NucleusServiceVaultStateResponse,
      kind: MethodKind.Unary,
      idempotency: MethodIdempotency.NoSideEffects,
    },
  }
} as const;

/**
 * @generated from service swell.v3.NucleusWalletService
 */
export const NucleusWalletService = {
  typeName: "swell.v3.NucleusWalletService",
  methods: {
    /**
     * @generated from rpc swell.v3.NucleusWalletService.FulfilledEvents
     */
    fulfilledEvents: {
      name: "FulfilledEvents",
      I: NucleusServiceFulfilledEventsRequest,
      O: NucleusServiceFulfilledEventsResponse,
      kind: MethodKind.Unary,
      idempotency: MethodIdempotency.NoSideEffects,
    },
    /**
     * @generated from rpc swell.v3.NucleusWalletService.PointsEarnETH
     */
    pointsEarnETH: {
      name: "PointsEarnETH",
      I: NucleusServicePointsRequest,
      O: NucleusServicePointsResponse,
      kind: MethodKind.Unary,
      idempotency: MethodIdempotency.NoSideEffects,
    },
    /**
     * @generated from rpc swell.v3.NucleusWalletService.PointsEarnBTC
     */
    pointsEarnBTC: {
      name: "PointsEarnBTC",
      I: NucleusServicePointsRequest,
      O: NucleusServicePointsResponse,
      kind: MethodKind.Unary,
      idempotency: MethodIdempotency.NoSideEffects,
    },
    /**
     * @generated from rpc swell.v3.NucleusWalletService.VerifyWithdrawal
     */
    verifyWithdrawal: {
      name: "VerifyWithdrawal",
      I: NucleusVerifyWithdrawalRequest,
      O: NucleusVerifyWithdrawalResponse,
      kind: MethodKind.Unary,
      idempotency: MethodIdempotency.NoSideEffects,
    },
  }
} as const;

