import React, { Fragment, useMemo, useState } from 'react'
import { Helmet } from 'react-helmet'
import { Grid } from '@swell-ui/Grid'
import { PageTitle } from '@/components/PageTitleOld'
import { RestakingUploadDepositCollectionWidget } from '@/components/UploadDepositCollectionWidget'
import { Button } from '@/swell-ui/Button'
import { RestakingDepositCollectionSubmissionTable } from '@/components/DepositCollectionSubmissionTable'
import {
  useBatchRestakingSubmitAwaitInLoop,
  useRestakingDepositSubmissionHistoricState,
  useRestakingDepositSubmissionActions,
  useBatchRestakingDepositSubmissionStatus,
  BatchDepositSubmissionStatus,
} from '@/state/restakingDepositSubmission/hooks'
import { useRestakingDepositCollectionValidationState } from '@/state/restakingDepositValidation/hooks'
import { DepositCollectionValidationSummary } from '@/components/DepositCollectionValidationSummary'
import { DepositCollectionValidationDetail } from '@/components/DepositCollectionValidationDetail'
import { useViewedRestakingDepositSubmissionUpdater } from '@/hooks/useViewedRestakingDepositSubmissionUpdater'
import useRestakingDepositSubmissionTabTitle from '@/hooks/useRestakingDepositSubmissionTabTitle'
import { useRestakingUserOperatorStatusWithWarning } from '@/hooks/useUserOperatorStatus'
import styled from 'styled-components'

const TitlePositioner = styled.div`
  position: relative;
`

function ValidatorKeys() {
  const { isOperator } = useRestakingUserOperatorStatusWithWarning()

  const [textAreaRef, setTextAreaRef] = useState<any>(null)

  const { validationResult } = useRestakingDepositCollectionValidationState()

  const { submissionStatus } = useBatchRestakingDepositSubmissionStatus()
  const { isHistoric } = useRestakingDepositSubmissionHistoricState()
  const { clearSubmissionState } = useRestakingDepositSubmissionActions()

  // responsible for indicating that the submission has been viewed
  // (for the sake of showing a notification to users who revisit with an unfinished submission)
  useViewedRestakingDepositSubmissionUpdater()

  const onSubmit = useBatchRestakingSubmitAwaitInLoop()
  const { tabTitleOverride } = useRestakingDepositSubmissionTabTitle()

  type ValidatorKeyView = keyof typeof views

  const view = useMemo<ValidatorKeyView>(() => {
    // Loading initial view based on values from localStorage
    const initialSubmissionStatus = submissionStatus
    return initialSubmissionStatus === BatchDepositSubmissionStatus.UNSTARTED
      ? 'validation'
      : 'submission'
  }, [submissionStatus])

  const onExitSubmission = () => {
    clearSubmissionState()
  }

  const views = {
    validation: (
      <Grid key="validation" container spacing={2}>
        <Grid item xs={12} sm={5}>
          <RestakingUploadDepositCollectionWidget
            setTextAreaRef={setTextAreaRef}
            onSubmit={onSubmit}
            preventInteraction={!isOperator}
          />
        </Grid>
        <Grid item xs={12} sm={7}>
          <Grid container direction="column" spacing={2}>
            <Grid item>
              <DepositCollectionValidationSummary
                validationResult={validationResult}
              />
            </Grid>
            <Grid item>
              <DepositCollectionValidationDetail
                validationResult={validationResult}
                textAreaRef={textAreaRef}
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    ),
    submission: (
      <Fragment key="submission">
        {!isHistoric && tabTitleOverride && (
          <Helmet>
            <title>{tabTitleOverride}</title>
          </Helmet>
        )}
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <RestakingDepositCollectionSubmissionTable
              isHistoric={isHistoric}
            />
          </Grid>
          <Grid item>
            <Button onClick={onExitSubmission}>Close</Button>
          </Grid>
        </Grid>
      </Fragment>
    ),
  }

  const derived = {
    get pageTitleText() {
      if (isHistoric) return 'Historical submission data'
      return 'Validator keys'
    },
  }

  return (
    <>
      <TitlePositioner>
        <PageTitle text={derived.pageTitleText} />
      </TitlePositioner>
      {views[view]}
    </>
  )
}

export { ValidatorKeys }
