import { getAddress } from 'ethers/lib/utils'
import axios, { AxiosError } from 'axios'
import {
  IStaticWavedropService,
  StaticWavedrop,
  StaticWavedropRaw,
} from './types'
import { BigNumber } from 'ethers'

export class StaticWavedropService implements IStaticWavedropService {
  private baseURL: string
  constructor({ baseURL }: { baseURL: string }) {
    this.baseURL = baseURL.replace(/\/$/, '')
  }

  // https://airdrop.svc.swellnetwork.io/api/wavedrop?wavedrop_number=1&address=0x822c9782f35C4bc34216588e96fD6451e8DB80CF&opn=2
  private userUrl = (address: string) =>
    `${this.baseURL}/api/wavedrop?address=${address}`

  wavedrops: IStaticWavedropService['wavedrops'] = async (address) => {
    address = getAddress(address)
    address = address.toLowerCase()

    const url = this.userUrl(address)
    let res: { data: StaticWavedropRaw[] }
    try {
      res = await axios.get(url)
    } catch (e) {
      if (e && e instanceof AxiosError && e.response?.status === 404) {
        return {
          wavedrops: [],
        }
      }
      throw e
    }
    const dataRaw = res.data
    if (!dataRaw || !dataRaw.length) {
      throw new Error('No wavedrops found')
    }

    const wavedrops: StaticWavedrop[] = []
    for (const raw of dataRaw) {
      wavedrops.push({
        wavedropNumber: raw.wavedrop_number,
        weightedPearls: raw.weighted_pearls,
        bonus: raw.bonus,
        swellAmount: BigNumber.from(raw.swell_amount),
        cumulativeAmount: BigNumber.from(raw.cumulative_amount),
        merkleRoot: raw.merkle_root,
        proofsHex: raw.proofs,
      })
    }

    return { wavedrops }
  }
}
