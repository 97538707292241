import React, { useState } from 'react'
import styled from 'styled-components'
import { Accordion } from '@swell-ui/Accordion'
import { FlexRow } from '@swell-ui/FlexRow'
import { MinusIcon } from '@swell-ui/icons/MinusIcon'
import { PlusIcon } from '@swell-ui/icons/PlusIcon'
import { Typography } from '@swell-ui/Typography'
import { useSwellUiTheme } from '@swell-ui/theme'

const Heading = styled(Typography)`
  margin-top: 0;
`

const SectionBody = styled.div`
  color: #b0b0b0;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 160%; /* 25.6px */
  letter-spacing: -0.48px;
`

interface Question {
  question: string
  answer: string | React.ReactNode
}

const FaqOverflowContainer = styled.div`
  position: relative;
  width: 100%;
  overflow: visible;
`

const FaqContainerFlex = styled(FlexRow)`
  max-width: 829px;
  li {
    margin: 4px 0;
  }
`

const StyledAccordion = styled(Accordion)`
  width: 100%;
  min-height: 52px;

  .MuiAccordionSummary-content.Mui-expanded {
    margin-left: 0;
    margin-right: 0;
    margin-bottom: 21px;
  }

  .MuiAccordionDetails-root {
    margin-bottom: 0;
  }
`

const QUESTIONS: Question[] = [
  {
    question: 'What is Swell L2?',
    answer: (
      <>
        <p>
          Swell L2 is the restaking chain, powered by{' '}
          <a
            href="https://www.swellnetwork.io/post/restakingyieldlayer"
            target="_blank"
            rel="noopener noreferrer"
          >
            Proof of Restake
          </a>
          .
        </p>
        <p>
          It is an Ethereum Layer 2 that uses the{' '}
          <a
            href="https://www.swellnetwork.io/post/swell-l2-superchain"
            target="_blank"
            rel="noopener noreferrer"
          >
            OP Stack
          </a>
          , built on the Superchain.
        </p>
        <p>It is optimized to be the best place for restaking across chains.</p>
      </>
    ),
  },
  {
    question: 'What will I get for Pre-Launch Depositing?',
    answer: (
      <>
        <h4>Multiple Airdrops</h4>
        <p>
          Depositing in the Swell L2 Pre-Launch on mainnet makes you eligible
          for aidrops from both Swell and projects building on the L2.
        </p>
        <p>
          These projects include{' '}
          <a href="https://ionprotocol.io/" target="_blank" rel="noreferrer">
            ION Protocol
          </a>
          ,{' '}
          <a href="https://ambient.finance/" target="_blank" rel="noreferrer">
            Ambient Finance
          </a>
          ,{' '}
          <a href="https://www.brahma.fi/" target="_blank" rel="noreferrer">
            Brahma Finance
          </a>
          , and more to be confirmed. Each of these will deliver tokens to
          depositors on the date of their respective airdrops.
        </p>
        <p>
          In addition, Swell will airdrop $SWELL to all depositors on the launch
          of Swell L2. Note that this is separate to the 8.5% of supply that is
          allocated to the Voyage, represented by the White Pearl holders.
        </p>
        <h4>Native points and rewards</h4>
        <p>
          In addition to being eligible for multiple airdrops, you will also
          earn native rewards and points for the token you deposit.
        </p>
        <p>
          Points for native rewards are distributed by their platform to the L2
          Pre-Launch contract and then passed in full to depositors.
        </p>
        <p>
          Depending on the asset you deposit, these rewards/points will include:
        </p>
        <ul>
          <li>
            <a
              href="https://www.swellnetwork.io/post/the-voyage"
              target="_blank"
              rel="noopener noreferrer"
            >
              4x Swell Voyage Pearls
            </a>{' '}
            (until TGE)
          </li>
          <li>
            <a
              href="https://docs.eigenlayer.xyz/eigenlayer/restaking-guides/restaking-user-guide/restaked-points"
              target="_blank"
              rel="noopener noreferrer"
            >
              EigenLayer Restaked Points
            </a>
          </li>
          <li>
            <a
              href="https://etherfi.gitbook.io/etherfi/getting-started/loyalty-points"
              target="_blank"
              rel="noopener noreferrer"
            >
              EtherFi Loyalty Points
            </a>
          </li>
          <li>
            <a
              href="https://medium.com/@official_42951/stakestone-omnichain-carnival-6a4fc10ebe4f"
              target="_blank"
              rel="noopener noreferrer"
            >
              StakeStone Points
            </a>{' '}
            (in Carnival wave 2)
          </li>
          <li>
            <a
              href="https://blog.eigenpiexyz.io/eigenpies-point-system-and-low-fdv-ido-b75678bad213"
              target="_blank"
              rel="noopener noreferrer"
            >
              Eigenpie Points
            </a>
          </li>
          <li>
            <a
              href="https://docs.renzoprotocol.com/docs/product/renzo-ezpoints"
              target="_blank"
              rel="noopener noreferrer"
            >
              Renzo ezPoints
            </a>
          </li>
          <li>
            <a
              href="https://etherfi.gitbook.io/etherfi/getting-started/loyalty-points"
              target="_blank"
              rel="noopener noreferrer"
            >
              Etherfi Loyalty Points
            </a>
          </li>
          <li>
            <a
              href="https://turtle.club/points/"
              target="_blank"
              rel="noopener noreferrer"
            >
              Turtle Points
            </a>
          </li>
          <li>LST/LRT staking yield</li>
          <li>LST/LRT restaking yield</li>
        </ul>
        <p>Note that ALT staking yield is not included as a native reward.</p>
        <h4>Share of 1M EigenLayer Points</h4>
        <p>
          A proportional share of 1M Eigenlayer Points will be given to everyone
          who deposits in the first four weeks (before May 8th, 2024), and
          doesn't withdraw until the launch of Swell L2 in Q4, 2024.
        </p>
      </>
    ),
  },
  {
    question: 'What makes Swell L2 different?',
    answer: (
      <>
        <ul>
          <li>
            <b>Native restaking yield</b>
            <br />
            Swell L2 provides a foundational yield of staking and restaking
            rewards for all assets and activity on the network.
          </li>
          <li>
            <b>Built for maximum rewards </b> <br />
            In exchange for providing security and liquidity by bridging
            liquidity to Swell L2, restakers reap all the rewards of an emerging
            restaking ecosystem. This includes the SWELL Wavedrops and multiple
            ecosystem project airdrops, along with rewards from AVSs as well as
            the native points and yield of all deposited assets.
          </li>
          <li>
            <b>Optimized for restaking</b> <br />
            Swell L2 is optimized for restaking. The chain brings together a
            curated ecosystem of the best dApps and AVSs to create the best
            restaking experience in crypto bar none. This includes incentivized
            base layer LRT liquidity, deep onchain liquidity, capital efficient
            borrow / lend markets, and more.
          </li>
        </ul>
      </>
    ),
  },
  {
    question: 'How will the EigenLayer Points be distributed?',
    answer: (
      <>
        <p>
          The amount of EigenLayer points received will be derived from the
          amount that you deposit in the pre-launch deposit contract, and the
          duration of your deposit, as calculated based on a time-weighted
          average.
        </p>
        <p>
          Only depositors who stay in the Pre-Launch until the launch of Swell
          L2 will receive the Points.
        </p>
      </>
    ),
  },
  {
    question: 'Which assets can I deposit?',
    answer:
      'Only assets highlighted in the UI are accepted as L2 Pre-Launch deposits.',
  },
  {
    question: 'Can I withdraw from the pre-deposit?',
    answer: (
      <>
        <p>Withdrawals can be made instantly at any time.</p>
        <p>
          There is no need to lock or bridge assets to earn points and receive
          the airdrop rewards.
        </p>
      </>
    ),
  },
  {
    question: 'How does Swell L2 work?',
    answer: (
      <>
        <p>
          Swell L2 will be powered by Swell's own liquid staking and restaking
          tokens — swETH and rswETH — as well as those of fellow LRT protocols
          including EtherFi, Renzo, and Kelp.
        </p>
        <p>
          As LRTs are bridged to Swell L2, they provide critical base layer
          liquidity to power the wider ecosystem of restaking.
        </p>
        <p>
          In this way, liquidity flowing into the chain both supports the DeFi
          ecosystem on the network, and secures AVSs that leverage Swell L2 and
          other protocols across the restaking ecosystem.
        </p>
      </>
    ),
  },
  {
    question: 'What does Swell do with pre-deposited assets?',
    answer: (
      <>
        <p>
          Assets deposited in the pre-deposit contract remain untouched in the
          contract, with no additional risk incurred.
        </p>
        <p>
          Once Swell L2 is live, users will be able to bridge from mainnet to
          Swell L2.
        </p>
      </>
    ),
  },
  {
    question: 'How are assets in the pre-deposit contract secured?',
    answer: (
      <>
        <p>
          You remain in control of L2 Pre-Launch assets and can withdraw at any
          time.
        </p>
        <p>
          The L2 Pre-Launch contract has been audited by Hexens and MixBytes.
        </p>
      </>
    ),
  },
  {
    question: 'Is there a minimum deposit?',
    answer: 'There is no minimum pre-deposit requirement.',
  },
  {
    question: 'Updates',
    answer: (
      <>
        <p>
          The FAQs were updated on 22 November to reflect the following changes:
        </p>
        <ul>
          <li>
            Swell L2 is now building on the Superchain, using the OP Stack.
          </li>
          <li>Swell L2's revised launch date is early to mid December 2024.</li>
          <li>
            Swell L2 uses ETH as the gas token instead of Restaked Swell ETH
            (rswETH). The OP Stack is a standardized, shared, and open-source
            development stack maintained by the Optimism Collective, which
            utilizes ETH as the common and exclusive gas token.
          </li>
          <li>
            Swell has since launched the $SWELL token and concluded the Voyage
            airdrop to White Pearl holders.
          </li>
          <li>
            Swell has shifted to a more universal and flexible liquid restaking
            model for its governance token staking mechanism, shifting from
            Tri-Staking, to Liquid Restaking, via rSWELL.
          </li>
        </ul>
      </>
    ),
  },
]

function QuestionAccordion({ question }: { question: Question }) {
  const [expanded, setExpanded] = useState<boolean>(false)

  const handleChange = () => {
    setExpanded(!expanded)
  }

  const accordionConfig = {
    summary: (
      <FlexRow justify="space-between">
        <Typography variant="headline" size="h6" letterSpacing="small">
          {question.question}
        </Typography>
        {expanded && <MinusIcon />}
        {!expanded && <PlusIcon />}
      </FlexRow>
    ),
    details: <SectionBody>{question.answer}</SectionBody>,
  }

  return <StyledAccordion config={accordionConfig} onChange={handleChange} />
}

export function FaqSection() {
  const { theme } = useSwellUiTheme()

  return (
    <FaqOverflowContainer>
      <FaqContainerFlex direction="column" align="flex-start" gap="12">
        <Heading variant="headline" size="h4" letterSpacing="small">
          FAQ
        </Heading>
        {QUESTIONS.map((question: Question) => {
          return (
            <QuestionAccordion key={question.question} question={question} />
          )
        })}
      </FaqContainerFlex>
    </FaqOverflowContainer>
  )
}
