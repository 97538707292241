import { createContext, useContext } from 'react'
import { WavedropsApi } from './types'
import { Token } from '@/types/tokens'

const MISSING_PROVIDER = Symbol()

export type WavedropsContext = WavedropsApi & {
  currentWaveNumber: number
}

export const WavedropsContext = createContext<
  WavedropsContext | typeof MISSING_PROVIDER
>(MISSING_PROVIDER)

export function useWavedropsApi() {
  const ctx = useContext(WavedropsContext)
  if (ctx === MISSING_PROVIDER) {
    throw new Error(
      'WavedropsContext hooks must be wrapped in a <WavedropsContext.Provider>'
    )
  }
  return ctx
}
