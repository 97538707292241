import { RadialSplitVis } from '@/components/RadialSplitVis'
import { SwellDaoClaimAirdrop } from '@/state/dao/hooks'
import { AirdropOffchainState, AirdropResult } from '@/state/dao/types'
import { Token } from '@/types/tokens'
import { formatUnits } from 'ethers/lib/utils'
import styled, { keyframes } from 'styled-components'
import { DecoBox } from '@/components/DecoBox'
import { FlexRow } from '@/swell-ui/FlexRow'
import { formatWithConfig, formatWithDynamicPrecision } from '@/util/number'
import { ClaimAirdropButton, SliderChipGroup } from '../../AirdropButtons'
import { AirdropSlider } from '../../AirdropSlider'
import { PreparedSwellDaoClaimAirdrop } from '../../airdropCalls'
import {
  bigValueCSS,
  medValueCSS,
  paragraphHintCSS,
  smallCapsColoredCSS,
  title1CSS,
  title2CSS,
} from '../../styles/common'
import { ClaimMerkleDropParams, MerkleDropState } from '@/types/merkle'
import { TokenLogo } from '@/components/TokenLogo'
import { TOKEN_LIST_RSWELL, TOKEN_LIST_SWELL } from '@/constants/tokens'
import { AirdropClaimAccounting } from './AirdropClaimAccounting'
import { css } from 'styled-components'
import { makeRewardSummary, makeVestingSchedule } from '../../airdropFormatting'
import { claimableAmountForSlider, shouldDisplaySplitter } from './util'
import { useEffect, useState } from 'react'
import { useSwellWeb3 } from '@/swell-web3/core'
import { InfoIcon } from '@/swell-ui/icons/InfoIcon'
import { Tooltip } from '@/swell-ui/Tooltip'

const RSWELL_LEARN_MORE = 'https://www.swellnetwork.io/post/swell-token'

export function ClaimAirdrop({
  airdropResult,
  daoToken,
  lockRatio,
  claimAirdrop,
  preparedClaimAirdrop,
  setLockRatio,
  merkleStatus,
  airdropOffchainState,
  restakedDaoToken,
  cachedClaimAirdropArguments,
}: {
  claimAirdrop: SwellDaoClaimAirdrop
  preparedClaimAirdrop: PreparedSwellDaoClaimAirdrop
  airdropResult: AirdropResult
  daoToken: Token
  lockRatio: number
  setLockRatio: (ratio: number) => void
  merkleStatus: MerkleDropState
  airdropOffchainState: AirdropOffchainState
  restakedDaoToken: Token
  cachedClaimAirdropArguments: ClaimMerkleDropParams | undefined
}) {
  if (!airdropResult.exists) {
    throw new Error('no airdrop')
  }
  const { account } = useSwellWeb3()
  const [justClaimed, setJustClaimed] = useState(false)
  useEffect(() => {
    if (claimAirdrop.status === claimAirdrop.STATUS.FULFILLED) {
      setJustClaimed(true)
    }
  }, [claimAirdrop])
  useEffect(() => {
    setJustClaimed(false)
  }, [account])

  const claimableAmount = claimableAmountForSlider({
    airdropResult,
    justClaimed,
  })
  const claimableSwellNum = Number(
    formatUnits(claimableAmount, daoToken.decimals)
  )
  const stakedNum = claimableSwellNum * lockRatio
  const stakedStr = formatWithConfig(stakedNum, {
    localize: true,
    precision: 0,
  })

  const rewardSummary = makeRewardSummary({
    airdropResult,
    daoToken,
  })
  const vestingSchedule = makeVestingSchedule({
    airdropResult,
    daoToken,
    airdropOffchainState,
    restakedDaoToken,
    justClaimed,
  })

  const showSplitter = shouldDisplaySplitter({
    airdropResult,
    justClaimed: !!cachedClaimAirdropArguments,
  })

  return (
    <Layout direction="column" gap="24" align="center" maxWidth="1064px">
      <h1>Claim and Restake SWELL</h1>
      <p className="c">
        Choose between claiming SWELL to your wallet, restaking for rSWELL or
        split your claim and get the best of both worlds.
      </p>
      <div />
      <ClaimLayout>
        <div
          className="a-claim"
          style={{ visibility: showSplitter ? 'visible' : 'hidden' }}
        >
          <DecoBox gap="24">
            <FlexRow justify="space-between" align="center">
              <h4>Restake SWELL</h4>
              <span className="med">{Math.round(lockRatio * 100)}%</span>
            </FlexRow>
            <FlexRow gap="24" direction="column">
              <SliderChipGroup
                lockRatio={lockRatio}
                setLockRatio={setLockRatio}
                disabled={claimAirdrop.status !== claimAirdrop.STATUS.IDLE}
              />
              <AirdropSlider
                maxAmount={claimableSwellNum}
                disabled={claimAirdrop.status !== claimAirdrop.STATUS.IDLE}
                lockRatio={lockRatio}
                setLockRatio={setLockRatio}
                stakedStr={stakedStr}
              />
            </FlexRow>
          </DecoBox>
          <SplitContainer gap="24" align="start" textAlign="left">
            <h4>Claim split</h4>
            <div>
              <RadialSplitVis
                label0={'restake'}
                label1={'withdraw'}
                labelCenter={'total swell'}
                amount={claimableSwellNum}
                ratio={lockRatio}
              />
            </div>
          </SplitContainer>
          <div className="claim-btn">
            <ClaimAirdropButton
              prepared={preparedClaimAirdrop}
              claimAirdrop={claimAirdrop}
              merkleStatus={merkleStatus}
              airdropResult={airdropResult}
              justClaimed={justClaimed}
            />
          </div>
        </div>
        <FlexRow className="a-info" direction="column">
          <Tokens />
        </FlexRow>
        <FlexRow className="a-summary" direction="column">
          <FlexRow direction="column" gap="24" align="start">
            <h3>Claim Summary</h3>
            <AirdropClaimAccounting
              rewardSummary={rewardSummary}
              vestingSchedule={vestingSchedule}
              justClaimed={justClaimed}
            />
          </FlexRow>
        </FlexRow>
      </ClaimLayout>
    </Layout>
  )
}

const fadeIn = keyframes`
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
`

const fadeInCSS = css`
  opacity: 0;
  animation: ${fadeIn} 0.65s ease-out forwards;
  animation-delay: 0.15s;
`

const Layout = styled(FlexRow)`
  ${fadeInCSS}
  margin: auto 0;

  padding: 0 8px 64px;
  @media (min-width: 460px) {
    padding: 0 24px 64px;
  }

  @media (min-width: 1120px) {
    padding: 0;
  }

  h1,
  h2,
  h3,
  h4 {
    margin: 0;
  }

  .med {
    ${medValueCSS}
  }
  .big {
    ${bigValueCSS}
  }

  h1 {
    ${title1CSS}
    text-align: center;
  }

  h2 {
    ${title2CSS}
  }

  h4 {
    ${smallCapsColoredCSS}
  }
  p {
    margin: 0;
  }
  .c {
    text-align: center;
  }

  .hint {
    ${paragraphHintCSS}
  }
`

const SplitContainer = styled(DecoBox)`
  > div {
    margin: 0 auto;
  }
`

const ClaimLayout = styled.div`
  display: grid;
  grid-template-columns: auto;
  grid-template-rows: auto auto;
  grid-template-areas:
    'info    '
    'summary '
    'claim   ';

  grid-row-gap: 24px;

  @media (min-width: 1120px) {
    grid-column-gap: 36px;
    width: unset;
    grid-template-columns: minmax(auto, 646px) auto;
    grid-template-areas:
      'info    claim'
      'summary claim';

    justify-content: space-between;
  }

  .a-claim {
    margin: 0 auto;
    grid-area: claim;
    display: flex;
    flex-direction: column;
    gap: 11px;

    button:last-child {
      width: 100%;
    }

    margin-top: 48px;
    width: 100%;
  }
  @media (min-width: 460px) {
    .a-claim {
      max-width: 357px;
    }
  }
  @media (min-width: 1120px) {
    .a-claim {
      margin-top: 0;
    }
  }

  .a-info {
    grid-area: info;

    .token-label {
      color: var(--Swell-White-50, #fff);
      /* Headlines/H7 */
      font-family: Inter;
      font-size: 20px;
      font-style: normal;
      font-weight: 600;
      line-height: 130%; /* 26px */
      letter-spacing: -0.6px;

      svg {
        margin-left: 6px;
      }
    }

    ul {
      padding: 0;
      margin: 0;
      margin-left: 24px;
    }
  }
  .a-summary {
    grid-area: summary;
    align-self: end;
  }
`

function Tokens() {
  return (
    <TokensLayout gap="29" align="start">
      <FlexRow gap="12" direction="column" align="start" className="col">
        <FlexRow gap="12">
          <TokenLogo token={TOKEN_LIST_RSWELL} size={27} />
          <span className="token-label">
            rSWELL
            <Tooltip
              title={
                <>
                  <a
                    href={RSWELL_LEARN_MORE}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Learn more
                  </a>
                  .
                </>
              }
              arrow
            >
              <InfoIcon />
            </Tooltip>
          </span>
        </FlexRow>
        <div className="hint">
          <ul>
            <li>1:1 Governance power</li>
            <li>1x Symbiotic Points </li>
            <li>10x Black Pearls </li>
            <li>15x Ecosystem Points (deposit in Swell L2)</li>
            <li>Future restaking yield for securing Swell L2</li>
            <li>Yield-boosting DeFi opportunities</li>
          </ul>
        </div>
      </FlexRow>

      <FlexRow gap="12" direction="column" align="start" className="col">
        <FlexRow gap="12">
          <TokenLogo token={TOKEN_LIST_SWELL} size={27} />
          <span className="token-label">SWELL</span>
        </FlexRow>
        <div className="hint">
          <ul>
            <li>1:1 Governance power</li>
          </ul>
        </div>
      </FlexRow>
    </TokensLayout>
  )
}
// 1120
const TokensLayout = styled(FlexRow)`
  flex-wrap: wrap;

  @media (min-width: 1120px) {
    flex-wrap: nowrap;
  }

  > div.col {
    width: 100%;
    @media (min-width: 520px) {
      width: 344px;
      flex: 0 0 344px;
    }
  }
`
