import { ConsiderDepositRswellInL2Summary } from '@/components/Modals/ConsiderDepositRswellInL2Modal'
import { PreparedYearnDeposit } from '@/components/YearnAeraVaultWidget/yearnCalls'
import { Token } from '@/types/tokens'
import { displayCryptoLocale } from '@/util/displayCrypto'

export function restakeSuccessfulModalMemo({
  cachedDepositArgs,
  restakedDaoToken,
}: {
  cachedDepositArgs: PreparedYearnDeposit['args']
  restakedDaoToken: Token
}): ConsiderDepositRswellInL2Summary | undefined {
  if (!cachedDepositArgs || !cachedDepositArgs.length) {
    return undefined
  }

  const amount = cachedDepositArgs[0].amount

  return {
    amountTotal: displayCryptoLocale(amount, restakedDaoToken.decimals),
    claimedSymbols: restakedDaoToken.symbol,
    claimedLogoURIs: [restakedDaoToken.logoURI],
  }
}
