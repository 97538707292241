import { DecoBox } from '@/components/DecoBox'
import { medValueCSS } from '@/components/AirdropCarousel/styles/common'
import { Token } from '@/types/tokens'
import styled from 'styled-components'
import { AirdropOffchainState, AirdropResult } from '@/state/dao/types'
import { displayVestingBreakdown } from '../../airdropFormatting'
import { FlexRow } from '@/swell-ui/FlexRow'
import { useMediaQuery } from '@mui/material'

export function VestingSummary({
  daoToken,
  airdropResult,
  airdropOffchainState,
  restakedDaoToken,
}: {
  airdropResult: AirdropResult
  daoToken: Token
  airdropOffchainState: AirdropOffchainState
  restakedDaoToken: Token
}) {
  const isDesktop = useMediaQuery('(min-width: 990px)')
  if (!airdropResult.exists) return null
  const { initialClaimablePercent, unlocks } = displayVestingBreakdown({
    daoToken,
    airdropOffchainState,
    airdropResult,
    restakedDaoToken,
  })

  const innerContent = unlocks.map((unlock) => (
    <FlexRow key={unlock.date} direction="column">
      <h4 className="sm-header">
        {unlock.date} ({unlock.percent})
      </h4>
      <span className="value">{unlock.amount}</span>
    </FlexRow>
  ))

  const desktopContent = (
    <DesktopLayout gap="24" align="stretch">
      {innerContent}
    </DesktopLayout>
  )

  const mobileContent = (
    <MobileLayout direction="column" gap="24">
      {innerContent}
    </MobileLayout>
  )

  let content = mobileContent
  if (isDesktop) {
    content = desktopContent
  }

  let textNode = (
    <p>
      Due to the size of your SWELL allocation a portion of your token will be
      subject to vesting to ensure fairness and long term growth of the Swell
      ecosystem. An initial {initialClaimablePercent} will be vested and
      claimable on Nov 7th 2024 with the remaining vesting according to the
      following schedule
    </p>
  )
  if (airdropResult.selectedOption2) {
    textNode = (
      <p>
        Due to the size of your SWELL allocation a portion of your token will be
        subject to vesting to ensure fairness and long term growth of the Swell
        ecosystem. An initial 50% will be vested and claimable on Nov 7th 2024,
        the remaining 50% will restaked for rSWELL and will be vested according
        to the following schedule
      </p>
    )
  }

  return (
    <StyledDecoBox gap="24" align="start">
      <h4>Vesting schedule</h4>
      {textNode}
      <InnerBox>{content}</InnerBox>
    </StyledDecoBox>
  )
}

const InnerBox = styled(FlexRow)`
  border: 1px solid var(--lightblue-100, #2f43ec);
  border-radius: 12px;
  justify-content: space-around;
  padding: 24px 62px;
  width: 100%;
  text-align: center;

  &:first-child {
    width: 432px;
    white-space: nowrap;
  }
`

const DesktopLayout = styled(FlexRow)`
  white-space: nowrap;
`
const MobileLayout = styled(FlexRow)`
  ${InnerBox} {
    width: 100%;

    padding: 16px;
  }

  ${InnerBox}:last-child {
    flex-flow: column nowrap;
  }
`

const StyledDecoBox = styled(DecoBox)`
  span.b {
    font-weight: bold;
  }

  h4 {
    margin: 0;
    color: var(--Swell-Lightblue-50, #a4abf1);
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 160%; /* 22.4px */
    letter-spacing: 2.52px;
    text-transform: uppercase;
  }

  h4.sm-header {
    color: var(--Swell-Lightblue-50, #a4abf1);
    font-family: Inter;
    font-size: 10px;
    font-style: normal;
    font-weight: 500;
    line-height: 160%; /* 16px */
    letter-spacing: 1.8px;
    text-transform: uppercase;
  }

  .value {
    ${medValueCSS}
  }
`
