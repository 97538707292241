import { TokenList } from '@/types/tokens'
import { ETH_ALIAS } from './constants'
import { DeploymentSet } from '@/types/deployments'
import { ethers } from 'ethers'

import amphorEthLogoUrl from '@/assets/wave9/amphor_eth.svg'
import pendleEzethLogoUrl from '@/assets/wave9/pendle_ezeth.png'
import pendlePufethLogoUrl from '@/assets/wave9/pendle_pufeth.png'
import pendleRswethLogoUrl from '@/assets/wave9/pendle_rsweth.png'
import pendleWeethLogoUrl from '@/assets/wave9/pendle_weeth.png'
import deriveProtocolLogoUrl from '@/assets/svg/derive_protocol.svg'
import { TOKEN_LIST_RSWELL, TOKEN_LIST_SWBTC } from '@/constants/tokens'
import egethLogo from '@/assets/images/egeth_80x80.png'
import eigenpieLogo from '@/assets/images/eigenpie_40x40.png'
import eethLogo from '@/assets/images/eeth_40x40.png'

export function defaultTokenList(addresses: DeploymentSet['addresses']) {
  const d = template(addresses)

  d.tokens = d.tokens.map((t) => {
    return { ...t, address: ethers.utils.getAddress(t.address) }
  })

  return d
}

function template(addresses: DeploymentSet['addresses']): TokenList {
  return {
    name: 'default',
    keywords: [],
    logoURI: '',
    tags: {
      Swell: {
        name: 'Swell',
        description: 'Swell Ecosystem',
      },
      LRT: {
        name: 'LRT',
        description: 'Liquid Restaking Tokens',
      },
      LST: {
        name: 'LST',
        description: 'Liquid Staking Tokens',
      },
      Eigenpie: {
        name: 'Eigenpie',
        description: 'Eigenpie Ecosystem',
      },
      ETH: {
        name: 'ETH',
        description: 'Ethereum',
      },
      AVS: {
        name: 'AVS',
        description: 'Actively Validated Services',
      },
      Pendle: {
        name: 'Pendle',
        description: 'Pendle Ecosystem',
      },
      FRAX: {
        name: 'FRAX',
        description: 'Frax Ecosystem',
      },
      Stables: {
        name: 'Stables',
        description: 'Stablecoins',
      },
      Vaults: {
        name: 'Vaults',
        description: 'Vaults',
      },
      Options: {
        name: 'Options',
        description: 'Options',
      },
      Symbiotic: {
        name: 'Symbiotic',
        description: 'Symbiotic',
      },
      Lend: {
        name: 'Lend',
        description: 'Lend',
      },
    },
    tokens: [
      {
        chainId: 1,
        address: addresses.rswETH,
        symbol: 'rswETH',
        name: 'Swell restaked ETH',
        decimals: 18,
        logoURI:
          'https://uploads-ssl.webflow.com/63d9b0d075e8161e9281ebdd/65a18d05b8a1d66b3deb64e2_rswETH.svg',
        tags: ['Swell', 'LRT'],
      },
      {
        chainId: 1,
        address: ETH_ALIAS,
        symbol: 'ETH',
        name: 'Ethereum',
        decimals: 18,
        logoURI:
          'https://uploads-ssl.webflow.com/63d9b0d075e8161e9281ebdd/6535fb7092a04a804f3897db_ETH_black.svg',
        tags: ['ETH'],
      },
      {
        chainId: 1,
        address: addresses.swETH,
        symbol: 'swETH',
        name: 'Swell staked ETH',
        decimals: 18,
        logoURI:
          'https://uploads-ssl.webflow.com/63d9b0d075e8161e9281ebdd/65b1bb090e5ae9484a6d6c57_swETH.svg',
        tags: ['Swell', 'LST'],
      },
      {
        chainId: 1,
        address: '0x35fA164735182de50811E8e2E824cFb9B6118ac2',
        symbol: 'eETH',
        name: 'EtherFi restaked ETH',
        decimals: 18,
        logoURI: eethLogo,
        tags: ['LRT'],
      },
      {
        chainId: 1,
        address: '0xCd5fE23C85820F7B72D0926FC9b05b43E359b7ee',
        symbol: 'weETH',
        name: 'EtherFi wrapped eETH',
        decimals: 18,
        logoURI: eethLogo,
        tags: ['LRT'],
      },
      {
        chainId: 1,
        address: '0xbf5495Efe5DB9ce00f80364C8B423567e58d2110',
        symbol: 'ezETH',
        name: 'Renzo restaked ETH',
        decimals: 18,
        logoURI:
          'https://uploads-ssl.webflow.com/63d9b0d075e8161e9281ebdd/65cc6175cdd476978da26a17_ezETH.svg',
        tags: ['LRT'],
      },
      {
        chainId: 1,
        address: '0xae7ab96520DE3A18E5e111B5EaAb095312D7fE84',
        symbol: 'stETH',
        name: 'Lido staked ETH',
        decimals: 18,
        logoURI:
          'https://uploads-ssl.webflow.com/63d9b0d075e8161e9281ebdd/6535fb70329742981f34335b_stETH.svg',
        tags: ['LST'],
      },
      {
        chainId: 1,
        address: '0x7f39c581f595b53c5cb19bd0b3f8da6c935e2ca0',
        symbol: 'wstETH',
        name: 'Lido wrapped stETH',
        decimals: 18,
        logoURI:
          'https://uploads-ssl.webflow.com/63d9b0d075e8161e9281ebdd/6535fb70f080adb719c54a38_wstETH.svg',
        tags: ['LST'],
      },
      {
        chainId: 1,
        address: '0x7122985656e38BDC0302Db86685bb972b145bD3C',
        symbol: 'STONE',
        name: 'StakeStone staked ETH',
        decimals: 18,
        logoURI:
          'https://uploads-ssl.webflow.com/63d9b0d075e8161e9281ebdd/6600ea80f3784731d812c514_STONE.svg',
        tags: ['LST'],
      },
      {
        chainId: 1,
        address: '0xE46a5E19B19711332e33F33c2DB3eA143e86Bc10',
        symbol: 'mwbETH',
        name: 'Eigenpie Binance ETH',
        decimals: 18,
        logoURI: eigenpieLogo,
        tags: ['Eigenpie'],
      },
      {
        chainId: 1,
        address: '0x8a053350ca5F9352a16deD26ab333e2D251DAd7c',
        symbol: 'mmETH',
        name: 'Eigenpie Mantle ETH',
        decimals: 18,
        logoURI: eigenpieLogo,
        tags: ['Eigenpie'],
      },
      {
        chainId: 1,
        address: '0x32bd822d615A3658A68b6fDD30c2fcb2C996D678',
        symbol: 'mswETH',
        name: 'Eigenpie Swell swETH',
        decimals: 18,
        logoURI: eigenpieLogo,
        tags: ['Eigenpie'],
      },
      {
        chainId: 1,
        address: '0x49446A0874197839D15395B908328a74ccc96Bc0',
        symbol: 'mstETH',
        name: 'Eigenpie Lido stETH',
        decimals: 18,
        logoURI: eigenpieLogo,
        tags: ['Eigenpie'],
      },
      {
        chainId: 1,
        address: '0xC02aaA39b223FE8D0A0e5C4F27eAD9083C756Cc2',
        symbol: 'wETH',
        name: 'Wrapped ETH',
        decimals: 18,
        logoURI:
          'https://uploads-ssl.webflow.com/63d9b0d075e8161e9281ebdd/65279e1a53df43a1da84131a_weth.svg',
        tags: ['ETH'],
      },
      {
        chainId: 1,
        address: '0x8457CA5040ad67fdebbCC8EdCE889A335Bc0fbFB',
        symbol: 'ALT',
        name: 'AltLayer gov. token',
        decimals: 18,
        logoURI:
          'https://uploads-ssl.webflow.com/63d9b0d075e8161e9281ebdd/65a021d29151d48d3da03692_AltLayer.svg',
        tags: ['AVS'],
      },
      {
        chainId: 1,
        address: '0x5cb12d56f5346a016dbba8ca90635d82e6d1bcea',
        symbol: 'Pendle PT rswETH',
        name: 'Swell 27 Jun 2024',
        decimals: 18,
        logoURI:
          'https://uploads-ssl.webflow.com/63d9b0d075e8161e9281ebdd/660cac604ca30dcbe6ed5b37_PT-rswETH.svg',
        tags: ['Pendle'],
      },
      {
        chainId: 1,
        address: '0xa0021EF8970104c2d008F38D92f115ad56a9B8e1',
        symbol: 'Pendle PT USDe',
        name: 'Ethena 25 Jul 2024',
        decimals: 18,
        logoURI:
          'https://uploads-ssl.webflow.com/63d9b0d075e8161e9281ebdd/661dd05dbaf91ccb6eb96eb6_PT- USDe.svg',
        tags: ['Pendle'],
      },
      {
        chainId: 1,
        address: '0xc69Ad9baB1dEE23F4605a82b3354F8E40d1E5966',
        symbol: 'Pendle PT eETH',
        name: 'EtherFi 27 Jun 2024',
        decimals: 18,
        logoURI:
          'https://uploads-ssl.webflow.com/63d9b0d075e8161e9281ebdd/661dd05d5a42e2b7293b92fc_PT- eETH.svg',
        tags: ['Pendle'],
      },
      {
        chainId: 1,
        address: '0xC58aa33Ce619c7f7Ac4929DF357D0Ef762edbD23',
        symbol: 'Pendle PT pufETH',
        name: 'Puffer 27 Jun 2024',
        decimals: 18,
        logoURI:
          'https://uploads-ssl.webflow.com/63d9b0d075e8161e9281ebdd/661dd05d13f3a8c9655d427b_PT - pufETH.svg',
        tags: ['Pendle'],
      },
      {
        chainId: 1,
        address: '0x6ee2b5E19ECBa773a352E5B21415Dc419A700d1d',
        symbol: 'Pendle PT eETH',
        name: 'EtherFi 26 Dec 2024',
        decimals: 18,
        logoURI:
          'https://uploads-ssl.webflow.com/63d9b0d075e8161e9281ebdd/661dd05d5a42e2b7293b92fc_PT- eETH.svg',
        tags: ['Pendle'],
      },
      {
        chainId: 1,
        address: '0xac440d0eE23FA69632Aa731322C2a480dD1376e4',
        symbol: 'Pendle PT swETH',
        name: 'Swell 27 Jun 2024',
        decimals: 18,
        logoURI:
          'https://uploads-ssl.webflow.com/63d9b0d075e8161e9281ebdd/661dd05d32b08d84fd922b4c_PT - swETH.svg',
        tags: ['Pendle'],
      },
      {
        chainId: 1,
        address: '0x330c4DD05dB16db6323505262C57CFdE5f0A78Ea',
        symbol: 'Pendle PT swETH',
        name: 'Swell 26 Dec 2024',
        decimals: 18,
        logoURI:
          'https://uploads-ssl.webflow.com/63d9b0d075e8161e9281ebdd/661dd05d32b08d84fd922b4c_PT - swETH.svg',
        tags: ['Pendle'],
      },
      {
        chainId: 1,
        address: '0x4c9EDD5852cd905f086C759E8383e09bff1E68B3',
        decimals: 18,
        logoURI:
          'https://uploads-ssl.webflow.com/63d9b0d075e8161e9281ebdd/6621d1e729ec93f4b12b4a7f_USDe.svg',
        name: 'Ethena USDe',
        symbol: 'USDe',
        tags: ['Stables'],
      },
      {
        chainId: 1,
        address: '0x853d955aCEf822Db058eb8505911ED77F175b99e',
        decimals: 18,
        logoURI:
          'https://uploads-ssl.webflow.com/63d9b0d075e8161e9281ebdd/6621d1e62f3a23452031405a_FRAX.svg',
        name: 'FRAX stablecoin',
        symbol: 'FRAX',
        tags: ['FRAX', 'Stables'],
      },
      {
        chainId: 1,
        address: '0xA663B02CF0a4b149d2aD41910CB81e23e1c41c32',
        decimals: 18,
        logoURI:
          'https://uploads-ssl.webflow.com/63d9b0d075e8161e9281ebdd/6621d1e762dc2c55d56e3080_sFRAX.svg',
        name: 'FRAX staked FRAX',
        symbol: 'sFRAX',
        tags: ['FRAX'],
      },
      {
        chainId: 1,
        address: '0x5E8422345238F34275888049021821E8E08CAa1f',
        decimals: 18,
        logoURI:
          'https://uploads-ssl.webflow.com/63d9b0d075e8161e9281ebdd/6621d1e762dc2c55d56e30a3_frxETH.svg',
        name: 'FRAX ETH',
        symbol: 'frxETH',
        tags: ['FRAX', 'ETH'],
      },
      {
        chainId: 1,
        address: '0xac3E018457B222d93114458476f3E3416Abbe38F',
        decimals: 18,
        logoURI:
          'https://uploads-ssl.webflow.com/63d9b0d075e8161e9281ebdd/6621d21093d79ece8ce9adbe_sfrxETH.svg',
        name: 'FRAX staked ETH',
        symbol: 'sfrxETH',
        tags: ['FRAX', 'LST'],
      },
      {
        chainId: 1,
        address: '0x18f313Fc6Afc9b5FD6f0908c1b3D476E3feA1DD9',
        symbol: 'egETH',
        name: 'Eigenpie restaked ETH',
        decimals: 18,
        logoURI: egethLogo,
        tags: ['LRT', 'Eigenpie'],
      },
      {
        chainId: 1,
        address: '0xf7906F274c174A52d444175729E3fa98f9bde285',
        decimals: 18,
        logoURI:
          'https://uploads-ssl.webflow.com/63d9b0d075e8161e9281ebdd/662f249c616cf983c9a469fe_PT - ezETH.svg',
        name: 'Renzo 26 Dec 2024',
        symbol: 'Pendle PT ezETH',
        tags: ['Pendle'],
      },
      {
        chainId: 1,
        address: '0x83F20F44975D03b1b09e64809B757c47f942BEeA',
        symbol: 'sDAI',
        name: 'Maker savings DAI',
        decimals: 18,
        logoURI:
          'https://uploads-ssl.webflow.com/63d9b0d075e8161e9281ebdd/6642cffa9236463752554b8d_sDAI.svg',
        tags: ['Stables'],
      },
      {
        chainId: 1,
        address: '0x572975FF6d5136c81c8d7448B6361eF9EEfE1AB0',
        symbol: 'wstUSDT',
        name: 'Tron wrapped staked USDT',
        decimals: 18,
        logoURI:
          'https://uploads-ssl.webflow.com/63d9b0d075e8161e9281ebdd/6642cffabd158e7f5a46ddee_wstUSDT.svg',
        tags: ['Stables'],
      },
      {
        chainId: 1,
        address: '0x08c6F91e2B681FaF5e17227F2a44C307b3C1364C',
        symbol: 'Liquid USD',
        name: 'EtherFi Market-Neutral USD',
        decimals: 6,
        logoURI:
          'https://uploads-ssl.webflow.com/63d9b0d075e8161e9281ebdd/6642cffa80e3978b12822c3a_EtherFi Liquid.svg',
        tags: ['Vaults'],
      },
      {
        chainId: 1,
        address: '0x9Ba021B0a9b958B5E75cE9f6dff97C7eE52cb3E6',
        symbol: 'apxETH',
        name: 'Dinero auto-compounding pxETH',
        decimals: 18,
        logoURI:
          'https://uploads-ssl.webflow.com/63d9b0d075e8161e9281ebdd/6642cff987cd9b7f45d30856_apxETH.svg',
        tags: ['Vaults'],
      },
      {
        chainId: 1,
        address: '0x04C154b66CB340F3Ae24111CC767e0184Ed00Cc6',
        symbol: 'pxETH',
        name: 'Dinero staked ETH',
        decimals: 18,
        logoURI:
          'https://uploads-ssl.webflow.com/63d9b0d075e8161e9281ebdd/6642cff9d5fd86fcb4204a16_pxETH.svg',
        tags: ['LST'],
      },
      {
        name: 'Derive rswETH cov. call',
        symbol: 'rswETHC',
        address: '0xE9A12fB15cC00b59867E4E2f0aCbdCebfd32b3d7',
        chainId: 1,
        decimals: 18,
        logoURI: deriveProtocolLogoUrl,
        tags: ['Options'],
      },
      {
        name: 'Derive eETH cov. call',
        symbol: 'weETHC',
        address: '0xcAe44C93f7B3b519Fc28f9d4F7Ae22dE770a907b',
        chainId: 1,
        decimals: 18,
        logoURI: deriveProtocolLogoUrl,
        tags: ['Options'],
      },
      {
        name: 'Symbiotic wrapped stETH',
        symbol: 'DC_wstETH',
        address: '0xC329400492c6ff2438472D4651Ad17389fCb843a',
        chainId: 1,
        decimals: 18,
        logoURI:
          'https://uploads-ssl.webflow.com/63d9b0d075e8161e9281ebdd/66693e5d00dc4d1921a9395e_Symbiotic.svg',
        tags: ['Symbiotic'],
      },
      {
        name: 'Symbiotic swETH',
        symbol: 'DC_swETH',
        address: '0x38B86004842D3FA4596f0b7A0b53DE90745Ab654',
        chainId: 1,
        decimals: 18,
        logoURI:
          'https://uploads-ssl.webflow.com/63d9b0d075e8161e9281ebdd/66693e5d00dc4d1921a9395e_Symbiotic.svg',
        tags: ['Symbiotic'],
      },
      {
        name: 'Karak 26 Sep 2024',
        symbol: 'Pendle PT sUSDe',
        address: '0xd351de53277c4218CC29f793263FB60E2fcFC1dC',
        chainId: 1,
        decimals: 18,
        logoURI:
          'https://uploads-ssl.webflow.com/63d9b0d075e8161e9281ebdd/66790f477f14dcfecd20165f_PT_sUSDe-Karak.svg',
        tags: ['Pendle'],
      },
      {
        name: 'Karak 26 Sep 2024',
        symbol: 'Pendle PT USDe',
        address: '0x791c1E9D38029092E8aD1E252d0Bb97261809A2c',
        chainId: 1,
        decimals: 18,
        logoURI:
          'https://uploads-ssl.webflow.com/63d9b0d075e8161e9281ebdd/66790f47e7844a9625845d51_PT_USDe_Karak.svg',
        tags: ['Pendle'],
      },
      {
        name: 'Ethena 26 Sep 2024',
        symbol: 'Pendle PT sUSDe',
        address: '0x6c9f097e044506712B58EAC670c9a5fd4BCceF13',
        chainId: 1,
        decimals: 18,
        logoURI:
          'https://uploads-ssl.webflow.com/63d9b0d075e8161e9281ebdd/66790f47e82da844727960c7_PT_sUSDe.svg',
        tags: ['Pendle'],
      },
      {
        name: 'Karak 26 Sep 2024',
        symbol: 'Pendle PT eETH',
        address: '0x5E9e4bFd81c8c03F8289b827C9B92332789b6fe1',
        chainId: 1,
        decimals: 18,
        logoURI:
          'https://uploads-ssl.webflow.com/63d9b0d075e8161e9281ebdd/66790f47fcac42fccdc96e47_PT_eETH_karak.svg',
        tags: ['Pendle'],
      },
      {
        name: 'Mellow MEV Capital 25 Jul 2024',
        symbol: 'Pendle PT amphrETH',
        address: '0x4CA48e7Dd3970F96EE74D6A573Ca5678EA1EB31b',
        chainId: 1,
        decimals: 18,
        logoURI:
          'https://uploads-ssl.webflow.com/63d9b0d075e8161e9281ebdd/6678c2d825bcd4ef95f61b22_Mellow_amphrETH.svg',
        tags: ['Pendle'],
      },
      {
        name: 'Mellow Re7 Labs 25 Jul 2024',
        symbol: 'Pendle PT Re7LRT',
        address: '0x2B91426E6F2A88B2C4Afc6c279fA8842A23EBD7F',
        chainId: 1,
        decimals: 18,
        logoURI:
          'https://uploads-ssl.webflow.com/63d9b0d075e8161e9281ebdd/6678c2d8d6ce6d112879b3b7_Mellow_re7LRT.svg',
        tags: ['Pendle'],
      },
      {
        name: 'Mellow Steakhouse 25 Jul 2024',
        symbol: 'Pendle PT steakLRT',
        address: '0x2ED065f447971768c1117E4A36C80F4a54eb88FA',
        chainId: 1,
        decimals: 18,
        logoURI:
          'https://uploads-ssl.webflow.com/63d9b0d075e8161e9281ebdd/6678c2d825bcd4ef95f61b25_Mellow_steakLRT.svg',
        tags: ['Pendle'],
      },
      {
        name: 'Mellow P2P.org 25 Jul 2024',
        symbol: 'Pendle PT rstETH',
        address: '0x997949eEA781c04E4801d9c0902540236A317B07',
        chainId: 1,
        decimals: 18,
        logoURI:
          'https://uploads-ssl.webflow.com/63d9b0d075e8161e9281ebdd/6678c2d864f7d36f5cd7d46e_Mellow_rstETH.svg',
        tags: ['Pendle'],
      },
      {
        name: 'Sturdy Swell Aggregator',
        symbol: 'SWELL-agg',
        address: '0xBd564BFE6F2366336ecD707862EDa79b97BCE383',
        chainId: 1,
        decimals: 18,
        logoURI:
          'https://uploads-ssl.webflow.com/63d9b0d075e8161e9281ebdd/6638739d6f4511ef50dbb091_Sturdy.svg',
        tags: ['Lend'],
      },
      {
        name: 'Swell 26 Sep 2024',
        symbol: 'Pendle PT rswETH',
        address: '0xF0574d8B9Dc3a9DE768eaa7DBB7bB0C68521b148',
        chainId: 1,
        decimals: 18,
        logoURI: pendleRswethLogoUrl,
        tags: ['Pendle'],
      },
      {
        name: 'EtherFi 26 Sep 2024',
        symbol: 'Pendle PT eETH',
        address: '0x1c085195437738d73d75DC64bC5A3E098b7f93b1',
        chainId: 1,
        decimals: 18,
        logoURI: pendleWeethLogoUrl,
        tags: ['Pendle'],
      },
      {
        name: 'Renzo 26 Sep 2024',
        symbol: 'Pendle PT ezETH',
        address: '0x248241244356D18f63d0c16082996839ecB0C7bF',
        chainId: 1,
        decimals: 18,
        logoURI: pendleEzethLogoUrl,
        tags: ['Pendle'],
      },
      {
        name: 'Puffer 26 Sep 2024',
        symbol: 'Pendle PT pufETH',
        address: '0xd4e75971eAF78a8d93D96df530f1FFf5f9F53288',
        chainId: 1,
        decimals: 18,
        logoURI: pendlePufethLogoUrl,
        tags: ['Pendle'],
      },
      {
        name: 'Amphor restaked ETH',
        symbol: 'amphrETH',
        address: '0x5fD13359Ba15A84B76f7F87568309040176167cd',
        chainId: 1,
        decimals: 18,
        logoURI: amphorEthLogoUrl,
        tags: ['LRT'],
      },
      {
        name: 'Symbiotic wrapped Binance beacon ETH',
        symbol: 'DC_wBETH',
        address: '0x422F5acCC812C396600010f224b320a743695f85',
        chainId: 1,
        decimals: 18,
        logoURI:
          'https://uploads-ssl.webflow.com/63d9b0d075e8161e9281ebdd/66693e5d00dc4d1921a9395e_Symbiotic.svg',
        tags: ['Symbiotic'],
      },
      {
        name: 'Symbiotic Coinbase wrapped staked ETH',
        symbol: 'DC_cbETH',
        address: '0xB26ff591F44b04E78de18f43B46f8b70C6676984',
        chainId: 1,
        decimals: 18,
        logoURI:
          'https://uploads-ssl.webflow.com/63d9b0d075e8161e9281ebdd/66693e5d00dc4d1921a9395e_Symbiotic.svg',
        tags: ['Symbiotic'],
      },
      {
        name: 'Symbiotic Rocket Pool ETH',
        symbol: 'DC_rETH',
        address: '0x03Bf48b8A1B37FBeAd1EcAbcF15B98B924ffA5AC',
        chainId: 1,
        decimals: 18,
        logoURI:
          'https://uploads-ssl.webflow.com/63d9b0d075e8161e9281ebdd/66693e5d00dc4d1921a9395e_Symbiotic.svg',
        tags: ['Symbiotic'],
      },
      {
        name: 'Symbiotic Mantle ETH',
        symbol: 'DC_mETH',
        address: '0x475D3Eb031d250070B63Fa145F0fCFC5D97c304a',
        chainId: 1,
        decimals: 18,
        logoURI:
          'https://uploads-ssl.webflow.com/63d9b0d075e8161e9281ebdd/66693e5d00dc4d1921a9395e_Symbiotic.svg',
        tags: ['Symbiotic'],
      },
      {
        name: 'Symbiotic staked Frax Ether',
        symbol: 'DC_sfrxETH',
        address: '0x5198CB44D7B2E993ebDDa9cAd3b9a0eAa32769D2',
        chainId: 1,
        decimals: 18,
        logoURI:
          'https://uploads-ssl.webflow.com/63d9b0d075e8161e9281ebdd/66693e5d00dc4d1921a9395e_Symbiotic.svg',
        tags: ['Symbiotic'],
      },
      {
        name: 'Symbiotic Stader ETHx',
        symbol: 'DC_ETHx',
        address: '0xBdea8e677F9f7C294A4556005c640Ee505bE6925',
        chainId: 1,
        decimals: 18,
        logoURI:
          'https://uploads-ssl.webflow.com/63d9b0d075e8161e9281ebdd/66693e5d00dc4d1921a9395e_Symbiotic.svg',
        tags: ['Symbiotic'],
      },
      {
        name: 'Adapter rswETH vault',
        symbol: 'aPT-rswETH',
        address: '0xe6cD0b7800cA3e297b8fBd7697Df9E9F6A27f0F5',
        chainId: 1,
        decimals: 18,
        logoURI:
          'https://uploads-ssl.webflow.com/63d9b0d075e8161e9281ebdd/669f0748536bae8ccf26fdd2_Adapter.fi.svg',
        tags: ['Vaults'],
      },
      {
        name: 'Pendle PT sUSDe',
        symbol: 'Ethena 24 Oct 2024',
        address: '0xae5099c39f023c91d3dd55244cafb36225b0850e',
        chainId: 1,
        decimals: 18,
        logoURI:
          'https://uploads-ssl.webflow.com/63d9b0d075e8161e9281ebdd/66b02db3d9a9003eed94e649_Pendle_PT-sUSDe.svg',
        tags: ['Pendle'],
      },
      {
        name: 'Pendle PT USDe',
        symbol: 'Ethena 24 Oct 2024',
        address: '0x9468b647b36A23af2E48DaB81a178596a6aF95D9',
        chainId: 1,
        decimals: 18,
        logoURI:
          'https://uploads-ssl.webflow.com/63d9b0d075e8161e9281ebdd/66b02ecd10de7d4d50b0b733_Pendle_PT-USDe.svg',
        tags: ['Pendle'],
      },
      {
        name: 'Pendle PT pzETH',
        symbol: 'Mellow Renzo 26 Sep 2024',
        address: '0xdbd8a24e780e7661c5c9f66310a3d4ad309cd763',
        chainId: 1,
        decimals: 18,
        logoURI:
          'https://uploads-ssl.webflow.com/63d9b0d075e8161e9281ebdd/66b02c1a65a7d45541be4362_Mellow_pzETH.svg',
        tags: ['Pendle'],
      },
      {
        name: 'Swell restaked BTC',
        symbol: 'swBTC',
        address: TOKEN_LIST_SWBTC.address,
        chainId: 1,
        decimals: TOKEN_LIST_SWBTC.decimals,
        logoURI: TOKEN_LIST_SWBTC.logoURI,
        tags: ['LRT', 'Swell'],
      },
      {
        name: 'Restaked SWELL',
        symbol: 'rSWELL',
        address: TOKEN_LIST_RSWELL.address,
        chainId: 1,
        decimals: TOKEN_LIST_RSWELL.decimals,
        logoURI: TOKEN_LIST_RSWELL.logoURI,
        tags: ['LRT', 'Swell'],
      },
    ],
    timestamp: '2024-04-15T12:59:36.659Z',
    version: '1',
  }
}
