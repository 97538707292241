import { EcosystemPartnerInfo } from './types'
import altlayerLogo from '@/assets/images/predeposit/altlayer.png'
import ambientLogo from '@/assets/images/predeposit/ambient.png'
import bixinLogo from '@/assets/images/predeposit/bixin.png'
import brahmaLogo from '@/assets/images/predeposit/brahma.png'
import chainlinkLogo from '@/assets/images/predeposit/chainlink.png'
import eigenlayerLogo from '@/assets/images/predeposit/eigenlayer.png'
import eigenpieLogo from '@/assets/images/eigenpie_40x40.png'
import etherfiLogo from '@/assets/images/predeposit/etherfi.png'
import frameworkLogo from '@/assets/images/predeposit/framework.png'
import hexensLogo from '@/assets/images/predeposit/hexens.png'
import ionLogo from '@/assets/images/predeposit/ion.png'
import iosgLogo from '@/assets/images/predeposit/iosg.png'
import lidoLogo from '@/assets/images/predeposit/lido.png'
import maven11Logo from '@/assets/images/predeposit/maven11.png'
import mixbytesLogo from '@/assets/images/predeposit/mixbytes.png'
import pendleLogo from '@/assets/images/predeposit/pendle.png'
import redstoneLogo from '@/assets/images/predeposit/redstone.png'
import renzoLogo from '@/assets/images/predeposit/renzo.png'
import spartanLogo from '@/assets/images/predeposit/spartan.png'
import stakestoneLogo from '@/assets/images/predeposit/stakestone.png'
import swellLogo from '@/assets/images/predeposit/swell.png'
import bubblyFinanceLogo from '@/assets/images/bubbly_finance_80x80.png'
import optimismLogo from '@/assets/images/optimism_160x160.png'

const fraxLogo =
  'https://uploads-ssl.webflow.com/63d9b0d075e8161e9281ebdd/6621d1e62f3a23452031405a_FRAX.svg'
const ethenaLogo =
  'https://uploads-ssl.webflow.com/63d9b0d075e8161e9281ebdd/66304afbb2d8ae4b9a233b55_Ethena.svg'
const sturdyFinanceLogo =
  'https://uploads-ssl.webflow.com/63d9b0d075e8161e9281ebdd/6638739d6f4511ef50dbb091_Sturdy.svg'
const apolloCryptoLogo =
  'https://uploads-ssl.webflow.com/63d9b0d075e8161e9281ebdd/663874f35c61c859b398905d_Apollo.svg'
const kainSynthetixImg =
  'https://uploads-ssl.webflow.com/63d9b0d075e8161e9281ebdd/65b704135525915bb028480d_Kain%20Warwick.png'
const samFraxImg =
  'https://uploads-ssl.webflow.com/63d9b0d075e8161e9281ebdd/663876ec21b6a99cbfa4f4e2_Sam.svg'
const michaelCurveImg =
  'https://uploads-ssl.webflow.com/63d9b0d075e8161e9281ebdd/663877eb6f4511ef50df40ee_Michael.svg'
const tnPendleImg =
  'https://uploads-ssl.webflow.com/63d9b0d075e8161e9281ebdd/663876ebe19f1be233b12e22_TN.svg'
const anthonySassanoImg =
  'https://uploads-ssl.webflow.com/63d9b0d075e8161e9281ebdd/65b70413ad24d6836fe9c5f2_Anthony%20Sassano.png'
const droseraLogo =
  'https://uploads-ssl.webflow.com/63d9b0d075e8161e9281ebdd/6639889a5cf3c36621da47d9_Drosera.svg'
const dittoLogo =
  'https://uploads-ssl.webflow.com/63d9b0d075e8161e9281ebdd/6655688570abf5569b5f1b9e_Ditto.svg'
const versatusLogo =
  'https://uploads-ssl.webflow.com/63d9b0d075e8161e9281ebdd/66556885bac265137bbdf120_Versatus.svg'
const symbioticLogo =
  'https://uploads-ssl.webflow.com/63d9b0d075e8161e9281ebdd/66693e5d00dc4d1921a9395e_Symbiotic.svg'
const jigsawLogo =
  'https://uploads-ssl.webflow.com/63d9b0d075e8161e9281ebdd/66a9b49d64d6795fbbbcf383_Jigsaw.svg'

export const ETH_ALIAS = '0xEeeeeEeeeEeEeeEeEeEeeEEEeeeeEeeeeeeeEEeE'

export const L2_POWERED_BY_PARTNERS = [
  {
    name: 'Swell',
    logo: swellLogo,
  },
  {
    name: 'Eigenlayer',
    logo: eigenlayerLogo,
  },
  {
    name: 'Etherfi',
    logo: etherfiLogo,
  },
  {
    name: 'Renzo',
    logo: renzoLogo,
  },
  {
    name: 'Eigenpie',
    logo: eigenpieLogo,
  },
  {
    name: 'Stakestone',
    logo: stakestoneLogo,
  },
  {
    name: 'Symbiotic',
    logo: symbioticLogo,
  },
  {
    name: 'Lido',
    logo: lidoLogo,
  },
  {
    name: 'Pendle',
    logo: pendleLogo,
  },
  {
    name: 'FRAX',
    logo: fraxLogo,
  },
  {
    name: 'Ethena',
    logo: ethenaLogo,
  },
]

export const L2_INFRASTRUCTURE_PARTNERS: EcosystemPartnerInfo[] = [
  {
    name: 'Optimism',
    logo: optimismLogo,
  },
  {
    name: 'Altlayer',
    logo: altlayerLogo,
  },
  {
    name: 'Chainlink',
    logo: chainlinkLogo,
  },
  {
    name: 'Redstone',
    logo: redstoneLogo,
  },
]

export const L2_ECOSYSTEM_PROJECTS: EcosystemPartnerInfo[] = [
  {
    name: 'Ambient',
    category: 'dex',
    logo: ambientLogo,
  },
  {
    name: 'ION protocol',
    category: 'lending',
    logo: ionLogo,
  },
  {
    name: 'BRAHMA',
    category: 'infrastructure',
    logo: brahmaLogo,
  },
  {
    name: 'Sturdy Finance',
    category: 'lending',
    logo: sturdyFinanceLogo,
  },
  {
    name: 'Jigsaw',
    category: 'Stablecoin',
    logo: jigsawLogo,
  },
  {
    name: 'Bubbly Finance',
    category: 'dex',
    logo: bubblyFinanceLogo,
  },
]

export const L2_ECOSYSTEM_AVS: EcosystemPartnerInfo[] = [
  {
    name: 'Drosera',
    category: 'security',
    logo: droseraLogo,
  },
  {
    name: 'Ditto',
    category: 'infrastructure',
    logo: dittoLogo,
  },
  {
    name: 'Versatus',
    category: 'infrastructure',
    logo: versatusLogo,
  },
]

export const L2_AUDITED_BY: EcosystemPartnerInfo[] = [
  {
    name: 'Hexens',
    logo: hexensLogo,
  },
  {
    name: 'Mixbytes',
    logo: mixbytesLogo,
  },
]

export const L2_BACKED_BY: EcosystemPartnerInfo[] = [
  {
    name: 'Framework',
    logo: frameworkLogo,
  },
  {
    name: 'Maven 11',
    logo: maven11Logo,
  },
  {
    name: 'IOSG',
    logo: iosgLogo,
  },
  {
    name: 'Spartan',
    logo: spartanLogo,
  },
  {
    name: 'Bixin',
    logo: bixinLogo,
  },
  {
    name: 'Apollo Crypto',
    logo: apolloCryptoLogo,
  },
  {
    name: 'Kain',
    category: 'synthetix',
    logo: kainSynthetixImg,
  },
  {
    name: 'Sam',
    category: 'FRAX',
    logo: samFraxImg,
  },
  {
    name: 'Michael',
    category: 'Curve',
    logo: michaelCurveImg,
  },
  {
    name: 'TN',
    category: 'Pendle',
    logo: tnPendleImg,
  },
  {
    name: 'Anthony Sassano',
    logo: anthonySassanoImg,
  },
]

export const etherFiLiquidUsdAddress =
  '0x08c6F91e2B681FaF5e17227F2a44C307b3C1364C'

export const NEW_PREDEPOSIT_TOKENS: Set<string> = new Set([
  '0xAE5099C39f023C91d3dd55244CAFB36225B0850E', // Pendle PT sUSDe | Ethena 24 Oct 2024
  '0x9468b647b36A23af2E48DaB81a178596a6aF95D9', // Pendle PT USDe | Ethena 24 Oct 2024
  '0xdBD8a24E780E7661c5c9f66310A3D4Ad309cD763', // Pendle PT pzETH | Mellow Renzo 26 Sep 2024
  '0x8DB2350D78aBc13f5673A411D4700BCF87864dDE', // swBTC
])
