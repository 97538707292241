import styled from 'styled-components'
import { DecoBox } from '.'
import { useState } from 'react'
import { scaleLinear } from '@/util/charts'
import { useNowMsAnimation } from '@/hooks/useTimeCountdown'
import { css } from 'styled-components'

const PRESS_DURATION = 140

export function DecoRoundButton({
  hide,
  disabled,
  children,
  onClick: onClickProp,
  ...props
}: React.ComponentProps<typeof DecoBox> & {
  disabled?: boolean
}) {
  const nowMs = useNowMsAnimation()
  const [lastPressedTimestamp, setLastPressedTimestamp] = useState(0)

  const t = nowMs - lastPressedTimestamp

  let progress = scaleLinear(t, [0, PRESS_DURATION], [0, 1])
  progress = Math.min(1, Math.max(0, progress))
  const depress = Math.sin(progress * Math.PI)

  function onClick() {
    if (disabled) return
    setLastPressedTimestamp(nowMs)
    onClickProp?.()
  }

  return (
    <ButtonBox
      width="61px"
      height="61px"
      {...props}
      onClick={onClick}
      round
      aria-disabled={disabled}
      depress={depress}
    >
      <div className="btn">{children}</div>
    </ButtonBox>
  )
}

const ButtonBox = styled(DecoBox)<{ depress: number }>`
  flex: 0 0 auto;

  &[aria-disabled='true'] {
    opacity: 0.5;
    pointer-events: none;
  }
  :not(&[aria-disabled='true']) {
    cursor: pointer;
  }

  padding: 16.316px;

  position: relative;
  ::after {
    z-index: 1;
    content: '';
    position: absolute;

    top: 0;
    left: 0;
    bottom: 0;
    right: 0;

    background: rgba(255, 255, 255, 0.05);
    transition: background-color 0.2s;
    border-radius: 50%;
  }

  .btn {
    z-index: 2;
  }

  ::before {
    z-index: 3;
    content: '';
    position: absolute;

    top: 0;
    left: 0;
    bottom: 0;
    right: 0;

    background: transparent;
    border-radius: 50%;

    ${(props) => {
      const { depress } = props
      const alpha = 0.0 + 0.22 * depress

      return css`
        box-shadow: 0 0 9px 4px rgb(255, 255, 255, ${alpha}) inset;
      `
    }}
    transition: box-shadow 0.2s;

    transform: scale(1);
    transition: background-color 0.2s;
  }

  :hover {
    ::after {
      background: rgba(255, 255, 255, 0.09);
    }
  }
`
