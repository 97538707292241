import React, { useEffect, useRef, useState } from 'react'
import { useHasAcceptedAirdropTerms } from './airdropHooks'
import { Dialog } from '@/swell-ui/Dialog'
import styled, { css } from 'styled-components'
import { StyledButtonThin } from './AirdropButtons'
import { DecoBox } from '../DecoBox'
import { useMediaQuery } from '@mui/material'

export function AirdropToSModal({ open }: { open?: boolean }) {
  const { accept, hasAcceptedTerms } = useHasAcceptedAirdropTerms()

  const [hasScrolledToBottom, setHasScrolledToBottom] = useState(false)
  const scrollboxRef = useRef<HTMLDivElement>(null)
  useEffect(() => {
    let done = false

    function animate() {
      if (done) return
      if (scrollboxRef.current) {
        const { scrollHeight, clientHeight, scrollTop } = scrollboxRef.current

        const atBottom = scrollHeight - clientHeight === scrollTop
        if (atBottom) {
          setHasScrolledToBottom(true)
          done = true
        }
      }
      requestAnimationFrame(animate)
    }

    requestAnimationFrame(animate)

    return () => {
      done = true
    }
  }, [])

  const ackButton = (
    <StyledButtonThin onClick={accept}>I acknowledge</StyledButtonThin>
  )

  return (
    <MuiDialog open={!!open && !hasAcceptedTerms}>
      <Layout
        direction="column"
        gap="16"
        padding="42px !important"
        align="start"
        opaque
      >
        <h2>Terms &amp; Conditions</h2>
        <ScrollBox ref={scrollboxRef}>
          <p>
            <strong>
              TOKEN AIRDROP TERMS &amp; CONDITIONS
              <br />
            </strong>
            <em>Last Revised on August 31, 2024</em>
          </p>
          <p>
            These Token Airdrop Program Terms &amp; Conditions (“
            <strong>Airdrop Terms</strong>”) govern the participation in the
            airdrop program (the “Airdrop”) organized by LD Software
            Corporation, a company incorporated in Panama (the “
            <strong>Organization</strong>”, “<strong>we</strong>,” or “
            <strong>us</strong>”) and, where applicable, the receipt of tokens
            (“<strong>$SWELL</strong>” or “<strong>Tokens</strong>”) through the
            Program. Please read these Airdrop Terms carefully, as they include
            important information about your legal rights. By participating in
            the Airdrop or, where applicable, claiming Tokens through the
            Airdrop, you acknowledge that they have read, understood and agreed
            to these Airdrop Terms in their entirety, and agree to be bound by
            these Airdrop Terms. You are responsible for making its own decision
            in respect of its participation in the Airdrop and any receipt of
            Tokens. Any participation is entirely at your own risk and it is
            your sole responsibility to seek appropriate professional, legal ,
            financial, tax and other advice in respect of the Airdrop and any
            receipt of the Tokens thereto prior to participating in the Airdrop
            and prior to receiving any such Tokens.
          </p>
          <p>
            For purposes of these Airdrop Terms, “you” and “your” means you as
            the participant in the Airdrop. If you participate in the Airdrop or
            otherwise claim the Tokens on behalf of a company or other entity,
            then “you” includes you and that entity, and you represent and
            warrant that (a) you are an authorized representative of the entity
            with the authority to bind the entity to these Airdrop Terms, and
            (b) you agree to these Airdrop Terms on the entity’s behalf.
          </p>
          <ol>
            <li>
              <p>
                <strong>Digital Wallet Requirement</strong>
                <br />
                <strong>1.1</strong> You hereby agree and confirm that you are
                the legal owner of the relevant Digital Asset Wallet address
                that you have provided for the purposes of the Airdrop and will
                not sell, assign, or transfer control of such address or the
                Tokens to third parties to circumvent any Transferability
                Restrictions (as defined below) or to knowingly redistribute
                Tokens to any third party that would violate these Airdrop Terms
                if claimed directly by such third party.
              </p>
            </li>
          </ol>
          <p>
            <strong>1.2</strong> To participate in the Airdrop, you will need to
            connect a compatible third-party digital wallet (“Wallet”). By using
            a Wallet, you agree that you are using the Wallet under the terms
            and conditions of the applicable third-party provider of such
            Wallet. Wallets are not associated with, maintained by, supported
            by, or affiliated with the Organization. We accept no responsibility
            or liability to you in connection with your use of a Wallet, and we
            make no representations or warranties regarding how any specific
            Wallet will operate in connection with the Airdrop or be compatible
            to participate in the Airdrop.{' '}
            <em>
              The private keys necessary to access the assets held in a Wallet
              are not held by the Organization. The Organization has no ability
              to help you access or recover your private keys and/or seed
              phrases for your Wallet. You are solely responsible for
              maintaining the confidentiality of your private keys and you are
              responsible for any transactions signed with your private keys,
              and the Organization assumes no liability for any unauthorized or
              erroneous transactions.The Organization will not be responsible or
              liable for any loss, non-delivery or forfeiture of Tokens or your
              ineligibility to participate in the Airdrop arising from your
              failure to provide or link a valid wallet address.
              <br />
              <br />
            </em>
          </p>
          <ol start={2}>
            <li>
              <p>
                <strong>Eligibility Requirements</strong>
                <br />
                You agree and acknowledge that you have full legal capacity and
                authority to bind yourself to and agree to these Airdrop Terms,
                that you are at least 18 years of age or are of legal age to
                form a binding contract under applicable laws. You are not
                eligible to participate in the Airdrop if you are domiciled,
                located in, or a resident (tax or otherwise) of, the United
                States or any other country that is subject to{' '}
                <a
                  target="_blank"
                  rel="noopener noreferrer nofollow"
                  href="https://ofac.treasury.gov/sanctions-programs-and-country-information"
                >
                  <span>sanctions</span>
                </a>{' '}
                by the Office of Foreign Assets Control (OFAC) or any other
                applicable sanctions authority or where participation in such
                Airdrops are prohibited, restricted or unauthorized in any form
                or manner whether in full or in part under the laws, regulatory
                requirements or rules in the Jurisdiction in which you are
                located
              </p>
            </li>
          </ol>
          <p>
            You agree and acknowledge that you are not a Prohibited Person and
            you will not use a VPN or other tool to circumvent any geoblock or
            other restrictions that we may have implemented for Airdrop
            participants. Any such circumvention, or attempted circumvention,
            may permanently disqualify you from participation in the Airdrop at
            our discretion. A “Prohibited Person” is any person or entity that
            is (a) the subject of any economic or trade sanctions administered
            or enforced by any governmental authority, including being
            designated on any list of prohibited or restricted parties by any
            governmental authority, such as the U.S. Treasury Department’s list
            of Specially Designated Nationals, the U.S. Department of Commerce
            Denied Persons List Entity List, the E.U. Consolidated List of
            persons, and the U.K. Consolidated List of Financial Sanctions
            Targets, (b) located, a resident of, or organized in any
            jurisdiction or territory that is the subject of comprehensive
            country-wide or regional economic sanctions or has been designated
            as “terrorist supporting” by the United Nations or the governmental
            authority of the European Union, United Kingdom, or the United
            States, or (c) owned or controlled by such persons or entities
            listed in (a)-(b).
            <br />
            <br />
          </p>
          <ol start={3}>
            <li>
              <p>
                <strong>Airdrop Tokens and Distribution</strong>
                <br />
                <strong>3.1.</strong> The Airdrop will be conducted during one
                or more specified periods, as determined by us at our sole and
                absolute discretion. Your eligibility to receive Tokens through
                the Airdrop or participate in the Airdrop is subject to our sole
                and absolute discretion, including, without limitation, the
                amount of Tokens to be distributed to you. To the extent you
                believe you should have received any Tokens from the Airdrop
                based on any documentation or points system released by us, our
                affiliates, or any other third party from time to time, such
                documentation or points system do not entitle you to, nor does
                it create any legitimate expectation for you to receive, any
                Tokens or to participate in the Airdrop, and therefore you have
                no claim for any such Tokens.
              </p>
            </li>
          </ol>
          <p>
            <strong>3.2. </strong>You agree not to engage in any activities that
            are designed to obtain more Tokens from the Airdrop than you would
            have been legitimately entitled to (including and not limited to the
            use of multi-accounts and/or multi-wallets, botting, spamming,
            sybiling or other forms of automation or other similar forms of
            conduct, and/or the cheating or hacking the Airdrop or Tokens). You
            agree that you are the legal owner of the Wallet address that you
            use to access or participate in the Airdrop. Successful
            participation in a prior Airdrop does not guarantee, nor shall it
            create an expectation of, participation in a subsequent Airdrop. Any
            such behavior will result in all of your accounts and/or Digital
            Asset Wallets being permanently banned from all of the
            Organization’s Airdrops.
          </p>
          <p>
            <strong>3.3.</strong> Participation in the Airdrop does not require
            any purchase or payment although there may be transaction fees
            payable by you to receive and/or claim your Tokens. For avoidance of
            such transaction fees (including gas fees) are paid to the third
            parties and/or network of computers that operate the blockchain
            and/or process the transactions and are not paid to us. Kindly note
            that such transaction fees (including gas fees) often fluctuate
            based on a number of factors, all of which are not under our
            control, and may therefore change at any time without notice.
            <br />
            <br />
          </p>
          <ol start={4}>
            <li>
              <p>
                <strong>Use of Tokens</strong>
              </p>
            </li>
          </ol>
          <p>
            <strong>4.1.</strong> You acknowledge and agree that the Tokens
            received through the Airdrop may have specific usage limitations,
            such as utility within a specific platform or ecosystem, and may
            have no monetary value. Additionally, you acknowledge and agree that
            the Tokens delivered pursuant to an Airdrop may be subject to such
            lock-up periods, vesting schedules or similar restrictions of
            transferability (collectively, “
            <strong>Transferability Restrictions</strong>”) as determined and
            imposed by the Organization from time to time at its sole and
            absolute discretion, and you hereby agree to abide and comply fully
            with such Transferability Restrictions.
          </p>
          <p>
            <strong>4.2.</strong> No Investment Product: Tokens received through
            the Airdrop are not intended as investment products nor securities,
            and you acknowledge that their value (if any) may fluctuate and that
            no guarantees are made regarding future value (if any). The prices
            of such digital or blockchain assets are extremely volatile,
            subjective and do not have any inherent or intrinsic value.
            Fluctuations in the price of other digital assets could materially
            and adversely affect the Tokens, which may also be subject to
            significant price volatility. In addition, a lack of use or public
            interest in the Tokens could negatively impact the potential utility
            or value of the Token. Each Token has no inherent or intrinsic
            value, as their value is inherently subjective and factors occurring
            outside of the Organization’s control may materially impact the
            value and desirability of the Tokens.
          </p>
          <p>
            <strong>4.3.</strong> No Promise of Usability: The organization and
            conduct of an Airdrop and the delivery of Tokens thereto to you and
            other participants of the Airdrop does not constitute, and shall not
            be deemed as, a promise by the Organization that the Tokens are
            usable, tradeable or provided for any purpose whatsoever, whether
            immediately after the Airdrop or any time thereafter. You hereby
            acknowledge and agree that the Tokens received via an Airdrop may
            not have an inherent value, usage or purpose, and that you have no
            expectation of profit, value or use in the Token.
          </p>
          <ol start={5}>
            <li>
              <p>
                <strong>Taxes </strong>
                <br />
                You agree and acknowledge that you have the sole responsibility
                and liability for all taxes in connection with your
                participation in the Airdrop and you should consult a tax
                advisor prior to participating in the Airdrop or receiving any
                Tokens as a consequence of the Airdrop.
                <br />
                <br />
              </p>
            </li>
            <li>
              <p>
                <strong>Compliance with Laws</strong>
                <br />
                You agree and acknowledge that you are solely responsible for
                complying with all applicable laws of the jurisdiction you are
                located in or participating in the Airdrop from.
                <br />
                <br />
              </p>
            </li>
            <li>
              <p>
                <strong>Screening and Compliance</strong>
                <br />
                The Organization may use third-party services to screen you and
                other participants for compliance with AML and sanctions
                regulations. You agree to provide any necessary information and
                undergo required checks, including screening of your wallet
                addresses and transaction history. The Organization reserves the
                right to disqualify you or any other participants based on
                results from these compliance checks or if it determines that
                participation violates legal or regulatory requirements.
                <br />
                <br />
              </p>
            </li>
            <li>
              <p>
                <strong>No Expectations of Tokens</strong>
                <br />
                You agree and acknowledge that you (a) may receive Tokens for
                free via the Airdrop (other than applicable taxes, if any) (for
                avoidance of doubt, this does not mean you are entitled to any
                Tokens from the Airdrop), (b) were not previously promised any
                Tokens, and (c) took no action in anticipation of or in reliance
                on receiving any Tokens or an Airdrop.
                <br />
                <br />
              </p>
            </li>
            <li>
              <p>
                <strong>
                  Disclaimers; Limitation of Liability
                  <br />
                  9.1.
                </strong>{' '}
                Your participation in the Airdrop is at your own risk. You
                understand and agree that any the Airdrop, and any Tokens
                thereto are provided to you on an “AS IS” and “AS AVAILABLE”
                basis. Without limiting the foregoing, to the maximum extent
                permitted under applicable law, the Organization, its parents,
                affiliates, related companies, officers, directors, employees,
                agents, representatives, partners, and licensors (the “
                <strong>Organization Entities</strong>”) DISCLAIM ALL WARRANTIES
                AND CONDITIONS, WHETHER EXPRESS, IMPLIED OR STATUTORY, INCLUDING
                WITHOUT LIMITATION ANY WARRANTIES RELATING TO TITLE,
                MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE,
                NON-INFRINGEMENT, USAGE, QUALITY, PERFORMANCE, SUITABILITY OR
                FITNESS OF THE AIRDROP OR TOKENS FOR ANY PARTICULAR PURPOSE, OR
                AS TO THE ACCURACY, QUALITY, SEQUENCE, RELIABILITY, WORKMANSHIP
                OR TECHNICAL CODING THEREOF, OR THE ABSENCE OF ANY DEFECTS
                THEREIN WHETHER LATENT OR PATENT. The Organization Entities make
                no warranty or representation and disclaim all responsibility
                and liability for: (a) the completeness, accuracy, availability,
                timeliness, security, or reliability of the Airdrop or Tokens;
                (b) any harm to your computer system, loss of data, or other
                harm that results from your participation in the Airdrop or use
                of Tokens; (c) the operation or compatibility with any other
                application or any particular system or device, including any
                Wallets; (d) whether the Tokens will be supported by or provide
                any utility or functionality in connection with any application
                or protocol; (e) whether the Airdrop will be available on an
                uninterrupted, secure, or error-free basis; and (f) any
                postponement, suspension and/or abortion of the Airdrop. Nothing
                contained in these Airdrop Terms constitutes, or is meant to
                constitute, financial, legal, or other professional advice.
                <br />
                <strong>9.2.</strong> THE LAWS OF CERTAIN JURISDICTIONS DO NOT
                ALLOW LIMITATIONS ON IMPLIED WARRANTIES OR THE EXCLUSION OR
                LIMITATION OF CERTAIN DAMAGES AS SET FORTH IN SECTION 7.3 BELOW.
                IF THESE LAWS APPLY TO YOU, SOME OR ALL OF THE ABOVE
                DISCLAIMERS, EXCLUSIONS, OR LIMITATIONS MAY NOT APPLY TO YOU,
                AND YOU MAY HAVE ADDITIONAL RIGHTS.
                <br />
                <strong>9.3.</strong> TO THE EXTENT NOT PROHIBITED BY LAW, YOU
                AGREE THAT IN NO EVENT WILL THE ORGANIZATION ENTITIES, OR ANY
                PERSON REPRESENTING THE ORGANIZATION BE LIABLE (A) FOR DAMAGES
                OF ANY KIND, INCLUDING INDIRECT, SPECIAL, EXEMPLARY, INCIDENTAL,
                CONSEQUENTIAL OR PUNITIVE DAMAGES (INCLUDING, BUT NOT LIMITED
                TO, PROCUREMENT OF SUBSTITUTE GOODS OR SERVICES, LOSS OF USE,
                DATA OR PROFITS, BUSINESS INTERRUPTION OR ANY OTHER DAMAGES OR
                LOSSES, ARISING OUT OF OR RELATED TO YOUR PARTICIPATION IN THE
                AIRDROP), HOWEVER CAUSED AND UNDER ANY THEORY OF LIABILITY,
                WHETHER UNDER THESE AIRDROP TERMS OR OTHERWISE ARISING IN ANY
                WAY IN CONNECTION WITH THE AIRDROP OR THESE AIRDROP TERMS AND
                WHETHER IN CONTRACT, STRICT LIABILITY OR TORT (INCLUDING
                NEGLIGENCE OR OTHERWISE) EVEN IF THE ORGANIZATION ENTITIES HAVE
                BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGE, OR (B) FOR ANY
                OTHER CLAIM, DEMAND OR DAMAGES WHATSOEVER RESULTING FROM OR
                ARISING OUT OF OR IN CONNECTION WITH THESE AIRDROP TERMS OR THE
                AIRDROP. THE ORGANIZATION ENTITIES’ TOTAL LIABILITY TO YOU FOR
                ANY DAMAGES FINALLY AWARDED SHALL NOT EXCEED ONE HUNDRED DOLLARS
                ($100.00). THE FOREGOING LIMITATIONS WILL APPLY EVEN IF THE
                ABOVE STATED REMEDY FAILS OF ITS ESSENTIAL PURPOSE.
              </p>
            </li>
          </ol>
          <p>
            <strong>9.4.</strong> You agree and acknowledge that claiming the
            Airdrop may require interaction with, reliance on, or an integration
            with third-party products or services (e.g., a wallet or a network
            or blockchain) that the Organization does not control. In the event
            that you or any other participant is unable to access such products,
            services, or integrations, or if they fail for any reason, and you
            or any other participant is unable to participate in the Airdrop or
            claim the Tokens as a result, you or any other participant will have
            no recourse or claim against the Organization or any Organization
            Entities; and neither the Organization nor any Organization Entities
            bear(s) any responsibility or liability to the you or any other
            participant.
          </p>
          <p>
            <strong>9.5. </strong>These Airdrop Terms are not intended to, and
            do not, create or impose any fiduciary duties on us. To the fullest
            extent permitted by law, you acknowledge and agree that the
            Organization owes no fiduciary duties or liabilities to the you or
            any other party, and that to the extent any such duties or
            liabilities may exist at law or in equity, those duties and
            liabilities are hereby irrevocably disclaimed, waived, and
            eliminated. You further agree that the only duties and obligations
            that the Organization owes you are those set out expressly in these
            Airdrop Terms.
            <strong>
              <br />
              <br />
            </strong>
          </p>
          <ol start={10}>
            <li>
              <p>
                <strong>
                  Assumption of Risks
                  <br />
                  10.1.
                </strong>{' '}
                By participating in the Airdrop, you represent that you have
                sufficient knowledge and experience in business and financial
                matters, including a sufficient understanding of blockchain
                technologies, cryptocurrencies, and other digital assets,
                storage mechanisms (such as wallets), and blockchain-based
                software systems to be able to assess and evaluate the risks and
                benefits of participating in the Airdrop. You acknowledge and
                agree that there are risks associated with purchasing and
                holding cryptocurrency and using blockchain technology. These
                include, but are not limited to, risk of losing access to
                cryptocurrency due to slashing, loss of private key(s),
                custodial error or purchaser error, risk of mining or blockchain
                attacks, risk of hacking and security weaknesses, risk of
                unfavorable regulatory intervention in one or more
                jurisdictions, risk related to token taxation, risk of personal
                information disclosure, risk of uninsured losses, volatility
                risks, and unanticipated risks.
                <br />
                <strong>10.2.</strong> You agree and acknowledge that if you
                lose access to your Wallet or private keys, you may never be
                able to access your Tokens. You agree that the Organization
                Entities shall have no responsibility for any such losses.
                <br />
                <strong>10.3.3.</strong> By participating in the Airdrop, you
                acknowledge that blockchain technologies, including the Tokens,
                involve inherent risks such as volatility, loss of private keys,
                smart contract vulnerabilities, and regulatory uncertainties.
                You also acknowledge that the Tokens may not provide specific
                functionality or have value, and you assume all risks associated
                with transactions, token utility, and network interactions. The
                Organization is not liable for any losses, including but not
                limited to technical malfunctions, smart contract errors, or
                market risks.
                <br />
                <br />
              </p>
            </li>
            <li>
              <p>
                <strong>Release</strong>
                <br />
                To the fullest extent permitted by applicable law, you release
                the Organization Entities from responsibility, liability,
                claims, demands, and/or damages (actual and consequential) of
                every kind and nature, known and unknown (including, but not
                limited to, claims of negligence), arising out of or related to
                disputes between participants in the Airdrop and the acts or
                omissions of third parties.
                <br />
                <br />
              </p>
            </li>
            <li>
              <p>
                <strong>Indemnity</strong>
                <br />
                To the fullest extent permitted by applicable law, you will
                indemnify, defend and hold harmless the Organization Entities
                from and against all liabilities, costs, damages, penalties,
                losses, claims, and expenses (including reasonable attorneys’
                fees) of any kind, arising from or related to (a) your
                participation in the Airdrop, (b) your violation of these
                Airdrop Terms, (c) your violation of any rights of any third
                party, (d) your violation of any applicable law, rule or
                regulation, (e) any disputes or issues between you and any third
                party; and (f) any other party’s access to or use of the Airdrop
                with your Wallet or private key.
                <br />
                <br />
              </p>
            </li>
            <li>
              <p>
                <strong>Termination</strong>
                <br />
                The Organization may suspend or terminate your access to the
                Airdrop at our sole discretion, without notice, including,
                without limitation, if we believe you violated these Airdrop
                Terms. Upon termination, you continue to be bound by Sections
                6-13 of these Airdrop Terms.
                <br />
                <br />
              </p>
            </li>
            <li>
              <p>
                <strong>
                  General Terms
                  <br />
                  14.1.
                </strong>{' '}
                These Airdrop Terms constitute the entire agreement between you
                and the Organization relating to your participation in the
                Airdrop and supersede all prior or contemporaneous
                communications, agreements, and proposals with respect to the
                Airdrop.
                <br />
                <strong>14.2.</strong> We reserve the right to modify these
                Airdrop Terms in our sole discretion at any time. If we make
                changes to these Airdrop Terms, we will provide notice of such
                changes, such as by posting a notice on our website, updating
                the “Last Revised” date above, or through other means. By
                continuing to participate in the Airdrop, you agree to the
                revised Airdrop Terms.
                <br />
                <strong>14.3.</strong> These Airdrop Terms do not, and shall not
                be construed to, create any partnership, joint venture,
                employer-employee, agency, or franchisor-franchisee relationship
                between you and the Organization. You may not assign, transfer,
                or delegate any or all of your rights or obligations under these
                Airdrop Terms without our prior written consent, and any attempt
                to do so will be null and void. We may freely assign, transfer,
                or delegate any of our rights and obligations under these
                Airdrop Terms.
                <br />
                <strong>14.4.</strong> If any term, clause, or provision of the
                Airdrop Terms is held to be illegal, invalid, void, or
                unenforceable (in whole or in part), then such term, clause, or
                provision shall be severable from the Airdrop Terms without
                affecting the validity or enforceability of any remaining part
                of that term, clause, or provision, or any other term, clause,
                or provision in the Airdrop Terms, which will otherwise remain
                in full force and effect. Any invalid or unenforceable
                provisions will be interpreted to affect the intent of the
                original provisions. If such construction is not possible, the
                invalid or unenforceable provision will be severed from the
                Airdrop Terms, but the rest of the Airdrop Terms will remain in
                full force and effect. No waiver of any provision of these
                Airdrop Terms will be deemed a further or continuing waiver of
                that term or any other term, and our failure to assert any right
                or provision under these Airdrop Terms will not constitute a
                waiver of that right or provision.
                <br />
                <br />
              </p>
            </li>
            <li>
              <p>
                <strong>Governing Law and Venue</strong>
                <br />
                These Airdrop Terms and any action related thereto will be
                governed by the laws of the Republic of Panama, without regard
                to its conflict of laws provisions. Any disputes relating to
                these Airdrop Terms shall be subject to the exclusive
                jurisdiction of the courts of Panama.
                <br />
                <br />
              </p>
            </li>
            <li>
              <p>
                <strong>
                  Contact Information
                  <br />
                </strong>{' '}
                If you have any questions about these Airdrop Terms, please
                contact us at{' '}
                <a
                  target="_blank"
                  rel="noopener noreferrer nofollow"
                  href="mailto:airdrop@overflowfoundation.io"
                >
                  <span>airdrop@overflowfoundation.io</span>
                </a>
                .
              </p>
            </li>
          </ol>
          <p>
            <strong>
              There is only one website for the Airdrop, which is the following:{' '}
            </strong>
            <a
              target="_blank"
              rel="noopener noreferrer nofollow"
              href="https://app.swellnetwork.io/dao/voyage"
            >
              <strong>https://app.swellnetwork.io/dao/voyage</strong>
            </a>
            <strong>
              . Do not trust any other website regardless of its source or
              origin or any link routing you to a different website.{' '}
            </strong>
          </p>
          <p>
            Please check the url correctly before taking any action on or
            interacting with any website. The Organization will not be liable to
            any persons for any loss, liability, damage, cost, or expense
            suffered as a result of any interaction with any scam or fake
            website that impersonates the Organization, the Airdrop, the Token
            or the Services.
          </p>
        </ScrollBox>
        <div />
        {ackButton}
      </Layout>
    </MuiDialog>
  )
}

const MuiDialog = styled(Dialog)`
  .MuiPaper-root {
    /* border-radius: 12px; */
    border: none;
    background: none;
    padding: none;
    overflow: visible;
  }
`

const ScrollBox = styled.div`
  overflow-y: scroll;
  overflow-x: hidden;
  width: 100%;
  padding-right: 8px;

  flex: 1 1 auto;

  max-height: 433px;

  ${({ theme }) =>
    theme.scrollbar &&
    css`
      &::-webkit-scrollbar {
        width: ${theme.scrollbar.width};
      }

      &::-webkit-scrollbar-track {
        background: ${theme.scrollbar.trackBg};
        border-radius: ${theme.scrollbar.borderRadius};
      }

      &::-webkit-scrollbar-thumb {
        background: ${theme.scrollbar.thumbBg};
        border-radius: ${theme.scrollbar.borderRadius};
      }
    `}
`

const Layout = styled(DecoBox)`
  h2 {
    margin: 0;
    color: var(--white-50, #fff);
    text-align: left;
    /* Body/large */
    font-family: Inter;
    font-size: 24px;
    font-style: normal;
    font-weight: 500;
    line-height: 160%; /* 38.4px */
    letter-spacing: -0.72px;
  }

  button {
    width: 100%;
  }

  max-height: 77vh;
`
