import { ROUTES } from '@/constants/routes'
import { TOKEN_LIST_RSWELL, TOKEN_LIST_SWELL } from '@/constants/tokens'
import { FlexRow } from '@/swell-ui/FlexRow'
import { useState } from 'react'
import { Link } from 'react-router-dom'
import styled, { css } from 'styled-components'
import swellL2Large from '@/assets/images/swell_l2_large.png'
import { CloseIcon } from '@/swell-ui/icons/CloseIcon'
import { useMediaQuery } from '@mui/material'

const linkCSS = css`
  color: var(--Swell-White-50, #fff);
  font-family: Inter;
  font-style: normal;
  font-weight: 500;
  line-height: 160%;
  letter-spacing: -0.42px;
  text-decoration-line: underline !important;

  :hover {
    color: var(--Swell-White-50, #fff) !important;
  }
`

const headlinesH7 = css`
  color: var(--Swell-White-50, #fff);
  /* Headlines/H7 */
  font-family: Inter;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: 130%; /* 26px */
  letter-spacing: -0.6px;
`

const bodySmall = css`
  color: var(--Swell-White-100, #e7e7e7);
  /* Body/small */
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 160%;
  letter-spacing: -0.42px;
`

export function BannerTGE() {
  const [open, setOpen] = useState<boolean>(true)
  const isMob = useMediaQuery('(max-width: 1090px)')

  const handleClose = () => {
    setOpen(false)
  }

  if (!open) {
    return null
  }

  const airdropIsHereNode = (
    <FlexRow gap="12" className="main-node" align="center">
      <img width="62" height="62" src={TOKEN_LIST_SWELL.logoURI} />
      <FlexRow direction="column" align="flex-start">
        <span className="b-title">Swell Airdrop is here!</span>
        <span className="b-body">
          Maximise your claim in two easy steps.{' '}
          <Link to={ROUTES.DaoVoyage}>Claim here</Link>.
        </span>
      </FlexRow>
    </FlexRow>
  )

  const step1Node = (
    <FlexRow
      className="step-node"
      align="center"
      width={isMob ? '100%' : '222px'}
      gap={isMob ? '27' : '16'}
      justify={isMob ? 'center' : 'flex-start'}
    >
      <img width="60" height="60" src={TOKEN_LIST_RSWELL.logoURI} />
      <FlexRow direction="column" align="flex-start">
        <span className="b-steptitle">Step 1</span>
        <span className="b-body">
          <Link to={ROUTES.DaoVoyage}>Claim</Link> and restake your SWELL for
          rSWELL
        </span>
      </FlexRow>
    </FlexRow>
  )

  const step2Node = (
    <FlexRow
      className="step-node"
      align="center"
      width={isMob ? '100%' : '222px'}
      gap={isMob ? '27' : '16'}
    >
      <img width="60" height="60" src={swellL2Large} />
      <FlexRow direction="column" align="flex-start">
        <span className="b-steptitle">Step 2</span>
        <span className="b-body">
          <Link to={ROUTES.Layer2SwellL2}>Deposit</Link> rSWELL into Swell L2.
        </span>
      </FlexRow>
    </FlexRow>
  )

  if (isMob) {
    return (
      <LayoutMob>
        <StyledCloseIcon onClick={handleClose} />
        {airdropIsHereNode}
        <VDivider />
        <HDivider />
        <FlexRow direction="column" gap="16">
          {step1Node}
          {step2Node}
        </FlexRow>
      </LayoutMob>
    )
  }

  return (
    <LayoutDesktop>
      <StyledCloseIcon onClick={handleClose} />
      <FlexRow width="393px">{airdropIsHereNode}</FlexRow>
      <VDivider />
      <HDivider />
      <FlexRow justify="space-between" gap="12" maxWidth="456px">
        {step1Node}
        {step2Node}
      </FlexRow>
    </LayoutDesktop>
  )
}

const StyledCloseIcon = styled(CloseIcon)`
  position: absolute;
  top: 8px;
  right: 10px;

  path {
    stroke: #fff;
  }

  &:hover {
    cursor: pointer;
    opacity: 0.7;
  }
`

const HDivider = styled.div`
  width: 100%;
  height: 1px;
  opacity: 0.2;
  background: #fff;
  max-width: 400px;
  margin: 0 auto;
`

const VDivider = styled.div`
  opacity: 0.2;
  background: #fff;
  width: 1px;
  height: 82px;
  margin: 0 auto;
`

const LayoutBase = styled.div`
  margin: 0 auto;
  border-radius: 24px;
  border: 1px solid var(--Gradient-Blue, #3068ef);
  background: var(
    --Gradient-Blue,
    linear-gradient(180deg, #3068ef 0.29%, #1322ac 167.95%)
  );

  position: relative;
`

const LayoutDesktop = styled(LayoutBase)`
  max-width: 964px;
  display: flex;
  flex-flow: row nowrap;
  padding: 16px;
  align-items: center;

  .b-title {
    ${headlinesH7};
    white-space: nowrap;
  }

  .b-body {
    ${bodySmall};
  }
  .b-steptitle {
    ${headlinesH7};
  }

  a {
    ${linkCSS};
  }

  ${HDivider} {
    display: none;
  }
`

const LayoutMob = styled(LayoutBase)`
  display: flex;
  flex-direction: column;
  max-width: 343px;

  padding: 42px 12px 24px;
  gap: 12px;

  .b-body {
    height: auto;
  }

  ${VDivider} {
    display: none;
  }

  ${HDivider} {
    display: block;
  }
  .step-node {
    max-width: 100%;

    > img {
      width: 42px;
      height: 42px;
      margin-left: 12px;
    }
  }

  .b-title {
    white-space: unset;
  }

  .b-title {
    ${headlinesH7};
    white-space: nowrap;
  }

  .b-body {
    ${bodySmall};
    height: unset;
  }
  .b-steptitle {
    ${headlinesH7};
  }
`
