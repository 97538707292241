import styled, { css } from 'styled-components/macro'
import { FlexRow } from '@swell-ui/FlexRow'
import { Typography } from '@swell-ui/Typography'
import { Token, TokenList } from '@/types/tokens'
import { BigNumber } from 'ethers'
import { displayCrypto } from '@/util/displayCrypto'
import { TOKEN_LIST_RSWELL, TOKEN_LIST_RSWETH } from '@/constants/tokens'

interface TokenListProps {
  tokens: TokenList['tokens']
  onTokenSelect: (tokenAddr: string) => any
  balances?: Record<string, BigNumber>
  isFetching?: boolean
  selectedToken?: string
  selectedTag?: string
  newTokenAddresses?: string[]
}

export function TokenListWidget({
  tokens: inputTokens,
  balances,
  selectedToken,
  selectedTag,
  onTokenSelect,
  isFetching,
  newTokenAddresses,
}: TokenListProps) {
  const isLoading = !balances

  const newTokensSet = new Set(newTokenAddresses)

  let tokens: Token[] = []
  // filter
  if (!selectedTag) {
    tokens = inputTokens
  } else {
    tokens = inputTokens.filter((token) => token.tags?.includes(selectedTag))
  }
  // sort
  tokens.sort((a, b) => {
    if (a.address === TOKEN_LIST_RSWELL.address) {
      return -1
    }
    if (b.address === TOKEN_LIST_RSWELL.address) {
      return 1
    }
    if (!balances) {
      return a.name.localeCompare(b.name)
    }
    const balanceA = balances[a.address] || BigNumber.from(0)
    const balanceB = balances[b.address] || BigNumber.from(0)

    if (balanceA.gt(balanceB)) {
      return -1
    }
    if (balanceA.lt(balanceB)) {
      return 1
    }
    return a.name.localeCompare(b.name)
  })

  return (
    <Positioner>
      <Flex>
        {isFetching && null}
        {isLoading && null}
        {tokens.map((token) => {
          return (
            <TokenItem
              key={token.address}
              onClick={() => onTokenSelect(token.address)}
              selected={selectedToken === token.address}
            >
              <LHS>
                <TokenIcon src={token.logoURI} />
                <FlexRow direction="column" align="flex-start" width="auto">
                  <TokenSymbolCell>
                    <SymbolTypography
                      variant="body"
                      size="medium"
                      letterSpacing="small"
                    >
                      {token.symbol}
                    </SymbolTypography>
                    {newTokensSet.has(token.address) && (
                      <NewButton>New</NewButton>
                    )}
                  </TokenSymbolCell>
                  <NameTypography
                    variant="body"
                    size="small"
                    letterSpacing="small"
                  >
                    {token.name}
                  </NameTypography>
                </FlexRow>
              </LHS>
              <RHSPositioner>
                <div>
                  <AmountTypography
                    variant="body"
                    size="large"
                    fstyle="bold"
                    letterSpacing="small"
                  >
                    <TokenItemContrastBG />
                    {token.address in (balances || {}) ? (
                      <span>
                        {displayCrypto(
                          balances![token.address],
                          token.decimals,
                          {
                            precision: 4,
                            localize: false,
                          }
                        )}
                      </span>
                    ) : (
                      <span />
                    )}
                  </AmountTypography>
                </div>
              </RHSPositioner>
            </TokenItem>
          )
        })}
      </Flex>
    </Positioner>
  )
}

const TokenItemContrastBG = styled.div`
  border-radius: 8px;
  mask: radial-gradient(
    circle at 50% 50%,
    white 0%,
    white 50%,
    transparent 100%
  );
`

const Flex = styled.div`
  display: flex;
  flex-flow: column nowrap;

  /* height: 628px; */
  height: 100%;
  overflow-y: scroll;
  border-radius: 12px;
  background: rgba(12, 8, 39, 0.8);

  > div:first-child {
    border-radius: 8px 8px 0 0;
  }

  > div:last-child {
    border-radius: 0 0 8px 8px;
    border-bottom: 0;
  }

  padding-right: 6px;

  ::-webkit-scrollbar {
    width: 8px;
  }

  ::-webkit-scrollbar-track {
    background: #16113f;
    border-radius: 6px;
  }

  ::-webkit-scrollbar-thumb {
    background: linear-gradient(90deg, #845aff 0%, #4943e0 100%);
    border-radius: 6px;
  }
`

const TokenItem = styled.div<{ selected: boolean }>`
  display: flex;
  flex-flow: row nowrap;
  align-items: center;

  padding: 12px;
  padding-right: 4px;
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
  background: rgba(12, 8, 39, 0.8);

  ${TokenItemContrastBG} {
    background: rgba(12, 8, 39, 0.8);
  }

  &:hover {
    cursor: pointer;
    background: #16113f;
    opacity: 0.7;

    ${TokenItemContrastBG} {
      background: #16113f;
    }
  }

  &:active {
    opacity: 0.5;
  }

  ${({ selected }) =>
    selected &&
    css`
      background: #16113f;

      ${TokenItemContrastBG} {
        background: #16113f;
      }
    `}
`

const NewButton = styled.button`
  align-self: center;

  width: 32px;
  padding: 2px;

  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  pointer-events: none;
  border-radius: 6px;
  background: var(
    --L2-gradient,
    linear-gradient(90deg, #845aff 0%, #4943e0 100%)
  );

  color: var(--white-50, #fff);
  font-family: Inter;
  font-size: 8px;
  font-style: normal;
  font-weight: 500;
  letter-spacing: 0.75px;
  text-transform: uppercase;

  border: 0;
  outline: 0;
`

const LHS = styled.div`
  display: grid;
  grid-template-columns: 40px auto;
  align-items: center;
  gap: 12px;
  width: auto;
  max-width: 210px;
`

const SymbolTypography = styled(Typography)`
  white-space: nowrap;
`
const TokenSymbolCell = styled.div`
  position: relative;
  ${NewButton} {
    position: absolute;

    top: 50%;
    left: 100%;
    transform: translate(4px, -50%) translate(0, -2px);
  }
`

const TokenIcon = styled.img`
  width: 40px;
  height: 40px;
  border-radius: 40px;
`

const NameTypography = styled(Typography)`
  color: ${({ theme }) => theme.colors.white['125']};
  max-width: 210px;
  width: auto;
  word-break: break-word;
  line-height: 1;
`

const Positioner = styled.div`
  flex-grow: 1;
  position: relative;

  ${Flex} {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
  }
`

const RHSPositioner = styled.div`
  position: relative;
  flex-grow: 1;
  align-self: stretch;

  > div {
    position: absolute;

    top: 0;
    left: 0;
    bottom: 0;
    right: 0;

    /* mask: linear-gradient(to right, transparent 0%, white 15%); */

    scrollbar-width: none; /* Firefox */
    ::-webkit-scrollbar {
      display: none;
    }

    overflow: scroll;

    display: flex;
    flex-flow: row-reverse nowrap;
    align-items: center;

    line-height: 100%;
  }
`

const AmountTypography = styled(Typography)`
  pointer-events: none;
  height: auto;
  display: flex;
  align-items: center;

  padding-left: 4px;
  /* margin-left: auto; */
  min-width: 64px;
  max-width: 135px;
  width: max-content;

  /* Firefox */
  scrollbar-width: none;
  ::-webkit-scrollbar {
    display: none;
  }

  overflow: scroll;

  position: relative;
  ${TokenItemContrastBG} {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    transform: scale(1.1);
    /* z-index: -1; */
  }

  > span {
    margin-left: auto;
    z-index: 1;
  }
`
