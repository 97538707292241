import { createContext, useContext } from 'react'
import { IAirdropPersistenceApi, SwellDaoApi } from './types'
import { Token } from '@/types/tokens'
import { MerkleDrop } from '@/types/merkle'

const MISSING_PROVIDER = Symbol()

export type SwellDaoContext = SwellDaoApi &
  IAirdropPersistenceApi & {
    daoToken: Token
    restakedDaoToken: Token
    merkleDropAirdrop: MerkleDrop
  }

export const SwellDaoContext = createContext<
  SwellDaoContext | typeof MISSING_PROVIDER
>(MISSING_PROVIDER)

export function useSwellDaoApi() {
  const ctx = useContext(SwellDaoContext)
  if (ctx === MISSING_PROVIDER) {
    throw new Error(
      'SwellDaoContext hooks must be wrapped in a <SwellDaoContext.Provider>'
    )
  }
  return ctx
}
