import { useLocalStorageObject } from '@/hooks/useLocalStorage'
import {
  AirdropResult,
  IAirdropPersistenceApi,
  PersistedClaimMappingRaw,
  PersistedUserClaimDataRaw,
} from './types'
import { deserializeAirdropClaim, serializeAirdropClaim } from './util'
import { useSwellWeb3 } from '@/swell-web3/core'
import { BigNumber } from 'ethers'
import { useCallback, useRef, useState } from 'react'

export const PERSISTED_CLAIM_DATA_LS_KEY = 'dao:persisted-claim-mapping'
export function useAirdropPersistenceApi(): IAirdropPersistenceApi {
  const [rawMapping, setRawMapping] = useLocalStorageObject(
    PERSISTED_CLAIM_DATA_LS_KEY,
    {} as PersistedClaimMappingRaw
  )
  const [userToJustClaimed, setUserToJustClaimed] = useState(
    {} as {
      [account: string]: boolean
    }
  )
  const add = useCallback(
    (account: string, data: PersistedUserClaimDataRaw) => {
      setRawMapping((prev) => ({
        ...prev,
        [account]: data,
      }))
      setUserToJustClaimed((prev) => ({
        ...prev,
        [account]: true,
      }))
    },
    [setRawMapping]
  )
  const clear = () => {
    setRawMapping({})
  }

  const { account: currentAccount } = useSwellWeb3()

  const lastPersisted = useRef<number>(0)
  const onClaimDaoToken = useCallback(
    ({
      account,
      airdropResult,
      amountToLock,
    }: {
      account: string
      amountToLock: BigNumber
      airdropResult: AirdropResult
    }) => {
      if (account !== airdropResult.data?.address) {
        return
      }
      const now = Date.now()
      const timeSinceLastPersisted = now - lastPersisted.current
      if (timeSinceLastPersisted < 30_000) {
        return
      }
      const s = serializeAirdropClaim({
        airdropResult,
        amountToLock,
        nowMs: Date.now(),
      })
      add(account, s)
      lastPersisted.current = now
    },
    [add]
  )

  return {
    onClaimDaoToken,
    clearRecentDaoTokenClaim: () => {
      clear()
    },
    persistedClaimForAddress: (address: string) => {
      const data = rawMapping[address]
      if (!data) {
        return { exists: false }
      }

      const justClaimed = userToJustClaimed[address] ?? false

      return { exists: true, data: deserializeAirdropClaim(data), justClaimed }
    },
    persistedClaim: () => {
      if (!currentAccount) {
        return { exists: false }
      }

      const data = rawMapping[currentAccount]
      if (!data) {
        return { exists: false }
      }

      const justClaimed = userToJustClaimed[currentAccount] ?? false

      return { exists: true, data: deserializeAirdropClaim(data), justClaimed }
    },
  }
}
