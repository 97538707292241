import {
  PreparedWavedropClaim,
  WavedropErrors,
} from '@/components/WavedropCard/wavedropCalls'
import { useWeb3Button } from '@/hooks/useWeb3Button'
import { WavedropClaimAirdrop } from '@/state/wavedrops/hooks'
import { Button } from '@/swell-ui/Button'
import { Tooltip } from '@/swell-ui/Tooltip'
import { useSwellWeb3 } from '@/swell-web3/core'
import { CircularProgress } from '@mui/material'
import styled from 'styled-components'

export const StyledButtonThin = styled(Button)`
  width: 100%;
  height: unset;
  color: var(--white-50, #fff);
  /* Body/medium bold */
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 160%; /* 25.6px */
  padding: 8px 16px;
`

export function ConnectWalletButton() {
  const { connect } = useSwellWeb3()
  return (
    <StyledButtonThin onClick={() => connect()}>
      Connect Wallet
    </StyledButtonThin>
  )
}
const TooltipContainer = styled(Tooltip)`
  width: 100%;
`

export function PreviewClaimWavedropButton({ tooltip }: { tooltip?: string }) {
  const btn = <StyledButtonThin disabled>Claim</StyledButtonThin>

  if (tooltip) {
    return <TooltipContainer title={tooltip}>{btn}</TooltipContainer>
  }

  return btn
}

export function ClaimWavedropButton({
  prepared,
  claimWavedrop,
}: {
  prepared: PreparedWavedropClaim
  claimWavedrop: WavedropClaimAirdrop
}) {
  let idleLabel = 'Claim' as any
  if (prepared.error === WavedropErrors.AlreadyClaimed) {
    idleLabel = 'Claimed'
  }

  const btn = useWeb3Button(claimWavedrop, claimWavedrop.call, prepared.args, {
    idleLabel,
    pendingLabel: (
      <ButtonInner>
        <div>
          <ButtonCircularProgress size={24} />
        </div>
        <span>Confirming...</span>
      </ButtonInner>
    ),
    fulfilledLabel: 'Claimed',
  })

  return (
    <StyledButtonThin disabled={btn.disabled} onClick={btn.onClick}>
      {btn.label}
    </StyledButtonThin>
  )
}

const ButtonCircularProgress = styled(CircularProgress)`
  &.MuiCircularProgress-colorPrimary {
    color: currentColor;
  }
`
const ButtonInner = styled.span`
  position: relative;
  > div {
    position: absolute;
    left: -32px;
  }
`
