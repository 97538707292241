import {
  YearnPrimaryRates,
  YearnWithdrawRequest,
} from '@/state/yearnVault/types'
import { YearnAeraVault, YearnWithdrawAsset } from '@/types/yearnAera'
import {
  YearnDepositSummary,
  YearnWithdrawClaimSummary,
  YearnWithdrawPendingSummary,
  YearnWithdrawRequestSummary,
} from './types'
import {
  displayPercentFromBasisPoints,
  displayPercentFromPercent,
} from '@/util/displayNumbers'
import { parseUnits } from 'ethers/lib/utils'
import { displayCryptoLocale } from '@/util/displayCrypto'
import {
  assetsReceivedFromWithdrawal,
  depositAssetRequiredForVaultToken,
  yearnDepositAssetValueUsd,
} from './yearnConversions'
import { BigNumber } from 'ethers'
import { displayFiat } from '@/util/displayFiat'
import { Token } from '@/types/tokens'
import { AprResult } from '@/types/apr'

export function formatYearnWithdrawDelay(withdrawDelayUnix: number) {
  if (withdrawDelayUnix === 0) {
    return 'None'
  }

  const oneHour = 60 * 60
  const oneDay = oneHour * 24
  const hoursThreshold = oneHour
  const daysThreshold = oneDay

  if (withdrawDelayUnix >= daysThreshold) {
    const days = Math.round(withdrawDelayUnix / oneDay)
    if (days === 1) {
      return `${days} day`
    }
    return `${days} days`
  }

  if (withdrawDelayUnix >= hoursThreshold) {
    const hours = Math.round(withdrawDelayUnix / oneHour)
    if (hours === 1) {
      return `${hours} hour`
    }
    if (hours === 24) {
      return '1 day'
    }

    return `${hours} hours`
  }

  const seconds = withdrawDelayUnix
  if (seconds === 1) {
    return `${seconds} second`
  }
  return `${seconds} seconds`
}

export function makeYearnDepositSummary({
  vault,
  primaryRates,
  apr,
  depositAssetAmount,
  depositAssetUsdMarketRate,
}: {
  vault: YearnAeraVault
  primaryRates: YearnPrimaryRates | undefined
  apr: AprResult | undefined
  depositAssetUsdMarketRate: number | undefined
  depositAssetAmount: BigNumber | undefined
}): YearnDepositSummary {
  const summary: YearnDepositSummary = {
    apr: '',
    depositValueUsd: '',
    exchangeRate: '',
  }

  if (apr) {
    if (apr.indicative) {
      if (apr.indicativeAprPercent) {
        summary.apr = displayPercentFromPercent(apr.indicativeAprPercent)
      } else {
        summary.apr = 'TBD'
      }
    } else {
      summary.apr = displayPercentFromPercent(apr.aprPercent)
    }
  }

  if (primaryRates?.pricePerShare) {
    const oneVaultToken = parseUnits('1', vault.vaultToken.decimals)
    const oneVaultTokenDisplay = displayCryptoLocale(
      oneVaultToken,
      vault.vaultToken.decimals,
      { precision: 4 }
    )
    const requiredAssets = depositAssetRequiredForVaultToken({
      pricePerShare: primaryRates.pricePerShare,
      toReceiveVaultToken: oneVaultToken,
      vaultTokenDecimals: vault.vaultToken.decimals,
    })
    const requiredAssetsDisplay = displayCryptoLocale(
      requiredAssets,
      vault.depositAsset.decimals,
      { precision: 4 }
    )
    summary.exchangeRate = `${oneVaultTokenDisplay} ${vault.vaultToken.symbol} = ${requiredAssetsDisplay} ${vault.depositAsset.symbol}`
  }

  if (
    depositAssetAmount &&
    depositAssetUsdMarketRate &&
    depositAssetUsdMarketRate > 0
  ) {
    summary.depositValueUsd = displayFiat(
      yearnDepositAssetValueUsd({
        base: { decimals: vault.depositAsset.decimals },
        depositAssetAmount,
        depositAssetUsdMarketRate: depositAssetUsdMarketRate,
      })
    )
  }

  return summary
}

export function makeYearnWithdrawRequestSummary({
  withdrawAssetAmount,
  withdrawAssetUsdMarketRate,
  withdrawAsset,
}: {
  withdrawAssetUsdMarketRate: number | undefined
  withdrawAssetAmount: BigNumber | undefined
  withdrawAsset: YearnWithdrawAsset | undefined
}): YearnWithdrawRequestSummary {
  let withdrawFee = ''
  let processingTime = ''
  if (withdrawAsset) {
    withdrawFee = displayPercentFromBasisPoints(
      withdrawAsset.withdrawFeeBasisPoints
    )
    if (withdrawAsset.withdrawDelaySeconds) {
      processingTime = formatYearnWithdrawDelay(
        withdrawAsset.withdrawDelaySeconds
      )
      processingTime = `~${processingTime}`
    } else {
      processingTime = 'None'
    }
  }

  let withdrawValueUsd = ''
  let withdrawValueUsdAfterFee = ''
  if (
    withdrawAssetAmount &&
    withdrawAssetUsdMarketRate &&
    withdrawAsset &&
    withdrawAssetUsdMarketRate > 0
  ) {
    withdrawValueUsd = displayFiat(
      yearnDepositAssetValueUsd({
        base: { decimals: withdrawAsset.decimals },
        depositAssetAmount: withdrawAssetAmount,
        depositAssetUsdMarketRate: withdrawAssetUsdMarketRate,
      })
    )
    withdrawValueUsdAfterFee = displayFiat(
      yearnDepositAssetValueUsd({
        base: { decimals: withdrawAsset.decimals },
        depositAssetAmount: withdrawAssetAmount,
        depositAssetUsdMarketRate:
          withdrawAssetUsdMarketRate *
          (1 - withdrawAsset.withdrawFeeBasisPoints / 10000),
      })
    )
  }

  return {
    processingTime,
    withdrawFee,
    withdrawValueUsd,
    withdrawValueUsdAfterFee,
  }
}

export function makeYearnWithdrawPendingSummary({
  vaultToken,
  withdrawRequest,
  isBtc,
  withdrawAsset,
  primaryRates,
}: {
  withdrawRequest: YearnWithdrawRequest | undefined
  vaultToken: Token
  withdrawAsset: YearnWithdrawAsset | undefined
  primaryRates: YearnPrimaryRates | undefined
  isBtc?: boolean
}): YearnWithdrawPendingSummary {
  if (!withdrawRequest) {
    return {
      claimableTimeMs: 0,
      receivingAmount: '',
      withdrawingAmount: '',
      withdrawRequestDelayMs: 0,
      processingTime: '',
      withdrawFee: '',
      isCancelable: false,
    }
  }

  const { assetsAtTimeOfRequest, maturityUnix, shares } = withdrawRequest

  let receivingAmountStr = ''
  if (primaryRates && withdrawAsset) {
    const receiveAssets = assetsReceivedFromWithdrawal({
      assetsAtTimeOfRequest,
      shares,
      pricePerShare: primaryRates.pricePerShare,
      withdrawFeeBasisPoints: withdrawAsset.withdrawFeeBasisPoints,
      vaultTokenDecimals: vaultToken.decimals,
    })
    receivingAmountStr = displayCryptoLocale(
      receiveAssets,
      withdrawAsset.decimals,
      { btc: isBtc }
    )
  }
  const withdrawingAmountStr = displayCryptoLocale(
    shares,
    vaultToken.decimals,
    { btc: isBtc }
  )

  const deadlineMs = maturityUnix * 1000

  let withdrawRequestDelayMs = 0
  if (withdrawAsset) {
    withdrawRequestDelayMs = withdrawAsset.withdrawDelaySeconds * 1000
  }

  let processingTime = ''
  if (withdrawAsset) {
    if (withdrawAsset.withdrawDelaySeconds) {
      processingTime = formatYearnWithdrawDelay(
        withdrawAsset.withdrawDelaySeconds
      )
      processingTime = `~${processingTime}`
    } else {
      processingTime = 'None'
    }
  }

  let withdrawFee = ''
  if (withdrawAsset) {
    withdrawFee = displayPercentFromBasisPoints(
      withdrawAsset.withdrawFeeBasisPoints
    )
  }

  return {
    receivingAmount: receivingAmountStr,
    withdrawingAmount: withdrawingAmountStr,
    claimableTimeMs: deadlineMs,
    withdrawRequestDelayMs: withdrawRequestDelayMs,
    processingTime,
    withdrawFee,
    isCancelable: withdrawRequest.version === 'v1',
  }
}

export function makeYearnWithdrawClaimSummary({
  vaultToken,
  withdrawRequest,
  isBtc,
  primaryRates,
  withdrawAsset,
}: {
  withdrawRequest: YearnWithdrawRequest | undefined
  vaultToken: Token
  isBtc?: boolean
  withdrawAsset: YearnWithdrawAsset | undefined
  primaryRates: YearnPrimaryRates | undefined
}): YearnWithdrawClaimSummary {
  if (!withdrawRequest) {
    return {
      receivingAmount: '',
      withdrawingAmount: '',
      windowDeadlineMs: 0,
      hasClaimWindow: false,
    }
  }
  const { assetsAtTimeOfRequest, maturityUnix, shares } = withdrawRequest

  let receivingAmountStr = ''
  if (primaryRates && withdrawAsset) {
    const receiveAssets = assetsReceivedFromWithdrawal({
      assetsAtTimeOfRequest,
      shares,
      pricePerShare: primaryRates.pricePerShare,
      withdrawFeeBasisPoints: withdrawAsset.withdrawFeeBasisPoints,
      vaultTokenDecimals: vaultToken.decimals,
    })
    receivingAmountStr = displayCryptoLocale(
      receiveAssets,
      withdrawAsset.decimals,
      { btc: isBtc }
    )
  }
  const withdrawingAmountStr = displayCryptoLocale(
    shares,
    vaultToken.decimals,
    { btc: isBtc }
  )

  let windowDeadlineMs = 0
  let hasClaimWindow = false
  if (withdrawAsset) {
    if (withdrawAsset.version === 'v1') {
      const windowDeadlineUnix =
        maturityUnix + withdrawAsset.completionWindowSeconds
      windowDeadlineMs = windowDeadlineUnix * 1000
      hasClaimWindow = true
    }
  }

  return {
    receivingAmount: receivingAmountStr,
    withdrawingAmount: withdrawingAmountStr,
    windowDeadlineMs,
    hasClaimWindow,
  }
}
