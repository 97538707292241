import { Token } from '@/types/tokens'
import { displayCryptoLocale } from '@/util/displayCrypto'
import { BigNumber } from 'ethers'
import { merkleClaimable } from '../../util/merkledrop'
import { formatUnits, parseEther } from 'ethers/lib/utils'
import { MerkleClaimedEvent } from '@/types/merkle'
import { SwellDaoClaimAirdrop } from '@/state/dao/hooks'
import { AirdropOffchainState, AirdropResult } from '@/state/dao/types'
import {
  AirdropCompletionSummary,
  AirdropRewardSummary,
  VestingBreakdown,
  VestingClaimSchedule,
  VestingKeyDatesList,
} from './types'
import { displayPercent } from '@/util/displayNumbers'
import { airdropOption1Vesting, airdropOption2Vesting } from '@/util/vesting'
import { ConsiderDepositRswellInL2Summary } from '../Modals/ConsiderDepositRswellInL2Modal'
import { ConsiderRestakingSwellSummary } from '../Modals/ConsiderRestakingSwellModal'

export function makeRewardSummary({
  daoToken,
  airdropResult,
}: {
  daoToken: Token
  airdropResult: AirdropResult
}): AirdropRewardSummary {
  let baseRewardStr: string | undefined
  let loyaltyBonusStr: string | undefined
  let totalClaimableStr: string | undefined

  if (airdropResult && airdropResult.exists) {
    const {
      data: { totalAmount },
      loyaltyAmount,
    } = airdropResult

    const baseReward = totalAmount.sub(loyaltyAmount)

    baseRewardStr = displayCryptoLocale(baseReward, daoToken.decimals, {
      precision: 0,
      roundLarge: true,
    })
    loyaltyBonusStr = displayCryptoLocale(loyaltyAmount, daoToken.decimals, {
      precision: 0,
      roundLarge: true,
    })
    totalClaimableStr = displayCryptoLocale(totalAmount, daoToken.decimals, {
      precision: 0,
      roundLarge: true,
    })
  }

  return {
    baseReward: baseRewardStr ?? '0',
    loyaltyBonus: loyaltyBonusStr ?? '0',
    totalClaimable: totalClaimableStr ?? '0',
  }
}

export function displayVestingClaimableDate(timeUnix: number) {
  const date = new Date(timeUnix * 1000)
  const day = String(date.getUTCDate()).padStart(2, '0')
  const month = String(date.getUTCMonth() + 1).padStart(2, '0') // Months are zero-indexed
  const year = date.getUTCFullYear()

  return `${month}.${day}.${year}`
}

export function displayVestingClaimableDateTime(timeUnix: number) {
  const date = new Date(timeUnix * 1000)
  const day = String(date.getUTCDate()).padStart(2, '0')
  const month = String(date.getUTCMonth() + 1).padStart(2, '0') // Months are zero-indexed
  const year = date.getUTCFullYear()
  const hours = String(date.getUTCHours()).padStart(2, '0')
  const minutes = String(date.getUTCMinutes()).padStart(2, '0')

  return `${month}.${day}.${year} ${hours}:${minutes} UTC`
}

export function displayVestingBreakdown({
  daoToken,
  restakedDaoToken,
  airdropResult,
  airdropOffchainState,
}: {
  daoToken: Token
  restakedDaoToken: Token
  airdropOffchainState: AirdropOffchainState
  airdropResult: AirdropResult
}): VestingBreakdown {
  if (!airdropResult.exists) {
    return {
      initialClaimablePercent: '',
      unlocks: [],
    }
  }

  const { data, vestingTier, selectedOption2 } = airdropResult
  const { totalAmount } = data
  const { optionOneUnlockTimes, optionTwoUnlockTimes } = airdropOffchainState

  if (selectedOption2) {
    const oneQuarter = parseEther('0.25')
    const vestingSize = oneQuarter.mul(totalAmount).div(parseEther('1'))
    const initialClaim = totalAmount.sub(vestingSize.add(vestingSize))

    const initialClaimNum = Number(formatUnits(initialClaim, daoToken.decimals))
    const vestingNum = Number(
      formatUnits(vestingSize, restakedDaoToken.decimals)
    )
    const totalClaimableNum = initialClaimNum + vestingNum + vestingNum
    if (totalClaimableNum === 0) {
      return {
        initialClaimablePercent: '',
        unlocks: [],
      }
    }

    const initialClaimablePercent = displayPercent(
      initialClaimNum / totalClaimableNum
    )
    const firstUnlockPercent = displayPercent(vestingNum / totalClaimableNum)
    const secondUnlockPercent = displayPercent(vestingNum / totalClaimableNum)

    return {
      initialClaimablePercent,
      unlocks: [
        {
          date: displayVestingClaimableDate(airdropOffchainState.tgeUnix),
          percent: initialClaimablePercent,
          amount: `${displayCryptoLocale(initialClaim, daoToken.decimals)} ${
            daoToken.symbol
          }`,
        },
        {
          date: displayVestingClaimableDate(
            optionTwoUnlockTimes.firstUnlockUnix
          ),
          percent: firstUnlockPercent,
          amount: `${displayCryptoLocale(
            vestingSize,
            restakedDaoToken.decimals
          )} ${restakedDaoToken.symbol}`,
        },
        {
          date: displayVestingClaimableDate(
            optionTwoUnlockTimes.secondUnlockUnix
          ),
          percent: secondUnlockPercent,
          amount: `${displayCryptoLocale(
            vestingSize,
            restakedDaoToken.decimals
          )} ${restakedDaoToken.symbol}`,
        },
      ],
    }
  }

  let firstVestingSize: BigNumber
  let secondVestingSize: BigNumber
  let thirdVestingSize: BigNumber

  if (vestingTier === 1) {
    const thirty = parseEther('0.3')
    const twenty = parseEther('0.2')

    const thirtyPercent = thirty.mul(totalAmount).div(parseEther('1'))
    const twentyPercent = twenty.mul(totalAmount).div(parseEther('1'))

    firstVestingSize = thirtyPercent
    secondVestingSize = twentyPercent
    thirdVestingSize = twentyPercent
  } else if (vestingTier === 2) {
    const twentyFive = parseEther('0.25')
    const twenty = parseEther('0.2')

    const twentyFivePercent = twentyFive.mul(totalAmount).div(parseEther('1'))
    const twentyPercent = twenty.mul(totalAmount).div(parseEther('1'))

    firstVestingSize = twentyFivePercent
    secondVestingSize = twentyPercent
    thirdVestingSize = twentyPercent
  } else if (vestingTier === 3) {
    const twenty = parseEther('0.2')

    const twentyPercent = twenty.mul(totalAmount).div(parseEther('1'))

    firstVestingSize = twentyPercent
    secondVestingSize = twentyPercent
    thirdVestingSize = twentyPercent
  } else if (vestingTier === 4) {
    const twenty = parseEther('0.2')
    const fifteen = parseEther('0.15')

    const twentyPercent = twenty.mul(totalAmount).div(parseEther('1'))
    const fifteenPercent = fifteen.mul(totalAmount).div(parseEther('1'))

    firstVestingSize = twentyPercent
    secondVestingSize = twentyPercent
    thirdVestingSize = fifteenPercent
  } else if (vestingTier === 5) {
    const twenty = parseEther('0.2')
    const fifteen = parseEther('0.15')

    const twentyPercent = twenty.mul(totalAmount).div(parseEther('1'))
    const fifteenPercent = fifteen.mul(totalAmount).div(parseEther('1'))

    firstVestingSize = twentyPercent
    secondVestingSize = fifteenPercent
    thirdVestingSize = fifteenPercent
  } else {
    // invalid state
    return {
      initialClaimablePercent: '',
      unlocks: [],
    }
  }

  const initialClaimableAmount = totalAmount
    .sub(firstVestingSize)
    .sub(secondVestingSize)
    .sub(thirdVestingSize)

  const initialClaimableNum = Number(
    formatUnits(initialClaimableAmount, daoToken.decimals)
  )
  const firstVestingNum = Number(
    formatUnits(firstVestingSize, daoToken.decimals)
  )
  const secondVestingNum = Number(
    formatUnits(secondVestingSize, daoToken.decimals)
  )
  const thirdVestingNum = Number(
    formatUnits(thirdVestingSize, daoToken.decimals)
  )

  const totalClaimableNum = Number(formatUnits(totalAmount, daoToken.decimals))

  if (totalClaimableNum === 0) {
    return {
      initialClaimablePercent: '',
      unlocks: [],
    }
  }

  const initialClaimablePercent = displayPercent(
    initialClaimableNum / totalClaimableNum
  )
  const firstVestingPercent = displayPercent(
    firstVestingNum / totalClaimableNum
  )
  const secondVestingPercent = displayPercent(
    secondVestingNum / totalClaimableNum
  )
  const thirdVestingPercent = displayPercent(
    thirdVestingNum / totalClaimableNum
  )

  return {
    initialClaimablePercent,
    unlocks: [
      {
        date: displayVestingClaimableDate(airdropOffchainState.tgeUnix),
        percent: initialClaimablePercent,
        amount: `${displayCryptoLocale(
          initialClaimableAmount,
          daoToken.decimals
        )} ${daoToken.symbol}`,
      },
      {
        date: displayVestingClaimableDate(optionOneUnlockTimes.firstUnlockUnix),
        percent: firstVestingPercent,
        amount: `${displayCryptoLocale(firstVestingSize, daoToken.decimals)} ${
          daoToken.symbol
        }`,
      },
      {
        date: displayVestingClaimableDate(
          optionOneUnlockTimes.secondUnlockUnix
        ),
        percent: secondVestingPercent,
        amount: `${displayCryptoLocale(secondVestingSize, daoToken.decimals)} ${
          daoToken.symbol
        }`,
      },
      {
        date: displayVestingClaimableDate(optionOneUnlockTimes.thirdUnlockUnix),
        percent: thirdVestingPercent,
        amount: `${displayCryptoLocale(thirdVestingSize, daoToken.decimals)} ${
          daoToken.symbol
        }`,
      },
    ],
  }
}

export function makeVestingSchedule({
  airdropResult,
  airdropOffchainState,
  daoToken,
  restakedDaoToken,
  justClaimed,
}: {
  airdropResult: AirdropResult
  airdropOffchainState: AirdropOffchainState
  daoToken: Token
  restakedDaoToken: Token
  justClaimed: boolean
}): VestingClaimSchedule {
  const res: VestingClaimSchedule = {
    selectedOption2: false,
    vesting: [],
    claimableNowNonRestaked: '',
    claimableNowRestaked: '',
    nonRestakedLogoURI: daoToken.logoURI,
    restakedLogoURI: restakedDaoToken.logoURI,
  }

  if (!airdropResult.exists) {
    return res
  }

  const {
    data: { totalAmount, cumulativeAmount },
    vestingTier,
    selectedOption2,
  } = airdropResult
  const { optionOneUnlockTimes, optionTwoUnlockTimes } = airdropOffchainState
  let cumulativeClaimed = airdropResult.cumulativeClaimed
  if (justClaimed) {
    cumulativeClaimed = cumulativeAmount
  }

  if (vestingTier === 0 && !selectedOption2) {
    return res
  }

  const { claimableAmount } = merkleClaimable({
    cumulativeAmount,
    cumulativeClaimed,
    totalAmount,
  })

  let numClaims = airdropResult.claimedEvents.length
  if (justClaimed) {
    numClaims++
  }

  const hasClaimedOneTime = numClaims > 0
  const hasClaimedTwoTimes = numClaims > 1
  const hasClaimedThreeTimes = numClaims > 2
  const hasClaimedFourTimes = numClaims > 3

  let claimableNowNonRestaked: BigNumber
  let claimableNowRestaked: BigNumber
  let initialClaimableAmount: BigNumber
  const vestingSchedule: VestingClaimSchedule['vesting'] = []
  if (selectedOption2) {
    const { firstVestingSize, secondVestingSize } = airdropOption2Vesting({
      totalAmount,
      loyaltyAmount: airdropResult.loyaltyAmount,
    })

    vestingSchedule.push({
      amount: displayCryptoLocale(firstVestingSize, restakedDaoToken.decimals),
      date: displayVestingClaimableDate(optionTwoUnlockTimes.firstUnlockUnix),
      tokenLogoURI: restakedDaoToken.logoURI,
      claimed: false,
    })
    vestingSchedule.push({
      amount: displayCryptoLocale(firstVestingSize, restakedDaoToken.decimals),
      date: displayVestingClaimableDate(optionTwoUnlockTimes.secondUnlockUnix),
      tokenLogoURI: restakedDaoToken.logoURI,
      claimed: false,
    })

    const restakedAmount = firstVestingSize.add(secondVestingSize)
    const nonRestakedAmount = totalAmount.sub(restakedAmount)

    let nonRestakedClaimedSoFar = cumulativeClaimed
    if (nonRestakedClaimedSoFar.gt(nonRestakedAmount)) {
      nonRestakedClaimedSoFar = nonRestakedAmount
    }

    let restakedClaimedSoFar = cumulativeClaimed.sub(nonRestakedAmount)
    if (restakedClaimedSoFar.lt(0)) {
      restakedClaimedSoFar = BigNumber.from(0)
    }

    let nonRestakedLeftToClaim = nonRestakedAmount.sub(cumulativeClaimed)
    if (nonRestakedLeftToClaim.lt(0)) {
      nonRestakedLeftToClaim = BigNumber.from(0)
    }

    let restakedLeftToClaim = restakedAmount.sub(restakedClaimedSoFar)
    if (restakedLeftToClaim.lt(0)) {
      restakedLeftToClaim = BigNumber.from(0)
    }

    // nonRestaked is claimed first, then restaked
    let remaining = claimableAmount
    if (nonRestakedLeftToClaim.gt(0)) {
      claimableNowNonRestaked = nonRestakedLeftToClaim
      remaining = remaining.sub(nonRestakedLeftToClaim)
    } else {
      claimableNowNonRestaked = BigNumber.from(0)
    }

    if (remaining.gt(0) && restakedLeftToClaim.gt(0)) {
      claimableNowRestaked = restakedLeftToClaim
      if (claimableNowRestaked.gt(remaining)) {
        claimableNowRestaked = remaining
      }
    } else {
      claimableNowRestaked = BigNumber.from(0)
    }

    initialClaimableAmount = nonRestakedAmount
    if (hasClaimedTwoTimes) {
      vestingSchedule[0].claimed = true
    }
    if (hasClaimedThreeTimes) {
      vestingSchedule[1].claimed = true
    }
  } else {
    const { firstVestingSize, secondVestingSize, thirdVestingSize } =
      airdropOption1Vesting({
        totalAmount,
        vestingTier,
        loyaltyAmount: airdropResult.loyaltyAmount,
      })

    vestingSchedule.push({
      amount: displayCryptoLocale(firstVestingSize, daoToken.decimals),
      date: displayVestingClaimableDate(optionOneUnlockTimes.firstUnlockUnix),
      tokenLogoURI: daoToken.logoURI,
      claimed: false,
    })
    vestingSchedule.push({
      amount: displayCryptoLocale(secondVestingSize, daoToken.decimals),
      date: displayVestingClaimableDate(optionOneUnlockTimes.secondUnlockUnix),
      tokenLogoURI: daoToken.logoURI,
      claimed: false,
    })
    vestingSchedule.push({
      amount: displayCryptoLocale(thirdVestingSize, daoToken.decimals),
      date: displayVestingClaimableDate(optionOneUnlockTimes.thirdUnlockUnix),
      tokenLogoURI: daoToken.logoURI,
      claimed: false,
    })

    initialClaimableAmount = totalAmount
      .sub(firstVestingSize)
      .sub(secondVestingSize)
      .sub(thirdVestingSize)

    claimableNowRestaked = BigNumber.from(0) // option 1 = no restaking
    claimableNowNonRestaked = claimableAmount

    if (hasClaimedTwoTimes) {
      vestingSchedule[0].claimed = true
    }
    if (hasClaimedThreeTimes) {
      vestingSchedule[1].claimed = true
    }
    if (hasClaimedFourTimes) {
      vestingSchedule[2].claimed = true
    }
  }

  vestingSchedule.unshift({
    amount: displayCryptoLocale(initialClaimableAmount, daoToken.decimals),
    date: displayVestingClaimableDate(airdropOffchainState.tgeUnix),
    tokenLogoURI: daoToken.logoURI,
    claimed: false,
  })
  if (hasClaimedOneTime) {
    vestingSchedule[0].claimed = true
  }

  res.vesting = vestingSchedule
  res.selectedOption2 = selectedOption2
  res.claimableNowNonRestaked = displayCryptoLocale(
    claimableNowNonRestaked,
    daoToken.decimals,
    { precision: 0, roundLarge: true }
  )
  res.claimableNowRestaked = displayCryptoLocale(
    claimableNowRestaked,
    restakedDaoToken.decimals,
    { precision: 0, roundLarge: true }
  )

  return res
}

// creates summary data to be rendered in the Completion component
export function makeCompletionSummary({
  daoToken,
  restakedDaoToken,
  airdropResult,
  cachedClaimAirdropArguments,
}: {
  daoToken: Token
  restakedDaoToken: Token
  airdropResult: AirdropResult

  // data that the user submitted successfully. Not available if they're revisiting the page.
  cachedClaimAirdropArguments?: Parameters<SwellDaoClaimAirdrop['call']>[0]
}): AirdropCompletionSummary {
  if (!airdropResult.exists) {
    return {
      claimedToWalletAmount: '0',
      totalClaimedAmount: '0',
      totalRestakedAmount: '0',
      totalClaimedTokenSymbols: [daoToken.symbol],
      callToAction: 'restake',
    }
  }

  let claimedToWalletAmount = ''
  let totalRestakedAmount = ''
  let totalClaimedAmount = ''
  let totalClaimedTokenSymbols: string[] = []

  let syntheticLatestEvent: MerkleClaimedEvent | undefined
  if (cachedClaimAirdropArguments) {
    syntheticLatestEvent = {
      cumulativeAmount: cachedClaimAirdropArguments.cumulativeAmount,
      amountLocked: cachedClaimAirdropArguments.amountToLock,
      timestampUnix: Number.MAX_SAFE_INTEGER,
    }
  }

  let latestOnChainEvent: MerkleClaimedEvent | undefined
  {
    let t = 0
    for (const event of airdropResult.claimedEvents) {
      if (event.timestampUnix > t) {
        t = event.timestampUnix
        latestOnChainEvent = event
      }
    }
  }

  const claimedEvents = [...airdropResult.claimedEvents]

  if (latestOnChainEvent && syntheticLatestEvent) {
    if (
      syntheticLatestEvent.cumulativeAmount.gt(
        latestOnChainEvent.cumulativeAmount
      )
    ) {
      claimedEvents.push(syntheticLatestEvent)
    }
  } else if (syntheticLatestEvent) {
    claimedEvents.push(syntheticLatestEvent)
  }

  if (claimedEvents.length === 0) {
    // day one case - no events. Assume they claimed everything to wallet.
    claimedEvents.push({
      amountLocked: BigNumber.from(0),
      cumulativeAmount: airdropResult.data.cumulativeAmount,
      timestampUnix: 0,
    })
  }

  let amountToLockTotal = BigNumber.from(0)
  let cumulativeAmount = BigNumber.from(0)
  for (const event of claimedEvents) {
    amountToLockTotal = amountToLockTotal.add(event.amountLocked)
    if (event.cumulativeAmount.gt(cumulativeAmount)) {
      cumulativeAmount = event.cumulativeAmount
    }
  }

  claimedToWalletAmount = displayCryptoLocale(
    cumulativeAmount.sub(amountToLockTotal),
    daoToken.decimals,
    { precision: 0, roundLarge: true, abbreviateAbove: '100k' }
  )
  totalRestakedAmount = displayCryptoLocale(
    amountToLockTotal,
    restakedDaoToken.decimals,
    { precision: 0, roundLarge: true, abbreviateAbove: '100k' }
  )
  totalClaimedAmount = displayCryptoLocale(
    cumulativeAmount,
    daoToken.decimals,
    { precision: 0, roundLarge: true, abbreviateAbove: '100k' }
  )
  let callToAction: 'l2' | 'restake'
  totalClaimedTokenSymbols = []
  if (amountToLockTotal.eq(0)) {
    totalClaimedTokenSymbols = [daoToken.symbol]
    callToAction = 'restake'
  } else if (cumulativeAmount.eq(amountToLockTotal)) {
    totalClaimedTokenSymbols = [restakedDaoToken.symbol]
    callToAction = 'l2'
  } else {
    totalClaimedTokenSymbols = [daoToken.symbol, restakedDaoToken.symbol]
    callToAction = 'l2'
  }

  return {
    claimedToWalletAmount,
    totalRestakedAmount,
    totalClaimedAmount,
    totalClaimedTokenSymbols,
    callToAction,
  }
}

export function formatVestingKeyDatesList({
  airdropOffchainState,
  daoToken,
  restakedDaoToken,
  vestingTier,
  selectedOption2,
}: {
  daoToken: Token
  restakedDaoToken: Token
  airdropOffchainState: AirdropOffchainState
  vestingTier: number
  selectedOption2: boolean
}): VestingKeyDatesList {
  function formatDate(unix: number) {
    const date = new Date(unix * 1000)
    const d = `${date.toLocaleDateString('en-US', {
      month: 'short',
      day: 'numeric',
      year: 'numeric',
    })}`
    return d.replace(', 2024', '')
  }

  if (selectedOption2) {
    return {
      claimableToday: `50% of your ${daoToken.symbol} is ready to claim!`,
      futureVests: [
        `25% is restaked as ${
          restakedDaoToken.symbol
        } and will be unlocked on ${formatDate(
          airdropOffchainState.optionTwoUnlockTimes.firstUnlockUnix
        )}`,
        `25% is restaked as ${
          restakedDaoToken.symbol
        } and will be unlocked on ${formatDate(
          airdropOffchainState.optionTwoUnlockTimes.secondUnlockUnix
        )}`,
      ],
    }
  }

  let claimableTodaySize: string
  let firstVestingSize: string
  let secondVestingSize: string
  let thirdVestingSize: string

  if (vestingTier === 1) {
    claimableTodaySize = '30%'
    firstVestingSize = '30%'
    secondVestingSize = '20%'
    thirdVestingSize = '20%'
  } else if (vestingTier === 2) {
    claimableTodaySize = '35%'
    firstVestingSize = '25%'
    secondVestingSize = '20%'
    thirdVestingSize = '20%'
  } else if (vestingTier === 3) {
    claimableTodaySize = '40%'
    firstVestingSize = '20%'
    secondVestingSize = '20%'
    thirdVestingSize = '20%'
  } else if (vestingTier === 4) {
    claimableTodaySize = '45%'
    firstVestingSize = '20%'
    secondVestingSize = '20%'
    thirdVestingSize = '15%'
  } else if (vestingTier === 5) {
    claimableTodaySize = '50%'
    firstVestingSize = '20%'
    secondVestingSize = '15%'
    thirdVestingSize = '15%'
  } else {
    return {
      claimableToday: '',
      futureVests: [],
    }
  }

  return {
    claimableToday: `${claimableTodaySize} of your ${daoToken.symbol} is ready to claim today!`,
    futureVests: [
      `${firstVestingSize} will be vested on ${formatDate(
        airdropOffchainState.optionOneUnlockTimes.firstUnlockUnix
      )}`,
      `${secondVestingSize} will be vested on ${formatDate(
        airdropOffchainState.optionOneUnlockTimes.secondUnlockUnix
      )}`,
      `${thirdVestingSize} will be vested on ${formatDate(
        airdropOffchainState.optionOneUnlockTimes.thirdUnlockUnix
      )}`,
    ],
  }
}

export function makeConsiderDepositRswellInL2Modal({
  cachedClaimAirdropArguments,
  restakedDaoToken,
  airdropResult,
  daoToken,
}: {
  cachedClaimAirdropArguments?: Parameters<SwellDaoClaimAirdrop['call']>[0]
  restakedDaoToken: Token
  daoToken: Token
  airdropResult: AirdropResult
}): ConsiderDepositRswellInL2Summary | undefined {
  if (!airdropResult.exists) {
    return undefined
  }

  if (!cachedClaimAirdropArguments) {
    return undefined
  }

  const { totalAmount } = airdropResult.data
  const { amountToLock, cumulativeAmount } = cachedClaimAirdropArguments
  let isWhale = cumulativeAmount.lt(totalAmount)
  if (airdropResult.claimedEvents.length) {
    isWhale = true // only whales have >1 claiming events
  }

  if (!isWhale) {
    return undefined
  }

  if (amountToLock.eq(0)) {
    return undefined
  }

  let cumulativeClaimedOfLastEvent = BigNumber.from(0)
  for (const event of airdropResult.claimedEvents) {
    if (event.cumulativeAmount.gt(cumulativeClaimedOfLastEvent)) {
      cumulativeClaimedOfLastEvent = event.cumulativeAmount
    }
  }

  const amountClaimed = cumulativeAmount.sub(cumulativeClaimedOfLastEvent)

  let symbols = 'SWELL & rSWELL'
  let logos = [daoToken, restakedDaoToken].map((token) => token.logoURI)
  if (amountToLock.eq(amountClaimed)) {
    symbols = restakedDaoToken.symbol
    logos = [restakedDaoToken.logoURI]
  }

  return {
    amountTotal: displayCryptoLocale(amountClaimed, restakedDaoToken.decimals),
    claimedSymbols: symbols,
    claimedLogoURIs: logos,
  }
}

export function makeConsiderRestakingSwellModal({
  daoToken,
  cachedClaimAirdropArguments,
  airdropResult,
}: {
  cachedClaimAirdropArguments?: Parameters<SwellDaoClaimAirdrop['call']>[0]
  daoToken: Token
  airdropResult: AirdropResult
}): ConsiderRestakingSwellSummary | undefined {
  if (!airdropResult.exists) {
    return undefined
  }
  if (!cachedClaimAirdropArguments) {
    return undefined
  }

  const { cumulativeAmount, totalAmount } = airdropResult.data

  let isWhale = cumulativeAmount.lt(totalAmount)
  if (airdropResult.claimedEvents.length) {
    isWhale = true // only whales have >1 claiming events
  }
  if (!isWhale) {
    return undefined
  }

  const amountToLock = cachedClaimAirdropArguments.amountToLock
  if (amountToLock.gt(0)) {
    return undefined
  }

  let cumulativeClaimedOfLastEvent = BigNumber.from(0)
  for (const event of airdropResult.claimedEvents) {
    if (event.cumulativeAmount.gt(cumulativeClaimedOfLastEvent)) {
      cumulativeClaimedOfLastEvent = event.cumulativeAmount
    }
  }

  const amountClaimed = cumulativeAmount.sub(cumulativeClaimedOfLastEvent)

  const symbols: string[] = [daoToken.symbol]
  const logos: string[] = [daoToken.logoURI]
  return {
    amountClaimedTotal: displayCryptoLocale(amountClaimed, daoToken.decimals),
    claimedSymbols: symbols.join(' & '),
    claimedLogoURIs: logos,
  }
}
