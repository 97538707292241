import { useProtobufMutation } from '@/hooks/useProtobufMutation'
import {
  useProtobufQuery,
  useProtobufQueryImmutable,
} from '@/hooks/useProtobufQuery'
import {
  depositManagerStatsAdapter,
  stakesAdapter,
  tokenRatesAdapter,
  transactionNativeAdapter,
  transactionTokenAdapter,
  twitterUserAdapter,
  voyageLeaderboardRecordAdapter,
  voyageUserAdapter,
  tokenListProtobufAdapter,
} from './adapters'
import { createPromiseClient } from '@bufbuild/connect'
import { createConnectTransport } from '@bufbuild/connect-web'
import { useDeploymentSetConfig } from '@/state/deployments/hooks'
import { V3_BACKEND_SERVICES } from '.'
import { useMemo } from 'react'
import {
  OrderBy,
  OrderDirection,
  StakeTransactionType,
} from '@v3-backend/staker_pb'
import useSWR from 'swr'
import axios from 'axios'
import { BigNumber } from 'ethers'
import ms from 'ms.macro'
import useSWRImmutable from 'swr/immutable'

export function useV3BackendClient(baseUrl: string) {
  return useMemo(() => {
    const transport = createConnectTransport({
      baseUrl,
      useHttpGet: true,
    })

    const lifi = createPromiseClient(V3_BACKEND_SERVICES.LifiService, transport)

    const rates = createPromiseClient(
      V3_BACKEND_SERVICES.RatesService,
      transport
    )
    const operator = createPromiseClient(
      V3_BACKEND_SERVICES.OperatorService,
      transport
    )
    const preDeposit = createPromiseClient(
      V3_BACKEND_SERVICES.PreDepositService,
      transport
    )
    const staker = createPromiseClient(
      V3_BACKEND_SERVICES.StakerService,
      transport
    )
    const stats = createPromiseClient(
      V3_BACKEND_SERVICES.StatsService,
      transport
    )
    const twitter = createPromiseClient(
      V3_BACKEND_SERVICES.TwitterService,
      transport
    )
    const voyage = createPromiseClient(
      V3_BACKEND_SERVICES.VoyageService,
      transport
    )
    const eigenPoints = createPromiseClient(
      V3_BACKEND_SERVICES.EigenPointsService,
      transport
    )
    const wallet = createPromiseClient(
      V3_BACKEND_SERVICES.WalletService,
      transport
    )
    const paraswap = createPromiseClient(
      V3_BACKEND_SERVICES.ParaswapService,
      transport
    )
    const nucleus = createPromiseClient(
      V3_BACKEND_SERVICES.NucleusService,
      transport
    )
    const nucleusWallet = createPromiseClient(
      V3_BACKEND_SERVICES.NucleusWalletService,
      transport
    )
    const v3BackendClient = {
      lifi,
      rates,
      operator,
      preDeposit,
      staker,
      stats,
      twitter,
      voyage,
      eigenPoints,
      wallet,
      paraswap,
      nucleus,
      nucleusWallet,
    }

    return { v3BackendClient }
  }, [baseUrl])
}

export type V3BackendClient = ReturnType<
  typeof useV3BackendClient
>['v3BackendClient']
export type WalletClient = V3BackendClient['wallet']
export type VoyageClient = V3BackendClient['voyage']

export function useGetAllStatsV3Backend(backendUrl: string) {
  const { v3BackendClient } = useV3BackendClient(backendUrl)
  return useProtobufQueryImmutable(
    {
      service: V3_BACKEND_SERVICES.StatsService,
      method: 'all',
      params: {},
      client: v3BackendClient.stats,
      backendUrl,
    },
    { refreshInterval: ms`300s` }
  )
}

export function useTransactionsTokensV3Backend(
  backendUrl: string,
  address?: string
) {
  const { v3BackendClient } = useV3BackendClient(backendUrl)

  const { data, ...query } = useProtobufQueryImmutable(
    {
      service: V3_BACKEND_SERVICES.OperatorService,
      method: 'transactionsTokens',
      client: v3BackendClient.operator,
      params: { address },
      backendUrl,
    },
    {
      paused: !address,
    }
  )

  return {
    ...query,
    get data() {
      if (!data) return undefined
      const { tokenTransactions } = data
      return {
        tokenTransactions: tokenTransactions.map(transactionTokenAdapter),
      }
    },
  }
}

export function useTransactionsNativeV3Backend(
  backendUrl: string,
  address?: string
) {
  const { v3BackendClient } = useV3BackendClient(backendUrl)

  const { data, ...query } = useProtobufQuery(
    {
      service: V3_BACKEND_SERVICES.OperatorService,
      method: 'transactionsNative',
      client: v3BackendClient.operator,
      params: { address },
      backendUrl,
    },
    {
      paused: !address,
    }
  )

  return {
    ...query,
    get data() {
      if (!data) return undefined
      const { nativeTransactions } = data
      return {
        nativeTransactions: nativeTransactions.map(transactionNativeAdapter),
      }
    },
  }
}

export function useDepositManagerStatsV3Backend(backendUrl: string) {
  const { v3BackendClient } = useV3BackendClient(backendUrl)

  const { data, ...query } = useProtobufQueryImmutable({
    service: V3_BACKEND_SERVICES.OperatorService,
    method: 'depositsManagerStats',
    client: v3BackendClient.operator,
    params: {},
    backendUrl,
  })

  return {
    ...query,
    get data() {
      if (!data) return undefined
      return depositManagerStatsAdapter(data)
    },
  }
}

export function useDepositUserStatsV3Backend(backendUrl: string) {
  const { v3BackendClient } = useV3BackendClient(backendUrl)

  return useProtobufQueryImmutable({
    service: V3_BACKEND_SERVICES.OperatorService,
    method: 'depositsUserStats',
    client: v3BackendClient.operator,
    params: {},
    backendUrl,
  })
}

function useVoyageLeaderboardV3BackendProtobuf(backendUrl: string) {
  const { v3BackendClient } = useV3BackendClient(backendUrl)

  const { data, ...query } = useProtobufQuery({
    service: V3_BACKEND_SERVICES.VoyageService,
    method: 'voyageLeaderboard',
    client: v3BackendClient.voyage,
    params: {},
    backendUrl,
  })

  return {
    ...query,
    get data() {
      if (!data) return undefined
      const { scores } = data
      return {
        scores: scores.map(voyageLeaderboardRecordAdapter),
      }
    },
  }
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
function useVoyageLeaderboardV3BackendGet(): ReturnType<
  typeof useVoyageLeaderboardV3BackendProtobuf
> {
  // LST backend is used for peripheral data
  const { v3BackendLstUrl } = useDeploymentSetConfig()
  const safeV3Url = v3BackendLstUrl.replace(/\/$/, v3BackendLstUrl)

  return useSWR(
    'voyage-leaderboard',
    async () => {
      const data = await axios
        .get(
          `${safeV3Url}/swell.v3.VoyageService/v3BackendLstUrlconnect=v1&encoding=json&message={}`
        )
        .then((res) => {
          return res.data
        })

      const { scores } = data
      return { scores: scores.map(voyageLeaderboardRecordAdapter) } as any
    },
    {
      refreshInterval: 30_000,
    }
  )
}

// export { useVoyageLeaderboardV3BackendProtobuf as useVoyageLeaderboardV3Backend }
export { useVoyageLeaderboardV3BackendGet as useVoyageLeaderboardV3Backend }

export function useVoyageUserV3Backend(address?: string) {
  // LST backend is used for peripheral data
  const { v3BackendLstUrl } = useDeploymentSetConfig()
  const { v3BackendClient } = useV3BackendClient(v3BackendLstUrl)

  const { data, ...query } = useProtobufQuery(
    {
      service: V3_BACKEND_SERVICES.VoyageService,
      method: 'voyageUser',
      client: v3BackendClient.voyage,
      params: { address },
      backendUrl: v3BackendLstUrl,
    },
    {
      paused: !address,
    }
  )

  return {
    ...query,
    get data() {
      if (!data) return undefined
      return voyageUserAdapter(data)
    },
  }
}

export interface StakesV3BackendParams {
  first?: number
  skip?: number
  orderBy?: OrderBy
  orderDirection?: OrderDirection
  address?: string
  paused?: boolean
  stakeTransactionTypes: StakeTransactionType[]
}

export function useStakesV3Backend({
  first = 10,
  skip = 0,
  orderBy = OrderBy.TIMESTAMP,
  orderDirection = OrderDirection.DESC,
  address,
  paused = false,
  stakeTransactionTypes,
}: StakesV3BackendParams) {
  // LST backend is used for peripheral data
  const { v3BackendLstUrl } = useDeploymentSetConfig()
  const { v3BackendClient } = useV3BackendClient(v3BackendLstUrl)

  const { data, ...query } = useProtobufQueryImmutable(
    {
      service: V3_BACKEND_SERVICES.StakerService,
      method: 'stakes',
      client: v3BackendClient.staker,
      backendUrl: v3BackendLstUrl,
      params: {
        address,
        first,
        skip,
        orderBy,
        orderDirection,
        stakeTransactionTypes,
      },
    },
    {
      paused,
    }
  )

  return {
    ...query,
    get data() {
      if (!data) {
        return undefined
      }
      const { stakes, count } = data

      return {
        stakes: stakes.map(stakesAdapter),
        count,
      }
    },
  }
}

export function useTwitterUserV3Backend(address?: string) {
  // LRT backend is used for peripheral mutations
  const { v3BackendLrtUrl } = useDeploymentSetConfig()
  const { v3BackendClient } = useV3BackendClient(v3BackendLrtUrl)

  const { data, ...query } = useProtobufQueryImmutable(
    {
      service: V3_BACKEND_SERVICES.TwitterService,
      method: 'twitterUser',
      client: v3BackendClient.twitter,
      params: { walletAddress: address },
      backendUrl: v3BackendLrtUrl,
    },
    {
      paused: !address,
    }
  )

  return {
    ...query,
    get data() {
      if (!data) return undefined

      return {
        user: twitterUserAdapter(data),
      }
    },
  }
}

export function useTwitterVerifyV3Backend() {
  // LRT backend is used for peripheral mutations
  const { v3BackendLrtUrl } = useDeploymentSetConfig()
  const { v3BackendClient } = useV3BackendClient(v3BackendLrtUrl)

  const mutation = useProtobufMutation(v3BackendLrtUrl, {
    service: V3_BACKEND_SERVICES.TwitterService,
    method: 'twitterVerify',
    client: v3BackendClient.twitter,
  })

  return mutation
}

export function useGetSwethAprV3Backend() {
  // LST backend for sourcing swETH information
  const { v3BackendLstUrl } = useDeploymentSetConfig()
  const safeV3Url = v3BackendLstUrl.replace(/\/$/, '')

  return useSWRImmutable(
    'sweth-apr',
    async () => {
      return axios
        .get<number>(`${safeV3Url}/api/tokens/sweth/apr`)
        .then((res) => res.data)
    },
    { refreshInterval: ms`300s` }
  )
}

export function useGetRswethAprV3Backend() {
  // LRT backend for sourcing rswETH information
  const { v3BackendLrtUrl } = useDeploymentSetConfig()
  const safeV3Url = v3BackendLrtUrl.replace(/\/$/, '')

  return useSWRImmutable(
    'rsweth-apr',
    async () => {
      return axios
        .get<number>(`${safeV3Url}/api/tokens/rsweth/apr`)
        .then((res) => res.data)
    },
    {
      refreshInterval: ms`300s`,
    }
  )
}

export function useGetPreDepositStatsV3Backend() {
  const { v3BackendLstUrl } = useDeploymentSetConfig()
  const { v3BackendClient } = useV3BackendClient(v3BackendLstUrl)
  const { data, ...query } = useProtobufQueryImmutable({
    service: V3_BACKEND_SERVICES.PreDepositService,
    method: 'stats',
    params: {},
    client: v3BackendClient.preDeposit,
    backendUrl: v3BackendLstUrl,
  })
  return {
    ...query,
    get data() {
      if (!data) return undefined
      const tokenStats: Record<
        string,
        {
          totalStaked: BigNumber
        }
      > = {}
      for (const token of data.tokenStats) {
        tokenStats[token.tokenAddress] = {
          totalStaked: BigNumber.from(token.totalStaked),
        }
      }

      return {
        tokenStats,
      }
    },
  }
}

export function usePreDepositTokenListV3Backend() {
  const { v3BackendLstUrl } = useDeploymentSetConfig()
  const { v3BackendClient } = useV3BackendClient(v3BackendLstUrl)

  const { data, ...query } = useProtobufQueryImmutable({
    service: V3_BACKEND_SERVICES.PreDepositService,
    method: 'tokenList',
    params: {},
    client: v3BackendClient.preDeposit,
    backendUrl: v3BackendLstUrl,
  })

  return {
    ...query,
    get data() {
      if (!data?.tokenList) return undefined
      return {
        tokenList: tokenListProtobufAdapter(data.tokenList),
      }
    },
  }
}

export function usePreDepositTokenSupportedStatesV3Backend() {
  // const { v3BackendLstUrl } = useDeploymentSetConfig()
  // const { v3BackendClient } = useV3BackendClient(v3BackendLstUrl)

  // const { data, ...query } = useProtobufQueryImmutable({
  //   service: V3_BACKEND_SERVICES.PreDepositService,
  //   method: 'tokenList',
  //   params: {},
  //   client: v3BackendClient.preDeposit,
  //   backendUrl: v3BackendLstUrl,
  // })

  return {
    // ...query,
    get data() {
      // if (!data) return undefined
      const tokenSupported: Record<
        string,
        { depositSupported: boolean; withdrawSupported: boolean }
      > = {}

      const resp = {
        tokenSupported: [
          {
            tokenAddress: '0x9Ba021B0a9b958B5E75cE9f6dff97C7eE52cb3E6',
            depositSupported: true,
            withdrawSupported: true,
          },
          {
            tokenAddress: '0x6ee2b5E19ECBa773a352E5B21415Dc419A700d1d',
            depositSupported: true,
            withdrawSupported: true,
          },
          {
            tokenAddress: '0x18f313Fc6Afc9b5FD6f0908c1b3D476E3feA1DD9',
            depositSupported: true,
            withdrawSupported: true,
          },
          {
            tokenAddress: '0x8a053350ca5F9352a16deD26ab333e2D251DAd7c',
            depositSupported: true,
            withdrawSupported: true,
          },
          {
            tokenAddress: '0xf7906F274c174A52d444175729E3fa98f9bde285',
            depositSupported: true,
            withdrawSupported: true,
          },
          {
            tokenAddress: '0x08c6F91e2B681FaF5e17227F2a44C307b3C1364C',
            depositSupported: true,
            withdrawSupported: true,
          },
          {
            tokenAddress: '0xA663B02CF0a4b149d2aD41910CB81e23e1c41c32',
            depositSupported: true,
            withdrawSupported: true,
          },
          {
            tokenAddress: '0xCd5fE23C85820F7B72D0926FC9b05b43E359b7ee',
            depositSupported: true,
            withdrawSupported: true,
          },
          {
            tokenAddress: '0x5fD13359Ba15A84B76f7F87568309040176167cd',
            depositSupported: true,
            withdrawSupported: true,
          },
          {
            tokenAddress: '0x9468b647b36A23af2E48DaB81a178596a6aF95D9',
            depositSupported: true,
            withdrawSupported: true,
          },
          {
            tokenAddress: '0x5198CB44D7B2E993ebDDa9cAd3b9a0eAa32769D2',
            depositSupported: true,
            withdrawSupported: true,
          },
          {
            tokenAddress: '0xdBD8a24E780E7661c5c9f66310A3D4Ad309cD763',
            depositSupported: true,
            withdrawSupported: true,
          },
          {
            tokenAddress: '0x422F5acCC812C396600010f224b320a743695f85',
            depositSupported: true,
            withdrawSupported: true,
          },
          {
            tokenAddress: '0x5cb12D56F5346a016DBBA8CA90635d82e6D1bcEa',
            depositSupported: true,
            withdrawSupported: true,
          },
          {
            tokenAddress: '0x853d955aCEf822Db058eb8505911ED77F175b99e',
            depositSupported: true,
            withdrawSupported: true,
          },
          {
            tokenAddress: '0xBd564BFE6F2366336ecD707862EDa79b97BCE383',
            depositSupported: true,
            withdrawSupported: true,
          },
          {
            tokenAddress: '0x358d94b5b2F147D741088803d932Acb566acB7B6',
            depositSupported: true,
            withdrawSupported: true,
          },
          {
            tokenAddress: '0x03Bf48b8A1B37FBeAd1EcAbcF15B98B924ffA5AC',
            depositSupported: true,
            withdrawSupported: true,
          },
          {
            tokenAddress: '0xC329400492c6ff2438472D4651Ad17389fCb843a',
            depositSupported: true,
            withdrawSupported: true,
          },
          {
            tokenAddress: '0x6c9f097e044506712B58EAC670c9a5fd4BCceF13',
            depositSupported: true,
            withdrawSupported: true,
          },
          {
            tokenAddress: '0x7122985656e38BDC0302Db86685bb972b145bD3C',
            depositSupported: true,
            withdrawSupported: true,
          },
          {
            tokenAddress: '0x38B86004842D3FA4596f0b7A0b53DE90745Ab654',
            depositSupported: true,
            withdrawSupported: true,
          },
          {
            tokenAddress: '0x2B91426E6F2A88B2C4Afc6c279fA8842A23EBD7F',
            depositSupported: true,
            withdrawSupported: true,
          },
          {
            tokenAddress: '0xE46a5E19B19711332e33F33c2DB3eA143e86Bc10',
            depositSupported: true,
            withdrawSupported: true,
          },
          {
            tokenAddress: '0x4CA48e7Dd3970F96EE74D6A573Ca5678EA1EB31b',
            depositSupported: true,
            withdrawSupported: true,
          },
          {
            tokenAddress: '0x5E9e4bFd81c8c03F8289b827C9B92332789b6fe1',
            depositSupported: true,
            withdrawSupported: true,
          },
          {
            tokenAddress: '0x7f39C581F595B53c5cb19bD0b3f8dA6c935E2Ca0',
            depositSupported: true,
            withdrawSupported: true,
          },
          {
            tokenAddress: '0xcAe44C93f7B3b519Fc28f9d4F7Ae22dE770a907b',
            depositSupported: true,
            withdrawSupported: true,
          },
          {
            tokenAddress: '0x475D3Eb031d250070B63Fa145F0fCFC5D97c304a',
            depositSupported: true,
            withdrawSupported: true,
          },
          {
            tokenAddress: '0xAE5099C39f023C91d3dd55244CAFB36225B0850E',
            depositSupported: true,
            withdrawSupported: true,
          },
          {
            tokenAddress: '0xbf5495Efe5DB9ce00f80364C8B423567e58d2110',
            depositSupported: true,
            withdrawSupported: true,
          },
          {
            tokenAddress: '0xd351de53277c4218CC29f793263FB60E2fcFC1dC',
            depositSupported: true,
            withdrawSupported: true,
          },
          {
            tokenAddress: '0xd4e75971eAF78a8d93D96df530f1FFf5f9F53288',
            depositSupported: true,
            withdrawSupported: true,
          },
          {
            tokenAddress: '0xC02aaA39b223FE8D0A0e5C4F27eAD9083C756Cc2',
            depositSupported: true,
            withdrawSupported: true,
          },
          {
            tokenAddress: '0xf951E335afb289353dc249e82926178EaC7DEd78',
            depositSupported: true,
            withdrawSupported: true,
          },
          {
            tokenAddress: '0xC58aa33Ce619c7f7Ac4929DF357D0Ef762edbD23',
            depositSupported: true,
            withdrawSupported: true,
          },
          {
            tokenAddress: '0xe6cD0b7800cA3e297b8fBd7697Df9E9F6A27f0F5',
            depositSupported: true,
            withdrawSupported: true,
          },
          {
            tokenAddress: '0x4c9EDD5852cd905f086C759E8383e09bff1E68B3',
            depositSupported: true,
            withdrawSupported: true,
          },
          {
            tokenAddress: '0xE9A12fB15cC00b59867E4E2f0aCbdCebfd32b3d7',
            depositSupported: true,
            withdrawSupported: true,
          },
          {
            tokenAddress: '0x2ED065f447971768c1117E4A36C80F4a54eb88FA',
            depositSupported: true,
            withdrawSupported: true,
          },
          {
            tokenAddress: '0x8DB2350D78aBc13f5673A411D4700BCF87864dDE',
            depositSupported: true,
            withdrawSupported: true,
          },
          {
            tokenAddress: '0x83F20F44975D03b1b09e64809B757c47f942BEeA',
            depositSupported: true,
            withdrawSupported: true,
          },
          {
            tokenAddress: '0x1c085195437738d73d75DC64bC5A3E098b7f93b1',
            depositSupported: true,
            withdrawSupported: true,
          },
          {
            tokenAddress: '0x248241244356D18f63d0c16082996839ecB0C7bF',
            depositSupported: true,
            withdrawSupported: true,
          },
          {
            tokenAddress: '0x32bd822d615A3658A68b6fDD30c2fcb2C996D678',
            depositSupported: true,
            withdrawSupported: true,
          },
          {
            tokenAddress: '0x330c4DD05dB16db6323505262C57CFdE5f0A78Ea',
            depositSupported: true,
            withdrawSupported: true,
          },
          {
            tokenAddress: '0xc69Ad9baB1dEE23F4605a82b3354F8E40d1E5966',
            depositSupported: true,
            withdrawSupported: true,
          },
          {
            tokenAddress: '0xac3E018457B222d93114458476f3E3416Abbe38F',
            depositSupported: true,
            withdrawSupported: true,
          },
          {
            tokenAddress: '0xa0021EF8970104c2d008F38D92f115ad56a9B8e1',
            depositSupported: true,
            withdrawSupported: true,
          },
          {
            tokenAddress: '0x49446A0874197839D15395B908328a74ccc96Bc0',
            depositSupported: true,
            withdrawSupported: true,
          },
          {
            tokenAddress: '0x8457CA5040ad67fdebbCC8EdCE889A335Bc0fbFB',
            depositSupported: true,
            withdrawSupported: true,
          },
          {
            tokenAddress: '0x04C154b66CB340F3Ae24111CC767e0184Ed00Cc6',
            depositSupported: true,
            withdrawSupported: true,
          },
          {
            tokenAddress: '0x791c1E9D38029092E8aD1E252d0Bb97261809A2c',
            depositSupported: true,
            withdrawSupported: true,
          },
          {
            tokenAddress: '0xFAe103DC9cf190eD75350761e95403b7b8aFa6c0',
            depositSupported: true,
            withdrawSupported: true,
          },
          {
            tokenAddress: '0xBdea8e677F9f7C294A4556005c640Ee505bE6925',
            depositSupported: true,
            withdrawSupported: true,
          },
          {
            tokenAddress: '0xac440d0eE23FA69632Aa731322C2a480dD1376e4',
            depositSupported: true,
            withdrawSupported: true,
          },
          {
            tokenAddress: '0xB26ff591F44b04E78de18f43B46f8b70C6676984',
            depositSupported: true,
            withdrawSupported: true,
          },
          {
            tokenAddress: '0x572975FF6d5136c81c8d7448B6361eF9EEfE1AB0',
            depositSupported: true,
            withdrawSupported: true,
          },
          {
            tokenAddress: '0x997949eEA781c04E4801d9c0902540236A317B07',
            depositSupported: true,
            withdrawSupported: true,
          },
          {
            tokenAddress: '0x5E8422345238F34275888049021821E8E08CAa1f',
            depositSupported: true,
            withdrawSupported: true,
          },
          {
            tokenAddress: '0xF0574d8B9Dc3a9DE768eaa7DBB7bB0C68521b148',
            depositSupported: true,
            withdrawSupported: true,
          },
        ],
      }

      for (const token of resp.tokenSupported) {
        tokenSupported[token.tokenAddress] = {
          depositSupported: token.depositSupported,
          withdrawSupported: token.withdrawSupported,
        }
      }

      return {
        tokenSupported,
      }
    },
  }
}

export function usePreDepositVoyageUserV3Backend(address?: string) {
  const { v3BackendLstUrl } = useDeploymentSetConfig()
  const { v3BackendClient } = useV3BackendClient(v3BackendLstUrl)

  const { data, ...query } = useProtobufQueryImmutable(
    {
      service: V3_BACKEND_SERVICES.PreDepositService,
      method: 'voyageUser',
      params: { address },
      client: v3BackendClient.preDeposit,
      backendUrl: v3BackendLstUrl,
    },
    {
      paused: !address,
    }
  )

  return {
    ...query,
    get data() {
      if (!data) return undefined

      return {
        user: data,
      }
    },
  }
}

export function useGetPreDepositRatesUsdV3Backend() {
  const { v3BackendLstUrl } = useDeploymentSetConfig()
  const { v3BackendClient } = useV3BackendClient(v3BackendLstUrl)

  const { data, ...query } = useProtobufQueryImmutable({
    service: V3_BACKEND_SERVICES.PreDepositService,
    method: 'ratesUsd',
    params: {},
    client: v3BackendClient.preDeposit,
    backendUrl: v3BackendLstUrl,
  })

  return {
    ...query,
    get data() {
      if (!data) return undefined

      return {
        rates: tokenRatesAdapter(data),
      }
    },
  }
}
