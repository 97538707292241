import { FlexRow } from '@/swell-ui/FlexRow'
import React, { ReactNode } from 'react'
import styled, { css, keyframes } from 'styled-components'
import { title1CSS } from '../styles/common'
import swellWhite from '@/assets/images/swell_white.png'
import { DecoBox } from '@/components/DecoBox'
import d14nVis from '@/assets/images/airdrop/d14n-vis.png'
import { ColoredHeadingTypography } from '@/swell-ui/Typography/typographyPresets'
import { TOKEN_LIST_RSWELL, TOKEN_LIST_SWELL } from '@/constants/tokens'
import swellL2Logo from '@/assets/images/swell-l2-logo-56x56.png'
import eigenLogo from '@/assets/images/eigen-small-graphic_54x54.png'
import symbioticLogo from '@/assets/images/symbiotic-small-graphic_54x54.png'

const bodyCSS = css`
  color: var(--Swell-White-50, #fff);
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 160%; /* 22.4px */
  letter-spacing: -0.42px;
`
const uppercaseMediumCSS = css`
  color: var(--Swell-White-50, #fff);
  /* Body/uppercase medium */
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 160%; /* 22.4px */
  letter-spacing: 1.05px;
  text-transform: uppercase;
`
const bodyMedBoldCSS = css`
  color: var(--Swell-White-50, #fff);
  /* Body/medium bold */
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 160%; /* 25.6px */
`
const bodySmallCSS = css`
  color: var(--Swell-White-125, #b0b0b0);
  /* Body/small */
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 160%; /* 22.4px */
  letter-spacing: -0.42px;
`
const aCSS = css`
  color: var(--Swell-Lightblue-50, #a4abf1);
  text-align: center;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 160%; /* 22.4px */
  letter-spacing: -0.42px;
  text-decoration-line: underline;
`

// redundant... (need # links?)
const LEARN_MORE_RESTAKED_SWELL = 'http://www.swellnetwork.io/post/swell-token'
const LEARN_MORE_GOVERNANCE = 'http://www.swellnetwork.io/post/swell-token'

// https://www.figma.com/design/1trLPK52KCe6Nko8e2NCbU/Swell-Network-Design?node-id=10648-3733&node-type=text&m=dev
export function AirdropNewSwellEra() {
  return (
    <Layout direction="column" gap="24" align="center" maxWidth="1536px">
      <h1>A New SWELL Era</h1>
      <p className="sm-narrow">
        The SWELL token will be integral to the future of the SWELL DAO and
        Swell L2.
      </p>
      <div className="spacer-md" />
      <Grid>
        <div className="a-how-it-works">
          <HowItWorks />
        </div>
        <div className="a-d14n-vis">
          <D14NVis />
        </div>
        <div className="a-restaked-swell">
          <RestakedSwell />
        </div>
        <div className="a-governance">
          <Governance />
        </div>
      </Grid>
    </Layout>
  )
}

const fadeIn = keyframes`
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
`

const fadeInCSS = css`
  opacity: 0;
  animation: ${fadeIn} 0.65s ease-out forwards;
  animation-delay: 0.15s;
`
const Layout = styled(FlexRow)`
  ${fadeInCSS}
  margin: auto 0;

  h1 {
    ${title1CSS}
    margin: 0;
    text-align: center;
  }

  .body {
    ${bodyCSS}
  }
  .uppercase-medium {
    ${uppercaseMediumCSS}
  }
  .body-med-bold {
    ${bodyMedBoldCSS}
  }
  .body-small {
    ${bodySmallCSS}
  }

  a {
    ${aCSS}
  }

  h3,
  p {
    margin: 0;
    text-align: center;
  }

  .round {
    border-radius: 50%;
  }

  .row-title {
    display: flex;
    align-items: center;
    gap: 8px;
  }

  .sm-narrow {
    max-width: 520px;

    @media (min-width: 900px) {
      max-width: unset;
    }
  }

  .spacer-md {
    height: 0px;

    @media (min-width: 900px) {
      height: 4px;
    }
  }

  padding: 0 24px;

  .shrink-mob {
    width: auto;
    flex-shrink: 1;
  }
  @media (min-width: 600px) {
    width: 100%;
    flex-shrink: unset;
  }

  @media (max-width: 460px) {
    .vis {
      width: 100%;
      height: auto;
    }
  }
`

const Grid = styled.div`
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  grid-template-rows: repeat(4, auto);
  grid-template-areas:
    'how-it-works'
    'restaked-swell'
    'd14n-vis'
    'governance';
  grid-row-gap: 36px;

  @media (min-width: 900px) {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(2, auto);
    grid-template-areas:
      'how-it-works   d14n-vis'
      'restaked-swell governance';

    grid-column-gap: 12px;
    grid-row-gap: 36px;
  }

  @media (min-width: 1287px) {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(2, auto);
    grid-template-areas:
      'how-it-works   d14n-vis  '
      'restaked-swell governance';

    grid-column-gap: 12px;
    grid-row-gap: 36px;
  }

  .a-how-it-works {
    grid-area: how-it-works;
    align-self: stretch;
    display: flex;
  }
  .a-d14n-vis {
    grid-area: d14n-vis;
    align-self: stretch;
    display: flex;
  }
  .a-restaked-swell {
    grid-area: restaked-swell;
    max-width: 361px;
    justify-self: center;
  }
  .a-governance {
    grid-area: governance;
    max-width: 291px;
    justify-self: center;
  }
`

function HowItWorks() {
  const step1Vis = (
    <FlexRow gap="6" align="center">
      <img src={TOKEN_LIST_SWELL.logoURI} width={27} height={27} />
      <ArrowBoth />
      <img src={TOKEN_LIST_RSWELL.logoURI} width={27} height={27} />
    </FlexRow>
  )
  const step2Vis = (
    <FlexRow gap="6" align="center">
      <FlexRow>
        <img
          src={eigenLogo}
          style={{
            borderRadius: '50%',
            marginRight: '-12px',
            zIndex: 1,
          }}
          width={27}
          height={27}
        />
        <img src={symbioticLogo} width={27} height={27} />
      </FlexRow>
      <ArrowR />
      <img src={TOKEN_LIST_SWELL.logoURI} width={27} height={27} />
    </FlexRow>
  )
  const step3Vis = (
    <FlexRow gap="6" align="center">
      <img src={TOKEN_LIST_RSWELL.logoURI} width={27} height={27} />
      <ArrowL />
      <img src={swellL2Logo} width={27} height={27} />
    </FlexRow>
  )

  return (
    <DecoBox>
      <FlexRow direction="column" gap="24" align="start">
        <h4 className="uppercase-medium">How it works</h4>
        <FlexRow direction="column" gap="24">
          <HowItWorksStep step={1} vis={step1Vis}>
            Restake SWELL for rSWELL to use in DeFi.
          </HowItWorksStep>
          <HowItWorksStep step={2} vis={step2Vis}>
            Swell L2 is secured by restaked SWELL.
          </HowItWorksStep>
          <HowItWorksStep step={3} vis={step3Vis}>
            Swell L2 user activity generates yield back to rSWELL.
          </HowItWorksStep>
        </FlexRow>
      </FlexRow>
    </DecoBox>
  )
}

function HowItWorksStep({
  step,
  vis,
  children,
}: {
  step: number
  vis: ReactNode
  children?: ReactNode
}) {
  return (
    <FlexRow justify="space-between" align="center" gap="12">
      <FlexRow direction="column" gap="4" align="start">
        <ColoredHeadingTypography style={{ fontSize: '12px' }}>
          Step {step}
        </ColoredHeadingTypography>
        <span className="body shrink-mob" style={{ maxWidth: '213px' }}>
          {children}
        </span>
      </FlexRow>
      <div>{vis}</div>
    </FlexRow>
  )
}

function D14NVis() {
  return (
    <DecoBox>
      <FlexRow direction="column" gap="24">
        <h4 className="uppercase-medium row-title">
          <img src={swellWhite} alt="SWELL" width={27} height={27} />
          Swell DAO
        </h4>
        <img src={d14nVis} width="323.3" height="211" className="vis" />
      </FlexRow>
    </DecoBox>
  )
}

function RestakedSwell() {
  return (
    <FlexRow direction="column" gap="12">
      <h3 className="body-med-bold">SWELL as Restaked SWELL (rSWELL)</h3>
      <p className="body-small">
        rSWELL captures yield generated by activity on Swell L2, and can also be
        used in DeFi.
      </p>
      <a
        href={LEARN_MORE_RESTAKED_SWELL}
        target="_blank"
        rel="noopener noreferrer"
      >
        Learn more
      </a>
    </FlexRow>
  )
}

function Governance() {
  return (
    <FlexRow direction="column" gap="12">
      <h3 className="body-med-bold">Governance</h3>
      <p className="body-small">
        SWELL holders can submit and vote on governance proposals.
      </p>
      <a href={LEARN_MORE_GOVERNANCE} target="_blank" rel="noopener noreferrer">
        Learn more
      </a>
    </FlexRow>
  )
}

function ArrowBoth() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="18"
      height="9"
      viewBox="0 0 18 9"
      fill="none"
    >
      <path
        d="M0.646447 4.14645C0.451184 4.34171 0.451184 4.65829 0.646447 4.85355L3.82843 8.03553C4.02369 8.2308 4.34027 8.2308 4.53553 8.03553C4.7308 7.84027 4.7308 7.52369 4.53553 7.32843L1.70711 4.5L4.53553 1.67157C4.7308 1.47631 4.7308 1.15973 4.53553 0.964466C4.34027 0.769204 4.02369 0.769204 3.82843 0.964466L0.646447 4.14645ZM17.3536 4.85355C17.5488 4.65829 17.5488 4.34171 17.3536 4.14645L14.1716 0.964466C13.9763 0.769204 13.6597 0.769204 13.4645 0.964466C13.2692 1.15973 13.2692 1.47631 13.4645 1.67157L16.2929 4.5L13.4645 7.32843C13.2692 7.52369 13.2692 7.84027 13.4645 8.03553C13.6597 8.2308 13.9763 8.2308 14.1716 8.03553L17.3536 4.85355ZM1 5L17 5V4L1 4L1 5Z"
        fill="white"
      />
    </svg>
  )
}

function ArrowR() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="17"
      height="9"
      viewBox="0 0 17 9"
      fill="none"
      style={{ flex: '1 0 auto' }}
    >
      <path
        d="M16.3536 4.85355C16.5488 4.65829 16.5488 4.34171 16.3536 4.14645L13.1716 0.964466C12.9763 0.769204 12.6597 0.769204 12.4645 0.964466C12.2692 1.15973 12.2692 1.47631 12.4645 1.67157L15.2929 4.5L12.4645 7.32843C12.2692 7.52369 12.2692 7.84027 12.4645 8.03553C12.6597 8.2308 12.9763 8.2308 13.1716 8.03553L16.3536 4.85355ZM0 5L16 5V4L0 4L0 5Z"
        fill="white"
      />
    </svg>
  )
}

function ArrowL() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="17"
      height="9"
      viewBox="0 0 17 9"
      fill="none"
    >
      <path
        d="M0.646447 4.14645C0.451184 4.34171 0.451184 4.65829 0.646447 4.85355L3.82843 8.03553C4.02369 8.2308 4.34027 8.2308 4.53553 8.03553C4.7308 7.84027 4.7308 7.52369 4.53553 7.32843L1.70711 4.5L4.53553 1.67157C4.7308 1.47631 4.7308 1.15973 4.53553 0.964466C4.34027 0.769204 4.02369 0.769204 3.82843 0.964466L0.646447 4.14645ZM1 5L17 5V4L1 4V5Z"
        fill="white"
      />
    </svg>
  )
}
