import { Stack } from '@/swell-ui/Stack'
import React, { MouseEvent, useEffect, useRef, useState } from 'react'
import styled, { css, keyframes } from 'styled-components'
import { Typography } from '@/swell-ui/Typography'
import { useLocation } from 'react-router'
import MuiPopover from '@mui/material/Popover'
import { Link } from 'react-router-dom'
import { Portal } from '@mui/material'
import { CaretDownIcon } from '../icons/CaretDownIcon'
import { ROUTES } from '@/constants/routes'

// prevent open unless the users' mouse stays in the nav area for long enough
// prevent close unless the users' mouse leaves the popover for long enough
const DEBOUNCE_MS = 10
const OFFSET_Y = 8

export type NavIcon =
  | string // default circle
  | { shape: 'square'; uri: string }
  | { shape: 'circle'; uri: string }
  | { shape: 'rect-lg'; uri: string } // proportions roughly 160:116

export type NavLeaf = {
  label: string
  to: string
  icons?: NavIcon[]
  isNew: boolean
}

export type NavGroup = {
  title: string
  pathname: string
  leaves: NavLeaf[]
}

const fadeOut = keyframes`
  0% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
`

const fadeIn = keyframes`
  0% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
`
const quickFadeOutCSS = css`
  opacity: 1;
  animation: ${fadeOut} 0.08s ease-out forwards;
  animation-delay: 0;
`
const slowFadeInCSS = css`
  opacity: 0;
  animation: ${fadeIn} 0.2s ease-out forwards;
  animation-delay: 0.08s;
`

const StyledMuiPopover = styled(MuiPopover)<{ closing: boolean }>`
  margin-top: ${OFFSET_Y}px;
  white-space: nowrap;
  .MuiBackdrop-root {
    /* opacity: 0; */
    pointer-events: ${(props) => (props.closing ? 'none' : 'auto')} !important;
    /* background: ${(props) => (props.closing ? 'red' : 'green')}; */
    /* opacity: 0.2; */
  }

  &.MuiPopover-root {
    pointer-events: none;
    > * {
      pointer-events: auto;
    }
  }

  .MuiPopover-paper {
    /* ${({ closing }) => (closing ? quickFadeOutCSS : slowFadeInCSS)} */
    overflow: visible;
    background-color: ${({ theme }) => theme.navigation.dialog.bgColor};
    border: 1px solid ${({ theme }) => theme.navigation.dialog.borderColor};
    border-radius: ${({ theme }) => theme.navigation.dialog.borderRadius};
    box-shadow: none;
    padding: 4px;
  }
`

const NewIndicator = styled.div`
  margin-top: 3px;
  display: flex;
  padding: 2px 6px;
  justify-content: center;
  align-items: center;
  border-radius: ${({ theme }) => theme.navigation.newIndicator.borderRadius};
  background: ${({ theme }) => theme.navigation.newIndicator.bgColor};
  border: ${({ theme }) => theme.navigation.newIndicator.border};
  color: ${({ theme }) => theme.navigation.newIndicator.color};
  pointer-events: none;
  width: 37px;
  height: 20px;

  font-family: Inter;
  font-size: 10px;
  font-style: normal;
  font-weight: 500;
  line-height: 160%; /* 16px */
  letter-spacing: 0.75px;
`

const LinkStack = styled(Stack)``
const PagesTypography = styled(Typography)<{ open: boolean }>`
  ${({ theme }) => theme.navigation.pagesTypography}
  cursor: default;
  white-space: nowrap;

  &[aria-selected='false'] {
    color: ${({ theme, open }) => {
      return open
        ? theme.navigation.pagesOpenColor
        : theme.navigation.pagesUnselectedColor
    }};
  }

  transition: color 0.2s;
`
const StyledLink = styled(Link)`
  min-width: 175px;

  display: flex;
  flex-flow: row nowrap;
  gap: 12px;
  padding: 6px 12px;

  border-radius: ${({ theme }) => theme.navigation.linkHoverBorderRadius};
  :hover {
    background-color: ${({ theme }) => theme.navigation.linkHoverBgColor};
  }

  .icons {
    align-self: center;
    display: flex;
    flex-flow: row nowrap;
    justify-content: start;
    align-items: flex-end;
    img.major {
      width: 22px;
      height: 22px;
      z-index: 2;
    }
    img.minor {
      margin-left: -4px;
      width: 16px;
      height: 16px;
      z-index: 1;
    }
    img.circle {
      border-radius: 50%;
    }
    .rect-lg-holder {
      width: 30.35px;
      height: 22px;
      position: relative;
      margin-right: 4px;
      img.rect-lg {
        width: 30.35px;
        height: 22px;
        position: absolute;
        transform: scale(1.45) translate(-6px);
        transform-origin: left;
      }
    }
  }
`

const LinkTypography = styled(Typography)`
  ${({ theme }) => theme.navigation.linkTypography}
`
const NavStayOpenTrigger = styled.div`
  background: pink;
  opacity: 0;
  position: fixed;
  z-index: 1400;
`
const ChevronDown = styled(CaretDownIcon)`
  width: 12px;
  height: 12px;
  margin-left: 4px;
  stroke: currentColor;
`

export function NavDropdowns({ groups }: { groups: NavGroup[] }) {
  return (
    <LinkStack direction="row" alignItems="center" spacing={3}>
      {groups.map((group) => {
        return <NavDropdown key={group.title} group={group} />
      })}
    </LinkStack>
  )
}

function NavDropdown({ group }: { group: NavGroup }) {
  const { pathname } = useLocation()

  const isSelected = pathname.startsWith(group.pathname)

  const [anchorEl, setAnchorEl] = useState<HTMLDivElement | null>(null)
  const open = Boolean(anchorEl)

  const openTimeout = useRef<NodeJS.Timeout | null>(null)
  const closeTimeout = useRef<NodeJS.Timeout | null>(null)

  const openedTimeMs = useRef<number>(0)
  const mouseEnteredPaperTimeMs = useRef<number>(0)

  const [closing, setClosing] = useState(false)
  const [pageScrollY, setPageScrollY] = useState(0)
  useEffect(() => {
    let done = false
    function animate() {
      if (done) return
      setPageScrollY(window.scrollY)
      requestAnimationFrame(animate)
    }
    animate()
    return () => {
      done = true
    }
  }, [])

  const beginOpen = (event: MouseEvent<HTMLDivElement>) => {
    setClosing(false)
    const target = event.currentTarget
    openTimeout.current = setTimeout(() => {
      setAnchorEl(target)
      openedTimeMs.current = Date.now()
      openTimeout.current = null
    }, DEBOUNCE_MS)
  }

  const cancelOpen = () => {
    // cancel the signal to open if there's time remaining
    if (openTimeout.current) clearTimeout(openTimeout.current)
    openTimeout.current = null
  }

  const cancelClose = () => {
    // cancel the signal to close if there's time remaining
    if (closeTimeout.current) clearTimeout(closeTimeout.current)
    closeTimeout.current = null
    setClosing(false)
  }

  const quickOpen = (event: MouseEvent<HTMLDivElement>) => {
    cancelOpen()
    setAnchorEl(event.currentTarget)
  }

  const quickClose = () => {
    setAnchorEl(null)
    setClosing(true)
  }
  useEffect(() => {
    return () => {
      if (openTimeout.current) clearTimeout(openTimeout.current)
      if (closeTimeout.current) clearTimeout(closeTimeout.current)
    }
  }, [])

  const onMouseEnterPopover = () => {
    cancelClose()
    mouseEnteredPaperTimeMs.current = Date.now()
  }

  const location = useLocation()

  return (
    <>
      <div
        onMouseEnter={beginOpen}
        onMouseLeave={cancelOpen}
        onClick={quickOpen}
        aria-label={group.title}
      >
        <PagesTypography
          key={group.title}
          variant="body"
          size="medium"
          fstyle="bold"
          aria-selected={isSelected}
          open={open}
        >
          {group.title}
          <ChevronDown />
        </PagesTypography>
      </div>

      <StyledMuiPopover
        closing={closing}
        open={open}
        anchorEl={anchorEl}
        onClose={quickClose}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
        BackdropProps={{
          onMouseEnter: quickClose,
        }}
        transitionDuration={{
          enter: 150,
          exit: 150,
          appear: 150,
        }}
        disableScrollLock
      >
        <Stack
          key={group.title}
          direction="column"
          spacing={2}
          padding={'12px'}
        >
          {group.leaves.map((leaf) => {
            return (
              <StyledLink
                to={leaf.to}
                key={leaf.to}
                onClick={() => {
                  quickClose()
                  if (location.pathname === leaf.to) {
                    if (
                      location.pathname.toLowerCase().includes(ROUTES.DaoVoyage)
                    ) {
                      window.location.reload()
                    }
                  }
                }}
              >
                {leaf.icons && (
                  <div className="icons">
                    {leaf.icons.map((icon, idx) => {
                      return <NavIconImage icon={icon} idx={idx} key={idx} />
                    })}
                  </div>
                )}
                <LinkTypography variant="body" size="medium" fstyle="bold">
                  {leaf.label}
                </LinkTypography>
                {leaf.isNew && <NewIndicator>NEW</NewIndicator>}
              </StyledLink>
            )
          })}
        </Stack>
      </StyledMuiPopover>

      <Portal>
        <NavStayOpenTrigger
          onMouseEnter={onMouseEnterPopover}
          style={{
            width: `${anchorEl ? anchorEl.offsetWidth + 24 : 0}px`,
            height: `${anchorEl ? anchorEl.offsetHeight + OFFSET_Y + 12 : 0}px`,
            left: `${anchorEl ? anchorEl.offsetLeft : 0}px`,
            top: `${anchorEl ? anchorEl.offsetTop - pageScrollY : 0}px`,
          }}
        />
      </Portal>
    </>
  )
}

function NavIconImage({ icon, idx }: { icon: NavIcon; idx: number }) {
  let uri = ''
  if (typeof icon === 'string') {
    uri = icon
  } else {
    uri = icon.uri
  }

  if (typeof icon !== 'string' && icon.shape === 'rect-lg') {
    return (
      <div className="rect-lg-holder">
        <img src={uri} className="rect-lg" />
      </div>
    )
  }

  const isMajor = idx === 0
  let className = isMajor ? 'major' : 'minor'
  if (typeof icon === 'string' || icon.shape === 'circle') {
    className += ' circle'
  }

  return <img src={uri} className={className} />
}
