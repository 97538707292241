import React, { lazy, Suspense } from 'react'
import ReactDOM from 'react-dom/client'
import App from './App'
import reportWebVitals from './reportWebVitals'
import TagManager from 'react-gtm-module'
import { BrowserRouter } from 'react-router-dom'
import { Provider } from 'react-redux'
import { LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import { SwellWeb3Provider } from '@swell-web3/core'
import { SwellUiThemeProvider } from '@swell-ui/theme/SwellUiThemeProvider'
import store from '@/state'
import ApplicationUpdater from '@/state/application/updater'
import { BlockNumberProvider } from './hooks/useBlockNumber'
import { SWRConfig } from 'swr'
import './i18n'
import '@reach/dialog/styles.css'
import { ENABLE_DEV_GUI } from './configuration/featureFlags'
import { HOST_ENV, HostEnv } from './configuration/hostEnv'
import { env } from './env'
import WrongChainDetection from './components/WrongChainDetection'
import { GeoFenceContext } from './state/geofence/context'
import { ZapContext } from './state/zap/context'
import { useZapApiParaswap } from './state/zap/paraswap'
import {
  useBtcLrtVaultApiImpl,
  useRswellVaultApiImpl,
} from './state/yearnVault'
import {
  BtcLrtVaultContext,
  RswellVaultContext,
} from './state/yearnVault/context'
import { TOKEN_LIST_EIGEN, TOKEN_LIST_RSWETH } from './constants/tokens'
import { RswETHContext } from './state/rsweth/context'
import { EXIT_ASSET_RSWETH_ETH } from './constants/exits'
import { useRswETHApiImpl } from './state/rsweth/api'
import { usePredepositApiImpl } from './state/predeposit/api'
import { PredepositContext } from './state/predeposit/context'
import {
  useNucleusVaultBtcImpl,
  useNucleusVaultEthImpl,
} from './state/nucleusVault'
import {
  NucleusBtcContext,
  NucleusEthContext,
} from './state/nucleusVault/context'
import { useNucleusApiGlobalImpl } from './state/nucleusGlobal/api'
import {
  NUCLEUS_VAULT_EARN_BTC,
  NUCLEUS_VAULT_EARN_ETH,
} from './constants/nucleus'
import { NucleusGlobalContext } from './state/nucleusGlobal/context'
import {
  MetaCRMAccount,
  MetaCRMTracking,
  MetaCRMTrackingScript,
  MetaCRMWidgetScript,
} from './components/MetaCRM'
import { SwellDaoContext } from './state/dao/context'
import { useSwellDaoApiImpl } from './state/dao'
import { MERKLE_DROP_EIGEN_STAKEDROP_RSWETH_MAINNET } from './constants/merkleDrops'
import { useGeoFenceApiImplV3Backend } from './state/geofence/api'
import { useAutoConnectGnosis } from './hooks/useAutoConnectGnosis'
import { useEarnApiImpl } from './state/earn/api'
import { EarnContext } from './state/earn/context'
import { usePricesApiImpl } from './state/prices/api'
import { PricesContext } from './state/prices/context'
import { useGasApiImpl } from './state/gas/api'
import { GasContext } from './state/gas/context'
import { SentryService } from './services/SentryService'
import { getSentryDSN } from './util/sentry'
import { SentryMetricsTransport } from './services/MetricsService'
import { SentryErrorTransport } from './services/ErrorService'
import { ErrorsContext } from './state/errors/context'
import { MetricsContext } from './state/metrics/context'
import * as Sentry from '@sentry/react'
import { ErrorPage } from './components/GlobalErrorBoundary/ErrorPage'
import { useWeb3ErrorReporting } from './hooks/useWeb3ErrorReporting'
import { useWavedropsApiImpl } from './state/wavedrops/api'
import { WavedropsContext } from './state/wavedrops/context'

const sentry = new SentryService(getSentryDSN())
sentry.init()

const EIGEN_CHECKER_MODE = false

const DevGui = lazy(() => import('./components/DevGui'))

const GTM_ID = env.REACT_APP_GTM_ID

if (GTM_ID) {
  TagManager.initialize({ gtmId: GTM_ID })
} else if (HOST_ENV === HostEnv.PRODUCTION) {
  console.warn(
    'No GTM_ID in production. Google Tag Manager was not initialized.'
  )
}

function Updaters() {
  return (
    <>
      <ApplicationUpdater />
    </>
  )
}

function APIProviders({ children }: { children?: any }) {
  const geoFenceAPI = useGeoFenceApiImplV3Backend()
  const zapApi = useZapApiParaswap()

  const rswETHApi = useRswETHApiImpl({
    exitAssets: [EXIT_ASSET_RSWETH_ETH],
    rswETHToken: TOKEN_LIST_RSWETH,
    eigenStakedropMerkleDrop: MERKLE_DROP_EIGEN_STAKEDROP_RSWETH_MAINNET,
    eigenToken: TOKEN_LIST_EIGEN,
    eigenCheckerMode: EIGEN_CHECKER_MODE,
  })
  const predepositApi = usePredepositApiImpl()
  const btcLrtVaultApiReal = useBtcLrtVaultApiImpl()

  const earnApi = useEarnApiImpl()

  const nucleusApiGlobal = useNucleusApiGlobalImpl({
    vaults: [NUCLEUS_VAULT_EARN_BTC, NUCLEUS_VAULT_EARN_ETH],
  })
  const nucleusApiBtc = useNucleusVaultBtcImpl()
  const nucleusApiEth = useNucleusVaultEthImpl()
  const daoApi = useSwellDaoApiImpl()
  const pricesApi = usePricesApiImpl()
  const gasApi = useGasApiImpl()

  const rswellVaultApi = useRswellVaultApiImpl()

  const wavedropsApi = useWavedropsApiImpl()

  return (
    <GeoFenceContext.Provider value={geoFenceAPI}>
      <GasContext.Provider value={gasApi}>
        <PricesContext.Provider value={pricesApi}>
          <ZapContext.Provider value={zapApi}>
            <RswETHContext.Provider value={rswETHApi}>
              <SwellDaoContext.Provider value={daoApi}>
                <PredepositContext.Provider value={predepositApi}>
                  <BtcLrtVaultContext.Provider value={btcLrtVaultApiReal}>
                    <RswellVaultContext.Provider value={rswellVaultApi}>
                      <NucleusGlobalContext.Provider value={nucleusApiGlobal}>
                        <NucleusBtcContext.Provider value={nucleusApiBtc}>
                          <NucleusEthContext.Provider value={nucleusApiEth}>
                            <EarnContext.Provider value={earnApi}>
                              <WavedropsContext.Provider value={wavedropsApi}>
                                {children}
                              </WavedropsContext.Provider>
                            </EarnContext.Provider>
                          </NucleusEthContext.Provider>
                        </NucleusBtcContext.Provider>
                      </NucleusGlobalContext.Provider>
                    </RswellVaultContext.Provider>
                  </BtcLrtVaultContext.Provider>
                </PredepositContext.Provider>
              </SwellDaoContext.Provider>
            </RswETHContext.Provider>
          </ZapContext.Provider>
        </PricesContext.Provider>
      </GasContext.Provider>
    </GeoFenceContext.Provider>
  )
}

function Scripts() {
  return (
    <>
      <MetaCRMWidgetScript production={HOST_ENV === HostEnv.PRODUCTION} />
      <MetaCRMTrackingScript production={HOST_ENV === HostEnv.PRODUCTION} />
    </>
  )
}

function Effects() {
  useAutoConnectGnosis()
  useWeb3ErrorReporting()
  return null
}

const metrics = new SentryMetricsTransport()
const errors = new SentryErrorTransport()

function Transports({ children }: { children?: any }) {
  return (
    <>
      <ErrorsContext.Provider value={errors}>
        <MetricsContext.Provider value={metrics}>
          {children}
        </MetricsContext.Provider>
      </ErrorsContext.Provider>
    </>
  )
}

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement)
root.render(
  <React.StrictMode>
    <Sentry.ErrorBoundary
      fallback={
        <ErrorPage
          onClickForceReload={() => {
            localStorage.clear()
            window.location.reload()
          }}
        />
      }
    >
      <Scripts />
      <BrowserRouter>
        <Transports>
          <Provider store={store}>
            <SWRConfig>
              <SwellWeb3Provider>
                <MetaCRMTracking />
                <MetaCRMAccount />
                <BlockNumberProvider>
                  <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <APIProviders>
                      <Effects />
                      <SwellUiThemeProvider>
                        <WrongChainDetection />
                        <Updaters />
                        <App />
                        {ENABLE_DEV_GUI && (
                          <Suspense fallback={null}>
                            <DevGui />
                          </Suspense>
                        )}
                      </SwellUiThemeProvider>
                    </APIProviders>
                  </LocalizationProvider>
                </BlockNumberProvider>
              </SwellWeb3Provider>
            </SWRConfig>
          </Provider>
        </Transports>
      </BrowserRouter>
    </Sentry.ErrorBoundary>
  </React.StrictMode>
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
