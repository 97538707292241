import React from 'react'
import styled from 'styled-components/macro'
import { useSwellWeb3 } from '@swell-web3/core'
import { HamburgerIcon } from '@swell-ui/icons/HamburgerIcon'
import { Logo } from '@swell-ui/branding/Logo'
import { Menu } from '@swell-ui/Menu'
import { TopNav } from '@swell-ui/TopNav'
import { useMediaQuery } from '@swell-ui/theme/useMediaQuery'
import { TransakOnRampWidget } from '@/components/TransakOnRampWidget'
import { Web3NavWidget } from '@/components/Web3NavWidget'
import useAddToken from '@/hooks/useAddToken'
import { useSwEthToken, useRswEthToken } from '@/state/deployments/hooks'
import { useDepositSubmissionHistoricState } from '@/state/depositSubmission/hooks'
import { TopNavBarLinkStack } from './TopNavBarLinkStack'
import ethLogoUrl from '@/assets/images/ethereum-logo.png'
import swellL2Logo from '@/assets/images/swell-l2-logo-28x28.png'
import { NavGroup } from '@/swell-ui/NavPopover'
import { NavHamburgerMobile } from '@/swell-ui/NavHamburgerMobile'
import { ROUTES } from '@/constants/routes'
import blackPearlUrl from '@/assets/images/black-pearls-128x128.png'
import {
  TOKEN_LIST_EARN_BTC,
  TOKEN_LIST_EARN_ETH,
  TOKEN_LIST_RSWELL,
  TOKEN_LIST_SWBTC,
} from '@/constants/tokens'
import portfolioPieUrl from '@/assets/images/portfolio_pie.png'

const swethLogo =
  'https://uploads-ssl.webflow.com/63d9b0d075e8161e9281ebdd/65b1bb090e5ae9484a6d6c57_swETH.svg'
const rswethLogo =
  'https://uploads-ssl.webflow.com/63d9b0d075e8161e9281ebdd/65a18d05b8a1d66b3deb64e2_rswETH.svg'

const whitePearlUrl =
  'https://uploads-ssl.webflow.com/63d9b0d075e8161e9281ebdd/66ac75489816f0a5806aaeed_White%20Pearl.svg'

const navGroups: NavGroup[] = [
  {
    title: 'Stake',
    pathname: '/stake',
    leaves: [
      {
        isNew: false,
        label: 'EigenLayer LRT',
        to: ROUTES.StakeRswETH,
        icons: [rswethLogo],
      },
      {
        isNew: false,
        label: 'Ethereum LST',
        to: ROUTES.StakeSwETH,
        icons: [swethLogo],
      },
      {
        isNew: false,
        label: 'Bitcoin LRT',
        to: ROUTES.StakeSwBTC,
        icons: [TOKEN_LIST_SWBTC.logoURI],
      },
      {
        isNew: true,
        label: 'SWELL LRT',
        to: ROUTES.StakeSwell,
        icons: [TOKEN_LIST_RSWELL.logoURI],
      },
    ],
  },
  {
    title: 'Layer 2',
    pathname: '/layer2',
    leaves: [
      {
        isNew: false,
        label: 'Swell L2',
        to: ROUTES.Layer2SwellL2,
        icons: [{ shape: 'square', uri: swellL2Logo }],
      },
    ],
  },
  {
    title: 'Earn',
    pathname: '/earn',
    leaves: [
      {
        isNew: true,
        label: 'Portfolio',
        to: ROUTES.EarnPortfolio,
        icons: [portfolioPieUrl],
      },
      {
        isNew: false,
        label: 'Earn ETH Vault',
        to: ROUTES.EarnETH,
        icons: [TOKEN_LIST_EARN_ETH.logoURI],
      },
      {
        isNew: true,
        label: 'Earn BTC Vault',
        to: ROUTES.EarnBTC,
        icons: [TOKEN_LIST_EARN_BTC.logoURI],
      },
    ],
  },
  {
    title: 'DAO',
    pathname: '/dao',
    leaves: [
      {
        isNew: false,
        label: 'The Voyage',
        to: ROUTES.DaoVoyage,
        icons: [whitePearlUrl],
      },
      {
        isNew: false,
        label: 'Swell City',
        to: ROUTES.DaoSwellCity,
        icons: [blackPearlUrl],
      },
    ],
  },
]

const StyledTopNav = styled(TopNav)`
  justify-content: flex-start;
  top: 0;
  left: 0;
  height: 120px;
  padding-left: 36px;
  padding-right: 36px;
  z-index: 10;
`

const StyledHamburgerIcon = styled(HamburgerIcon)`
  width: 40px;
  height: 40px;

  path {
    fill: ${({ theme }) => theme.mainColor};
  }
`

function TopNavBarMenu() {
  const { account } = useSwellWeb3()
  const swEthToken = useSwEthToken()
  const rswEthToken = useRswEthToken()
  const { addToken, /*canWatchAssets,*/ success } = useAddToken({
    token: swEthToken,
  })
  const { addToken: addRswEthToken, success: addRswEthSuccess } = useAddToken({
    token: rswEthToken,
  })

  const { hasUnviewedHistoricSubmission } = useDepositSubmissionHistoricState()

  const config = {
    notificationDot: hasUnviewedHistoricSubmission,
    label: <StyledHamburgerIcon />,
    items: [
      {
        label: 'Documentation',
        type: 'outsideLink',
        href: 'https://docs.swellnetwork.io',
      },
      {
        label: 'Forum',
        type: 'outsideLink',
        href: 'https://forum.swellnetwork.io',
      },
      {
        label: 'GitHub',
        type: 'outsideLink',
        href: 'https://github.com/SwellNetwork',
      },
      {
        label: success ? 'Added swETH' : 'Add swETH',
        type: 'action',
        onClick: () => {
          addToken()
        },
      },
      {
        label: addRswEthSuccess ? 'Added rswETH' : 'Add rswETH',
        type: 'action',
        onClick: () => {
          addRswEthToken()
        },
      },
      {
        label: <TransakOnRampWidget label="Purchase ETH" account={account} />,
        type: 'action',
      },
    ],
  }

  if (hasUnviewedHistoricSubmission) {
    config.items.push({
      label: 'Previous key submission',
      type: 'insideLink',
      to: '/operators/validator-keys',
    } as any)
  }

  return <Menu config={config} align="middle" />
}

export function TopNavBar({ onlyLogo }: { onlyLogo?: boolean }) {
  const is1120Up = useMediaQuery('(min-width:1120px)')
  const is740Up = useMediaQuery('(min-width:740px)')

  let middleComponents: React.ReactNode[] = []
  let rightComponents: React.ReactNode[] = []
  if (is1120Up) {
    // only render top nav link stack if screen is wide enough
    middleComponents = [<TopNavBarLinkStack navGroups={navGroups} />]
    rightComponents = [<Web3NavWidget />, <TopNavBarMenu />]
  } else if (is740Up) {
    rightComponents = [
      <Web3NavWidget />,
      <NavHamburgerMobile groups={navGroups} />,
    ]
  } else {
    rightComponents = [<NavHamburgerMobile groups={navGroups} />]
  }

  return (
    <StyledTopNav
      leftComponent={<Logo />}
      middleComponents={onlyLogo ? [] : middleComponents}
      rightComponents={onlyLogo ? [] : rightComponents}
    />
  )
}
