import { FlexRow } from '@/swell-ui/FlexRow'
import { Menu, MenuConfig } from '@/swell-ui/Menu'
import { Token } from '@/types/tokens'
import { ReactNode } from 'react'
import styled from 'styled-components'

const ICON_SIZE = 22

export function TokenSelectMenu({
  tokens,
  selectedToken,
  setSelectedToken,
  title,
  detailsTitle,
  tokenToDetails,
}: {
  tokens: Token[]
  selectedToken: Token
  setSelectedToken: (token: Token) => void
  title: string
  tokenToDetails?: Record<string, ReactNode>
  detailsTitle?: string
}) {
  let selectedItemLabel: React.ReactNode = null

  let titleNode = title as ReactNode
  if (detailsTitle) {
    titleNode = (
      <FlexRow justify="space-between">
        <span>{title}</span> <span>{detailsTitle}</span>
      </FlexRow>
    )
  }

  const items: MenuConfig['items'] = [{ label: titleNode, type: 'heading' }]
  for (const t of tokens) {
    const details = tokenToDetails?.[t.address]

    const label = (
      <ItemLayout gap="6" width="100%">
        <img src={t.logoURI} width={ICON_SIZE} height={ICON_SIZE} />
        <span>{t.symbol}</span>
        {details && <div style={{ marginLeft: 'auto' }}>{details}</div>}
      </ItemLayout>
    )

    items.push({
      label,
      onClick: () => {
        setSelectedToken(t)
      },
      type: 'button',
      noIndent: true,
    })

    if (t.address === selectedToken.address) {
      // same as label, but without details
      selectedItemLabel = (
        <ItemLayout gap="6" width="100%">
          <img src={t.logoURI} width={ICON_SIZE} height={ICON_SIZE} />
          <span>{t.symbol}</span>
        </ItemLayout>
      )
    }
  }

  return (
    <Layout>
      <SMenu
        config={{ items, label: selectedItemLabel }}
        disableScrollLock
        align="right"
        minWidth="248px"
        caret
      />
    </Layout>
  )
}

const SMenu = styled(Menu)`
  .MuiButtonBase-root {
    width: 100%;
  }
`
const Layout = styled.div``
const ItemLayout = styled(FlexRow)`
  color: var(--white-50, #fff);
  /* Body/medium */
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 160%; /* 25.6px */
  letter-spacing: -0.48px;

  > img {
    border-radius: 50%;
  }
`
