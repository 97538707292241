import {
  INucleusFulfilledRequestsFetcher,
  INucleusPointsFetcher,
  INucleusVaultStatsFetcher,
} from './api'
import {
  NucleusClient,
  NucleusWalletClient,
} from '@/services/V3BackendService/types'
import { BigNumber } from 'ethers'
import { AprResult } from '@/types/apr'
import { NucleusFulfilledRequestEvent } from '@/types/nucleus'
import { parseRangeAPR } from '@/util/apr'
import {
  NucleusServiceFulfilledEventsResponse,
  NucleusServicePointsResponse,
  NucleusServiceVaultStatsResponse,
} from '@/submodules/v3-shared/ts/connect/swell/v3/nucleus_pb'
import { NucleusVaultPoints, NucleusVaultStats } from './types'

export function parseNucleusVaultPoints(
  resp: NucleusServicePointsResponse
): NucleusVaultPoints {
  return {
    blackPearls: resp.points?.blackPearls?.pts ?? 0,
    ecosystemPts: resp.points?.ecosystemPts?.pts ?? 0,
    nucleusPts: resp.points?.nucleusPts?.pts ?? 0,
    blackPearlsMultiplier: resp.points?.blackPearls?.multiplier ?? 1,
    ecosystemPtsMultiplier: resp.points?.ecosystemPts?.multiplier ?? 1,
    nucleusPtsMultiplier: resp.points?.nucleusPts?.multiplier ?? 1,
  }
}

export class EarnETHPointsFetcher implements INucleusPointsFetcher {
  private client: NucleusWalletClient
  constructor(client: NucleusWalletClient) {
    this.client = client
  }
  points: INucleusPointsFetcher['points'] = async ({ account }) => {
    return this.client
      .pointsEarnETH({ walletAddress: account })
      .then(parseNucleusVaultPoints)
  }
}
export class EarnBTCPointsFetcher implements INucleusPointsFetcher {
  private client: NucleusWalletClient
  constructor(client: NucleusWalletClient) {
    this.client = client
  }
  points: INucleusPointsFetcher['points'] = async ({ account }) => {
    return this.client
      .pointsEarnBTC({ walletAddress: account })
      .then(parseNucleusVaultPoints)
  }
}

export function parseFulfilledRequests(
  r: NucleusServiceFulfilledEventsResponse
): NucleusFulfilledRequestEvent[] {
  const events: NucleusFulfilledRequestEvent[] = []
  for (const e of r.events) {
    const offerAmountSpent = BigNumber.from(e.offerAmountSpentWei)
    const offerToken = e.offerToken
    const timestampUnix = Number(e.timestampUnix) // 2038 monka
    const user = e.user
    const wantAmountReceived = BigNumber.from(e.wantAmountReceivedWei)
    const wantToken = e.wantToken

    events.push({
      offerAmountSpent,
      offerToken,
      timestampUnix,
      user,
      wantAmountReceived,
      wantToken,
    })
  }
  return events
}

export class NucleusFulfilledRequestFetcher
  implements INucleusFulfilledRequestsFetcher
{
  private client: NucleusWalletClient
  constructor(client: NucleusWalletClient) {
    this.client = client
  }
  fulfilledRequests: INucleusFulfilledRequestsFetcher['fulfilledRequests'] =
    async ({ account, vaultToken, wantAssets }) => {
      const reqs = await this.client.fulfilledEvents({
        user: account,
        vaultToken,
        wantTokens: wantAssets,
      })

      return parseFulfilledRequests(reqs)
    }
}

export function parseNucleusVaultStats(
  resp: NucleusServiceVaultStatsResponse,
  { indicativeApr }: { indicativeApr: boolean }
): NucleusVaultStats {
  let apr: AprResult
  if (resp.apyPercent.length === 0) {
    apr = {
      indicative: true,
    }
  } else {
    apr = {
      indicative: false,
      aprPercent: resp.apyPercent[0],
    }
  }

  if (indicativeApr) {
    apr = {
      indicative: true,
    }
  }

  return {
    apr,
    performance: resp.performance.map((v) => {
      return {
        timestamp: v.timestamp,
        value: v.apyPercent,
      }
    }),
    tvlUsd: BigNumber.from(resp.tvlCents).toNumber() / 100,
    positions: resp.positions.map((p) => {
      return {
        name: p.name,
        allocationPercent: p.allocationPercent,
        apr: parseRangeAPR(p.apyPercent),
        assetIconURIs: p.assetLogoUris,
        protocolIconURIs: p.protocolLogoUris,
      }
    }),
  }
}

export class NucleusStatsFetcherIndicative
  implements INucleusVaultStatsFetcher
{
  private client: NucleusClient
  constructor(client: NucleusClient) {
    this.client = client
  }
  vaultTokenStats: INucleusVaultStatsFetcher['vaultTokenStats'] = async ({
    vaultToken,
  }) => {
    const stats = await this.client.vaultStats({ vaultToken })
    return parseNucleusVaultStats(stats, { indicativeApr: true })
  }
}

export class NucleusStatsFetcher implements INucleusVaultStatsFetcher {
  private client: NucleusClient
  constructor(client: NucleusClient) {
    this.client = client
  }
  vaultTokenStats: INucleusVaultStatsFetcher['vaultTokenStats'] = async ({
    vaultToken,
  }) => {
    const stats = await this.client.vaultStats({ vaultToken })
    return parseNucleusVaultStats(stats, { indicativeApr: false })
  }
}
