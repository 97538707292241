import { useV3BackendClient } from '@/services/V3BackendService/hooks'
import { useDeploymentSetConfig } from '../deployments/hooks'
import { SwellDaoContext } from './context'
import { useSwellWeb3 } from '@/swell-web3/core'
import { AirdropOffchainState, IDaoApiRead, IDaoApiWrite } from './types'
import {
  useIERC20Contract,
  useMerkleDropContract,
  useMulticallContract,
} from '@/hooks/useContract'
import { Token } from '@/types/tokens'
import { AIRDROP_VESTING_DATES } from '@/constants/airdrops'
import { useAirdropPersistenceApi } from './persistanceApi'
import { fetchAirdropContractsState, fetchAirdropUser } from './fetchers'
import { useMemo } from 'react'
import { StaticAirdropService } from '@/services/StaticAirdrop'
import { useDaoSettings } from '../deployments/hooks/daoSettings'
import { MerkleDrop } from '@/types/merkle'

export function useDaoApiImpl({
  merkleDropAirdrop,
  daoToken,
  restakedDaoToken,
}: {
  daoToken: Token
  restakedDaoToken: Token
  merkleDropAirdrop: MerkleDrop
}): SwellDaoContext {
  const persistenceApi = useAirdropPersistenceApi()
  return {
    ...persistenceApi,
    daoToken,
    restakedDaoToken,
    merkleDropAirdrop,
    read: useDaoApiRead({
      daoToken,
      merkleDropAirdrop,
    }),
    write: useDaoApiWrite({
      merkleDropAirdrop,
    }),
  }
}

async function fetchAirdropOffchainState(): Promise<AirdropOffchainState> {
  return { ...AIRDROP_VESTING_DATES }
}

function useDaoApiRead({
  daoToken,
  merkleDropAirdrop,
}: {
  daoToken: Token
  merkleDropAirdrop: MerkleDrop
}): IDaoApiRead {
  const { staticAirdropUrl, claimedEventsUrl } = useDaoSettings()

  const { account: maybeAccount } = useSwellWeb3()
  const walletClientLRT =
    useV3BackendClient(claimedEventsUrl).v3BackendClient.wallet

  const merkleDropContractAirdrop = useMerkleDropContract(
    merkleDropAirdrop.address
  )!

  const swellTokenContract = useIERC20Contract(daoToken.address)!

  const staticAirdropService = useMemo(
    () => new StaticAirdropService({ baseURL: staticAirdropUrl }),
    [staticAirdropUrl]
  )
  const multicall = useMulticallContract()!

  const account = maybeAccount!
  return {
    balances: async () => {
      return {
        swellBalance: await swellTokenContract.balanceOf(account),
      }
    },
    airdrop: async () => {
      if (!account) {
        throw new Error('No account')
      }

      return fetchAirdropUser({
        account,
        merkleDropAirdrop,
        walletClient: walletClientLRT,
        multicall,
        staticAirdropService,
      })
    },
    airdropContractsState: async () => {
      return fetchAirdropContractsState({
        merkleDropAirdropAddress: merkleDropAirdrop.address,
        multicall,
      })
    },
    airdropOffchainState: async () => {
      return fetchAirdropOffchainState()
    },
    checkClaimAirdrop: async ({ merkleProof, cumulativeAmount }) => {
      return merkleDropContractAirdrop.verifyProof(
        merkleProof,
        cumulativeAmount,
        account
      )
    },
  }
}

function useDaoApiWrite({
  merkleDropAirdrop,
}: {
  merkleDropAirdrop: MerkleDrop
}): IDaoApiWrite {
  const merkleDropAirdropContract = useMerkleDropContract(
    merkleDropAirdrop.address
  )!
  return {
    claimAirdrop: async (
      { amountToLock, cumulativeAmount, merkleProof },
      opts
    ) => {
      return merkleDropAirdropContract.claimAndLock(
        cumulativeAmount,
        amountToLock,
        merkleProof,
        opts
      )
    },
    claimAirdropEstimateGas: async ({
      amountToLock,
      cumulativeAmount,
      merkleProof,
    }) => {
      return merkleDropAirdropContract.estimateGas.claimAndLock(
        cumulativeAmount,
        amountToLock,
        merkleProof
      )
    },
  }
}
