import { AirdropCarousel } from '@/components/AirdropCarousel'
import {
  useAirdropPersistence,
  usePersistedAirdropClaim,
  useRestakedSwellDaoToken,
  useSwellDaoAirdrop,
  useSwellDaoAirdropContractsState,
  useSwellDaoAirdropOffchainState,
  useSwellDaoClaimAirdrop,
  useSwellDaoToken,
} from '@/state/dao/hooks'
import { useWeb3CallErrorNotify } from '@/hooks/useWeb3CallErrorNotify'
import { useCallback, useEffect } from 'react'
import { useSwellWeb3 } from '@/swell-web3/core'

const MIN_PEARLS_THRESHOLD = -1 // view disabled
const PLANKTON_THRESHOLD = 500
const CHECKER_MODE = false

export function AirdropPage() {
  const { onClaimDaoToken } = useAirdropPersistence()
  const persistedClaim = usePersistedAirdropClaim()
  const { account } = useSwellWeb3()

  const daoToken = useSwellDaoToken()
  const restakedDaoToken = useRestakedSwellDaoToken()
  const airdropResultQuery = useSwellDaoAirdrop()
  const airdropOffchainStateQuery = useSwellDaoAirdropOffchainState()
  const airdropContractsStateQuery = useSwellDaoAirdropContractsState()

  const airdropResult = airdropResultQuery.data
  const airdropContractsState = airdropContractsStateQuery.data
  const airdropOffchainState = airdropOffchainStateQuery.data

  // calls
  const claimAirdrop = useSwellDaoClaimAirdrop()
  useWeb3CallErrorNotify(claimAirdrop)
  useEffect(() => {
    claimAirdrop.clear()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [account])

  const handleOnClaim = useCallback(
    (args: Parameters<typeof claimAirdrop.call>[0]) => {
      if (!account) {
        return
      }
      if (!airdropResult) {
        return
      }
      if (persistedClaim.exists) {
        return
      }
      const { amountToLock } = args
      onClaimDaoToken({ account, airdropResult, amountToLock })
    },
    [account, airdropResult, claimAirdrop, onClaimDaoToken, persistedClaim]
  )

  useEffect(() => {
    if (claimAirdrop.status !== claimAirdrop.STATUS.FULFILLED) {
      return
    }
    if (!claimAirdrop.args || !claimAirdrop.args.length) {
      return
    }

    handleOnClaim(claimAirdrop.args[0])
  }, [claimAirdrop, handleOnClaim])

  return (
    <AirdropCarousel
      airdropResult={airdropResult}
      minimumPearls={MIN_PEARLS_THRESHOLD}
      claimAirdrop={claimAirdrop}
      daoToken={daoToken}
      airdropContractsState={airdropContractsState}
      airdropOffchainState={airdropOffchainState}
      restakedDaoToken={restakedDaoToken}
      planktonPearlsThreshold={PLANKTON_THRESHOLD}
      checkerMode={CHECKER_MODE}
    />
  )
}
