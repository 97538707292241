import { Token } from '@/types/tokens'
import { displayCryptoLocale } from '@/util/displayCrypto'
import { WavedropClaimAirdrop } from '@/state/wavedrops/hooks'
import { Wavedrop, WavedropUser } from '@/state/wavedrops/types'
import { Web3Toast } from '@/components/StakingConfirmationProgressWidget/Web3Toast'

export const TRANSACTION_TOAST_TITLE = {
  COMPLETED: 'Transaction completed!',
  CLAIM_AIRDROP_PROMPTING: 'Claim pending',
  CLAIM_AIRDROP_PENDING: 'Claim confirming',
}

export function ClaimWavedropToast({
  claimWavedrop,
  daoToken,
  latestClaimableWavedrop: latestWavedrop,
  wavedropUser,
}: {
  claimWavedrop: WavedropClaimAirdrop
  daoToken: Token
  latestClaimableWavedrop: Wavedrop | undefined
  wavedropUser: WavedropUser | undefined
}) {
  const complete = claimWavedrop.status === claimWavedrop.STATUS.FULFILLED
  const confirming = claimWavedrop.status === claimWavedrop.STATUS.PROMPTING
  const pending = claimWavedrop.status === claimWavedrop.STATUS.PENDING
  const idle = claimWavedrop.status === claimWavedrop.STATUS.IDLE

  let claimToWalletStr = ''
  if (
    claimWavedrop.args &&
    latestWavedrop?.phase === 'claims' &&
    wavedropUser
  ) {
    const { cumulativeAmount } = latestWavedrop
    // const { cumulativeClaimed } = wavedropUser // TODO: take into account claimed amt
    const claimToWalletAmount = cumulativeAmount

    claimToWalletStr = displayCryptoLocale(
      claimToWalletAmount,
      daoToken.decimals,
      { precision: 2, roundLarge: true }
    )
  }

  let title = ''
  if (complete) {
    title = TRANSACTION_TOAST_TITLE.COMPLETED
  } else if (confirming) {
    title = TRANSACTION_TOAST_TITLE.CLAIM_AIRDROP_PROMPTING
  } else if (pending) {
    title = TRANSACTION_TOAST_TITLE.CLAIM_AIRDROP_PENDING
  }

  let message = ''
  if (claimToWalletStr) {
    message = `Withdraw ${claimToWalletStr} ${daoToken.symbol}`
  }

  return <Web3Toast title={title} message={message} call={claimWavedrop} />
}
