import { useDeploymentSetConfig } from './useDeploymentSetConfig'

export function useDaoSettings() {
  const { staticAirdropUrl } = useDeploymentSetConfig()
  // const staticAirdropUrl = 'https://v3-lrt.svc.swellnetwork.io'
  const { v3BackendLrtUrl } = useDeploymentSetConfig()
  // const v3BackendLrtUrl = 'http://localhost:8081'

  return { staticAirdropUrl, claimedEventsUrl: v3BackendLrtUrl }
}

export function useDaoSettingsChecker() {
  // const { v3BackendLrtUrl } = useDeploymentSetConfig()
  const v3BackendLrtUrl = 'https://v3-lrt.svc.swellnetwork.io'

  return {
    airdropCheckerBaseURL: v3BackendLrtUrl,
  }
}
