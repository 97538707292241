import styled from 'styled-components'
import { SolverFeeSummary } from '../types'
import { AsyncDiv } from '@/swell-ui/AsyncDiv'
import { useFlash } from '@/hooks/useFlash'

export function SolverFeeValue({ solverFee }: { solverFee: SolverFeeSummary }) {
  const { isHighFee, isRequestingWithdrawal, currentFee, highFee, lowFee } =
    solverFee

  const { flashAmount } = useFlash({
    flash: isHighFee,
    flashOn: 'toggle',
    flashOnMount: true,
  })

  let node = (
    <AsyncDiv loading={true}>
      {() => {
        return <>{null}</>
      }}
    </AsyncDiv>
  )
  if (isRequestingWithdrawal) {
    if (currentFee) {
      // TODO make different?
      if (isHighFee) {
        node = <span>{currentFee}</span>
      } else {
        node = <span>{currentFee}</span>
      }
    }
  } else if (lowFee && highFee) {
    node = (
      <span>
        {lowFee}-{highFee}
      </span>
    )
  }

  return (
    <Layout
      style={{
        backgroundColor: `rgba(255, 255, 255, ${flashAmount * 0.6})`,
      }}
    >
      {node}
    </Layout>
  )
}

const Layout = styled.div`
  display: flex;
  width: max-content;
`
