import { formatWithConfig } from '@/util/number'
import React, { useEffect, useMemo } from 'react'
import styled from 'styled-components'
import { v4 as uuid } from 'uuid'

const RADIUS = 100
const INRADIUS = 75
const RADIAL_OFFSET_DEG = 45
const STROKE_WIDTH = 1

const displayPercent = (r: number) => {
  return `${formatWithConfig(r * 100, {
    localize: true,
    precision: 0,
  })}%`
}

interface IRadialSplitVisProps {
  label0: string
  label1: string
  labelCenter: string
  amount: number
  ratio: number
}

export const RadialSplitVis: React.FC<IRadialSplitVisProps> = ({
  label0,
  label1,
  labelCenter,
  amount,
  ratio,
}) => {
  const ID = useMemo(
    () => ({
      circleShapeBig: `${uuid()}-circle-big`,
      circleShapeSmall: `${uuid()}-circle-small`,
      innerCircleMask: `${uuid()}-mask`,
      pizzaSliceMask: `${uuid()}-arc-mask`,
      bgGradient: `${uuid()}-gradient`,
    }),
    []
  )

  const [ratioVis, setRatioVis] = React.useState(ratio)
  useEffect(() => {
    let stop = false

    const animate = () => {
      if (stop) return
      setRatioVis((prev) => {
        const delta = ratio - prev
        const step = 0.1 * delta
        if (Math.abs(delta) < 0.01) {
          return ratio
        }
        return prev + step
      })
      requestAnimationFrame(animate)
    }

    animate()

    return () => {
      stop = true
    }
  }, [ratio])

  const angle = ratioVis * 360

  const total = Math.round(amount)
  const amount0 = Math.round(amount * ratio)
  const amount1 = total - amount0

  return (
    <svg
      width={RADIUS * 2}
      height={RADIUS * 2}
      viewBox={`0 0 ${RADIUS * 2} ${RADIUS * 2}`}
      overflow={'visible'}
    >
      <defs>
        <circle
          id={ID.circleShapeBig}
          cx={RADIUS}
          cy={RADIUS}
          r={RADIUS}
          pathLength="360"
        />
        <circle
          id={ID.circleShapeSmall}
          cx={RADIUS}
          cy={RADIUS}
          r={INRADIUS}
          pathLength="360"
        />
        <mask id={ID.innerCircleMask}>
          <use href={`#${ID.circleShapeBig}`} fill="white" />
          <use href={`#${ID.circleShapeSmall}`} fill="black" />
        </mask>
        <mask id={ID.pizzaSliceMask}>
          {/* Draw arc by angle in SVG - https://stackoverflow.com/a/77176845/3197015 */}
          <g>
            <use
              href={`#${ID.circleShapeBig}`}
              fill="none"
              stroke="white"
              strokeWidth={RADIUS * 2}
              strokeDasharray={`${angle} ${360 - angle}`}
              transform={`rotate(90) rotate(${RADIAL_OFFSET_DEG}) rotate(${
                -angle / 2
              })`}
              transform-origin={`${RADIUS} ${RADIUS}`}
            />
          </g>
        </mask>
        <linearGradient id={ID.bgGradient}>
          <stop offset="47%" stopColor="#2F43EC" stopOpacity={'0.47'} />
          <stop offset="100%" stopColor="#2F43EC" />
        </linearGradient>
      </defs>
      <g mask={`url(#${ID.pizzaSliceMask})`}>
        <use
          href={`#${ID.circleShapeBig}`}
          fill={`url(#${ID.bgGradient})`}
          mask={`url(#${ID.innerCircleMask})`}
        />
      </g>
      <use
        href={`#${ID.circleShapeSmall}`}
        fill={'none'}
        stroke={'#2F43EC'}
        strokeWidth={STROKE_WIDTH}
      />
      <use
        href={`#${ID.circleShapeBig}`}
        fill={'none'}
        stroke={'#2F43EC'}
        strokeWidth={STROKE_WIDTH}
      />
      <g transform={`translate(${RADIUS} ${RADIUS})`}>
        <text x={0} y={0} textAnchor={'middle'} alignmentBaseline={'middle'}>
          <LabelText>{labelCenter.toUpperCase()}</LabelText>
          <ValueText x={0} dy={20}>
            {formatWithConfig(amount, { precision: 0, localize: true })}
          </ValueText>
        </text>
      </g>
      <g
        transform={`translate(${RADIUS} ${RADIUS}) translate(${
          -Math.cos(RADIAL_OFFSET_DEG * (Math.PI / 180)) * RADIUS
        } ${Math.sin(RADIAL_OFFSET_DEG * (Math.PI / 180)) * RADIUS})`}
      >
        <text x={0} y={0} textAnchor={'middle'} dominantBaseline={'baseline'}>
          <LabelText>{label0.toUpperCase()}</LabelText>
          <ValueText x={0} dy={20}>
            {formatWithConfig(amount0, { precision: 0, localize: true })}
          </ValueText>
          <HintText dx={4} dy={-1}>
            {displayPercent(ratio)}
          </HintText>
        </text>
      </g>
      <g
        transform={`translate(${RADIUS} ${RADIUS}) translate(${
          Math.cos(RADIAL_OFFSET_DEG * (Math.PI / 180)) * RADIUS
        } ${-Math.sin(RADIAL_OFFSET_DEG * (Math.PI / 180)) * RADIUS})`}
      >
        <text x={0} y={0} textAnchor={'middle'} dominantBaseline={'baseline'}>
          <LabelText>{label1.toUpperCase()}</LabelText>
          <ValueText x={0} dy={20}>
            {formatWithConfig(amount1, { precision: 0, localize: true })}
          </ValueText>
          <HintText dx={4} dy={-1}>
            {displayPercent(1 - ratio)}
          </HintText>
        </text>
      </g>
    </svg>
  )
}

const LabelText = styled.tspan`
  fill: var(--lightblue-50, #a4abf1);
  font-family: Inter;
  font-size: 10px;
  font-style: normal;
  font-weight: 500;
  line-height: 160%; /* 16px */
  letter-spacing: 1.8px;
  text-transform: uppercase;
`

const ValueText = styled.tspan`
  fill: var(--white-50, #fff);
  /* Headlines/H7 */
  font-family: Inter;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: 130%; /* 26px */
  letter-spacing: -0.6px;
`

const HintText = styled.tspan`
  fill: var(--white-125, #b0b0b0);
  /* Body/xsmall */
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 160%;
  letter-spacing: -0.36px;
`
