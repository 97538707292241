import { MerkleDrop } from '@/types/merkle'
import { ChainId, SupportedChainId } from './chains'

export const MERKLE_DROP_EIGEN_STAKEDROP_RSWETH_MAINNET: MerkleDrop = {
  address: '0x263190d31d1e7c46de703ecedc46fd425afdefd3',
  chainId: SupportedChainId.MAINNET,
}

export const MERKLE_DROP_SWELL_AIRDROP_MAINNET: MerkleDrop = {
  address: '0x342F0D375Ba986A65204750A4AECE3b39f739d75',
  chainId: ChainId.MAINNET,
}

export const MERKLE_DROP_SWELL_AIRDROP_SEPOLIA: MerkleDrop = {
  address: '0xd1e72eB0E533e7a0597bB55B1A508FD6E2f41358',
  chainId: ChainId.SEPOLIA,
}

export const MERKLE_DROP_SWELL_WAVEDROPS_MAINNET: MerkleDrop = {
  address: '0xad26899c06b786b2cafdaf7a5dd180c47402ee16',
  chainId: ChainId.MAINNET,
}
