import { useRestakedSwellDaoToken } from '@/state/dao/hooks'
import { useEffect, useState } from 'react'
import { restakeSuccessfulModalMemo } from './stakeSwellFormatting'
import { YearnDeposit } from '@/state/yearnVault/hooks'
import { ConsiderDepositRswellInL2Summary } from '@/components/Modals/ConsiderDepositRswellInL2Modal'

export function useConsiderDepositInL2Modal({
  deposit,
}: {
  deposit: YearnDeposit
}) {
  const [open, setOpen] = useState(false)
  function onClose() {
    setOpen(false)
  }
  const restakedDaoToken = useRestakedSwellDaoToken()

  const [hasOpened, setHasOpened] = useState(false)
  const [summary, setSummary] = useState<
    ConsiderDepositRswellInL2Summary | undefined
  >(undefined)

  useEffect(() => {
    if (summary) return

    if (deposit.status === deposit.STATUS.FULFILLED) {
      const summary = restakeSuccessfulModalMemo({
        cachedDepositArgs: deposit.args ? deposit.args : undefined,
        restakedDaoToken: restakedDaoToken,
      })
      setSummary(summary)
    }
  }, [deposit, restakedDaoToken, summary])

  const shouldOpen =
    Boolean(summary) && deposit.status === deposit.STATUS.FULFILLED
  useEffect(() => {
    if (!shouldOpen) {
      return
    }
    if (hasOpened) {
      return
    }

    setOpen(true)
    setHasOpened(true)
  }, [shouldOpen, hasOpened])

  return {
    open,
    onClose,
    summary,
  }
}
