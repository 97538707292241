import styled from 'styled-components'
import { css } from 'styled-components'
import { Typography } from '@/swell-ui/Typography'
import { AsyncDiv } from '@/swell-ui/AsyncDiv'
import { ColoredHeadingTypography } from '@/swell-ui/Typography/typographyPresets'
import { NucleusVaultWidget } from '../Nucleus/NucleusVaultWidget'
import {
  NucleusFulfilledRequestEvent,
  NucleusRates,
  NucleusTokens,
  NucleusVault,
  NucleusWithdrawRequestResult,
} from '@/types/nucleus'
import { NucleusVaultCalls } from '@/state/nucleusVault/hooks'
import {
  NucleusAllowances,
  NucleusBalances,
  NucleusSharesState,
  NucleusVaultAuth,
  NucleusVaultPoints,
  NucleusVaultState,
  NucleusVaultStats,
} from '@/state/nucleusVault/types'
import { ReactNode } from 'react'
import { NucleusPointsCampaigns } from '../Nucleus/types'

export function EarnVaultPageTemplate({
  title: customTitle,
  vault,
  calls,
  stats,
  allowances,
  auth,
  balances,
  rates,
  sharesState,
  currentWithdrawRequest,
  fulfilledRequests,
  points,
  supportedTokens,
  baseAssetRateUsd,
  vaultState,
  pageDescription,
  descriptionNode,
  performanceNode,
  pointsCampaigns,
  noPerformanceData,
}: {
  title: ReactNode
  pageDescription: ReactNode
  descriptionNode: ReactNode
  performanceNode: ReactNode

  pointsCampaigns: NucleusPointsCampaigns
  vault: NucleusVault
  calls: NucleusVaultCalls
  stats: NucleusVaultStats | undefined
  allowances: NucleusAllowances | undefined
  auth: NucleusVaultAuth | undefined
  balances: NucleusBalances | undefined
  rates: NucleusRates | undefined
  sharesState: NucleusSharesState | undefined
  supportedTokens: NucleusTokens | undefined
  points: NucleusVaultPoints | undefined
  currentWithdrawRequest: NucleusWithdrawRequestResult | undefined
  fulfilledRequests: NucleusFulfilledRequestEvent[] | undefined
  vaultState: NucleusVaultState | undefined
  baseAssetRateUsd: number | undefined
  noPerformanceData?: boolean
}) {
  const title = (
    <Typography
      variant="headline"
      xs="h3"
      md="h2"
      size="h2"
      fstyle="bold"
      letterSpacing="small"
    >
      {customTitle}
    </Typography>
  )

  const desc = (
    <Typography
      variant="body"
      xs="medium"
      md="medium"
      size="medium"
      letterSpacing="small"
    >
      {pageDescription}
    </Typography>
  )

  return (
    <Layout className="v-page-layout">
      <div className="v-title">{title}</div>
      <div className="v-description">{desc}</div>

      {/* TODO: total not relevant anymore */}
      <div className="v-tvl" style={{ visibility: 'hidden' }}>
        <ColoredHeadingTypography>
          Total earn vaults TVL
        </ColoredHeadingTypography>
        <AsyncDiv loading={false}>{() => '$0.00'}</AsyncDiv>
      </div>

      <div className="v-widgets">
        <div className="v-widget">
          <NucleusVaultWidget
            noPerformanceData={noPerformanceData ?? false}
            calls={calls}
            stats={stats}
            allowances={allowances}
            auth={auth}
            balances={balances}
            rates={rates}
            sharesState={sharesState}
            supportedTokens={supportedTokens}
            vaultState={vaultState}
            currentWithdrawRequest={currentWithdrawRequest}
            points={points}
            fulfilledRequests={fulfilledRequests}
            vault={vault}
            baseAssetRateUsd={baseAssetRateUsd}
            descriptionNode={descriptionNode}
            performanceNode={performanceNode}
            pointsCampaigns={pointsCampaigns}
          />
        </div>
      </div>
    </Layout>
  )
}

const mobileLayout = css`
  padding: 24px 0 0 0;
  grid-template-columns: 1fr;
  grid-auto-rows: auto;
  align-items: center;
  justify-items: center;
  grid-template-areas:
    'title'
    'description'
    'tvl'
    'widgets';
  gap: 24px;

  .v-title,
  .v-description {
    text-align: left;
  }

  .v-title,
  .v-description {
    justify-self: start;
  }

  .v-tvl {
    text-align: left;
    align-self: start;
    justify-self: start;

    [aria-busy='true'] {
      height: 38.4px;
    }
    display: none;
  }
`
const tabletLayout = css`
  margin: 0 auto;

  padding: 24px 12px 0;

  grid-template-columns: 1fr auto;
  grid-auto-rows: auto;
  grid-template-areas:
    'title tvl'
    'description tvl'
    'widgets widgets';
  gap: unset;
  row-gap: 16px;

  .v-tvl {
    display: flex;
    text-align: right;
    align-self: end;

    [aria-busy='true'] {
      height: 49px;
    }
  }
  .v-description {
    white-space: nowrap;
  }
`
const desktopLayout = css``

const Layout = styled.div`
  max-width: 1100px;

  width: 100%;

  display: grid;

  .v-title {
    grid-area: title;
  }
  .v-description {
    grid-area: description;
    color: #fff;
  }
  .v-tvl {
    grid-area: tvl;
    display: flex;
    flex-flow: column nowrap;
    gap: 12px;

    .value {
      color: var(--Swell-White-50, #fff);
      /* Headlines/H4 */
      font-family: Inter;
      font-size: 40px;
      font-style: normal;
      font-weight: 600;
      line-height: 120%; /* 48px */
      letter-spacing: -1.2px;

      ${({ theme }) => theme.breakpoints.down('sm')} {
        font-size: 32px;
      }
    }
  }
  .v-widgets {
    grid-area: widgets;
    margin-top: 84px;
    width: 100%;

    display: flex;
    flex-flow: column nowrap;
    gap: 24px;

    .v-widget {
      width: 100%;

      &:not(:last-child) {
        margin-bottom: 42px;
      }
    }
  }

  ${mobileLayout}
  @media (min-width: 900px) {
    ${tabletLayout}
  }
  @media (min-width: 1391px) {
    ${desktopLayout}
  }
`
