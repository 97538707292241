import useSWRImmutable from 'swr/immutable'
import { useWavedropsApi } from './context'
import { useSwellWeb3 } from '@/swell-web3/core'
import { useWeb3Call } from '@/hooks/useWeb3Call'
import { merkleDropClaimAndLockGasEstimate } from '@/constants/gasEstimates'
import { ethers } from 'ethers'

export function useWavedrops() {
  const { read } = useWavedropsApi()
  const { account } = useSwellWeb3()
  return useSWRImmutable(
    account ? ['wavedrops', account] : null,
    read.wavedrops
  )
}

export function useWavedropUser() {
  const { read } = useWavedropsApi()
  const { account } = useSwellWeb3()
  return useSWRImmutable(
    account ? ['cumulativeClaimed', account] : null,
    read.wavedropUser
  )
}
export function useWavedropMerkleContractState() {
  const { read } = useWavedropsApi()
  return useSWRImmutable('wavedropContractState', read.wavedropContractState)
}

export function useClaimWavedrop() {
  const cumulativeClaimed = useWavedropUser().data?.cumulativeClaimed
  const { write, read } = useWavedropsApi()
  return useWeb3Call({
    fn: write.claimWavedrop,
    estimateGas: write.claimWavedropEstimateGas,
    staticGasEstimate: ({ amountToLock, cumulativeAmount }) => {
      return merkleDropClaimAndLockGasEstimate({
        amountToLock,
        cumulativeAmount,
        cumulativeClaimed: cumulativeClaimed ?? ethers.constants.Zero,
        lockType: 'none',
      })
    },
    validate: async ({ amountToLock, cumulativeAmount, merkleProof }) => {
      const valid = await read.checkClaimWavedrop({
        cumulativeAmount,
        amountToLock,
        merkleProof,
      })

      if (!valid) {
        return 'Invalid merkle proof'
      }

      return null
    },
  })
}
export type WavedropClaimAirdrop = ReturnType<typeof useClaimWavedrop>
