import { Token } from '@/types/tokens'
import {
  INucleusVaultApiRead,
  INucleusVaultApiWrite,
  NucleusAllowances,
  NucleusBalances,
  NucleusSharesState,
  NucleusVaultAuth,
  NucleusVaultPoints,
  NucleusVaultState,
  NucleusVaultStats,
} from './types'
import { NucleusVaultContext } from './context'
import { BigNumber, PayableOverrides } from 'ethers'
import {
  IERC20__factory,
  Multicall3,
  TellerWithMultiAssetSupport,
} from '@/abis/types'
import { useSwellWeb3 } from '@/swell-web3/core'
import { TOKEN_LIST_ETH } from '@/constants/tokens'
import {
  useAtomicQueue,
  useBoringVault,
  useBoringVaultTeller,
  useMulticallContract,
} from '@/hooks/useContract'
import { SolmateRolesAuthorityService } from '@/services/SolmateRolesAuthority'
import { NucleusVaultService } from '@/services/NucleusVaultService'
import { useMemo } from 'react'
import { TokenMulticalls } from '@/services/Tokens'
import { useV3BackendClient } from '@/services/V3BackendService/hooks'
import {
  NucleusFulfilledRequestEvent,
  NucleusRates,
  NucleusShareUnlockResult,
  NucleusTokens,
  NucleusVault,
  NucleusWithdrawRequestResult,
} from '@/types/nucleus'
import { NucleusClient } from '@/services/V3BackendService/types'
import { JsonRpcProvider } from '@ethersproject/providers'
import { latestNucleusWithdrawRequest } from '@/util/nucleus'
import { useNucleusVaultBackendSettings } from '../deployments/hooks/nucleusSettings'
import { getAddress } from 'ethers/lib/utils'

const DEPOSIT_SIGNATURE = '0x0efe6a8b' // keccak256("deposit(address,uint256,uint256)")

// The service provides a batch of recent AtomicQueue AtomicRequestFulfilled events for the user.
export interface INucleusFulfilledRequestsFetcher {
  fulfilledRequests: (params: {
    account: string
    vaultToken: string
    wantAssets: string[]
  }) => Promise<NucleusFulfilledRequestEvent[]>
}
export interface INucleusVaultStatsFetcher {
  vaultTokenStats: (params: {
    vaultToken: string
  }) => Promise<NucleusVaultStats>
}
export interface INucleusPointsFetcher {
  points: (params: { account: string }) => Promise<NucleusVaultPoints>
}

export function useNucleusVaultImpl({
  fulfilledRequestsFetcher,
  atomicQueueAddress,
  vault,
  vaultStatsFetcher,
  pointsFetcher,
}: {
  vault: NucleusVault
  fulfilledRequestsFetcher: INucleusFulfilledRequestsFetcher
  atomicQueueAddress: string
  vaultStatsFetcher: INucleusVaultStatsFetcher
  pointsFetcher: INucleusPointsFetcher
}): NucleusVaultContext {
  return {
    read: useNucleusVaultReadImpl({
      fulfilledRequestsFetcher,
      vault,
      atomicQueueAddress,
      vaultStatsFetcher,
      pointsFetcher,
    }),
    write: useNucleusVaultWriteImpl({ vault, atomicQueueAddress }),
    atomicQueueAddress,
    ...vault,
  }
}

async function fetchAuth({
  account,
  rolesAuthorityAddress,
  multicall,
  tellerAddress,
}: {
  account: string
  multicall: Multicall3
  rolesAuthorityAddress: string
  tellerAddress: string
}): Promise<NucleusVaultAuth> {
  const rolesAuth = new SolmateRolesAuthorityService(
    multicall,
    rolesAuthorityAddress
  )

  const [{ canCall: canDeposit }] = await rolesAuth.fetchCanCall([
    {
      user: account,
      target: tellerAddress,
      sig: DEPOSIT_SIGNATURE,
    },
  ])

  return {
    isAuthorizedToDeposit: canDeposit,
    isAuthorizedToWithdraw: true, // permissionless
  }
}

async function fetchBalances({
  account,
  depositAssetAddresses,
  multicall,
  vaultTokenAddress,
  withdrawAssetAddresses,
  provider,
}: {
  account: string
  multicall: Multicall3
  withdrawAssetAddresses: string[]
  depositAssetAddresses: string[]
  vaultTokenAddress: string
  provider: JsonRpcProvider
}): Promise<NucleusBalances> {
  const tc = new TokenMulticalls(multicall)
  const addressesSet = new Set<string>([
    ...depositAssetAddresses,
    ...withdrawAssetAddresses,
    vaultTokenAddress,
  ])

  const hadETH = addressesSet.delete(TOKEN_LIST_ETH.address)
  const results = await tc.fetchBalances(Array.from(addressesSet), account)

  const assets: Record<string, BigNumber> = {}
  for (const res of results) {
    assets[res.token] = res.balance
  }

  const vaultToken = assets[vaultTokenAddress]

  if (hadETH) {
    const ethBalance = await provider.getBalance(account)
    assets[TOKEN_LIST_ETH.address] = BigNumber.from(ethBalance)
  }

  return {
    assets,
    vaultToken,
  }
}

async function fetchAllowances({
  account,
  multicall,
  atomicQueueAddress,
  vaultTokenAddress,
  depositAssetAddresses,
}: {
  account: string
  multicall: Multicall3
  vaultTokenAddress: string
  atomicQueueAddress: string
  depositAssetAddresses: string[]
}): Promise<NucleusAllowances> {
  const ts = new TokenMulticalls(multicall)
  const [{ allowance: vaultTokenForAtomicQueue }, ...rest] =
    await ts.fetchAllowances(
      [
        {
          spender: atomicQueueAddress,
          token: vaultTokenAddress,
        },
        ...depositAssetAddresses.map((token) => ({
          spender: vaultTokenAddress,
          token,
        })),
      ],
      account
    )

  const assetsForDeposit: Record<string, BigNumber> = {}
  for (const res of rest) {
    assetsForDeposit[res.token] = res.allowance
  }

  return {
    assetsForDeposit,
    vaultTokenForAtomicQueue,
  }
}

async function fetchRates({
  assetAddresses,
  nucleus,
}: {
  assetAddresses: string[]
  nucleus: NucleusVaultService
}): Promise<NucleusRates> {
  const addressSet = new Set(assetAddresses)
  return nucleus.rates({
    assetAddresses: Array.from(addressSet),
  })
}

async function fetchRecentFulfilledRequests({
  fulfilledRequestsFetcher,
  account,
  wantAssets,
  vaultToken,
}: {
  fulfilledRequestsFetcher: INucleusFulfilledRequestsFetcher
  account: string
  wantAssets: string[]
  vaultToken: Token
}): Promise<NucleusFulfilledRequestEvent[]> {
  const events = await fulfilledRequestsFetcher.fulfilledRequests({
    account,
    wantAssets,
    vaultToken: vaultToken.address,
  })

  return events
}
async function fetchSharesState(): Promise<NucleusSharesState> {
  // TODO: how to fetch?
  const shareUnlock: NucleusShareUnlockResult = {
    isLocked: false,
  }
  return {
    shareUnlock,
  }
}

async function fetchVaultStats({
  fetcher,
  vaultTokenAddress,
}: {
  fetcher: INucleusVaultStatsFetcher
  vaultTokenAddress: string
}): Promise<NucleusVaultStats> {
  return fetcher.vaultTokenStats({
    vaultToken: vaultTokenAddress,
  })
}
async function fetchSupportedTokens({
  nvs,
}: {
  nvs: NucleusVaultService
}): Promise<NucleusTokens> {
  return nvs.supportedTokens()
}
async function fetchVaultState({
  teller,
  nucleusClient,
  vault,
}: {
  teller: TellerWithMultiAssetSupport
  nucleusClient: NucleusClient
  vaultTokenAddress: string
  vault: NucleusVault
}): Promise<NucleusVaultState> {
  const isDepositPaused = await teller.isPaused()

  const { withdrawAssets, vaultToken } = vault
  const {
    solverFees: solverFeesResp,
    withdrawalProcessingDurationUnix,
    performanceFeePercent,
    platformFeePercent,
  } = await nucleusClient.vaultState({ vaultToken: vaultToken.address })

  const solverFees: NucleusVaultState['solverFees'] = {}
  for (const s of solverFeesResp) {
    const {
      highFeePercent,
      lowFeePercent,
      wantTokenAddress: wantTokenAddressNoChecksum,
      lowFeeThresholdWei,
    } = s

    const wantTokenAddress = getAddress(wantTokenAddressNoChecksum)

    const lowFeeThreshold = BigNumber.from(lowFeeThresholdWei)
    solverFees[wantTokenAddress] = {
      feeHighPercent: highFeePercent,
      feeLowPercent: lowFeePercent,
      lowFeeThreshold: lowFeeThreshold,
    }
  }
  for (const wantToken of withdrawAssets) {
    const wantTokenAddress = wantToken.address
    if (!solverFees[wantTokenAddress]) {
      console.warn(
        `fetchVaultState: no solver fees for wantToken ${wantTokenAddress}`,
        {
          solverFees,
          wantTokenAddress,
        }
      )
      solverFees[wantTokenAddress] = {
        feeHighPercent: 0,
        feeLowPercent: 0,
        lowFeeThreshold: BigNumber.from(0),
      }
    }
  }

  return {
    isDepositPaused,
    performanceFeePercent,
    solverFees,
    withdrawalProcessingDurationUnix,
    platformFeePercent,
  }
}
async function fetchWithdrawRequest({
  nvs,
  account,
  wantTokens,
  withdrawAssetAddresses,
}: {
  nvs: NucleusVaultService
  account: string
  wantTokens: string[]
  withdrawAssetAddresses: string[]
}): Promise<NucleusWithdrawRequestResult> {
  const allReqsForAsset = await nvs.withdrawalRequestsByWantToken({
    wantTokens,
    user: account,
  })
  const latest = latestNucleusWithdrawRequest(
    allReqsForAsset,
    withdrawAssetAddresses
  )
  return latest
}
function useNucleusVaultReadImpl({
  fulfilledRequestsFetcher,
  vault,
  atomicQueueAddress,
  vaultStatsFetcher,
  pointsFetcher,
}: {
  fulfilledRequestsFetcher: INucleusFulfilledRequestsFetcher
  vault: NucleusVault
  atomicQueueAddress: string
  vaultStatsFetcher: INucleusVaultStatsFetcher
  pointsFetcher: INucleusPointsFetcher
}): INucleusVaultApiRead {
  const { account: maybeAccount, provider } = useSwellWeb3()
  const multicall = useMulticallContract()
  const teller = useBoringVaultTeller(vault.tellerAddress)

  const vaultTokenAddress = vault.vaultToken.address

  const { nucleusBackendURL } = useNucleusVaultBackendSettings()

  const nucleusClient =
    useV3BackendClient(nucleusBackendURL).v3BackendClient.nucleus
  const nucleusWalletClient =
    useV3BackendClient(nucleusBackendURL).v3BackendClient.nucleusWallet

  const nvs = useMemo(
    () =>
      new NucleusVaultService({
        atomicQueueAddress,
        multicall,
        vault,
      }),
    [atomicQueueAddress, multicall, vault]
  )

  const account = maybeAccount!

  return {
    auth: async () => {
      return fetchAuth({
        account,
        tellerAddress: vault.tellerAddress,
        multicall,
        rolesAuthorityAddress: vault.rolesAuthorityAddress,
      })
    },
    balances: async () => {
      return fetchBalances({
        account,
        depositAssetAddresses: vault.depositAssets.map((t) => t.address),
        multicall,
        vaultTokenAddress: vault.vaultToken.address,
        withdrawAssetAddresses: vault.withdrawAssets.map((t) => t.address),
        provider,
      })
    },
    allowances: async () => {
      return fetchAllowances({
        account,
        atomicQueueAddress: atomicQueueAddress,
        depositAssetAddresses: vault.depositAssets.map((t) => t.address),
        multicall,
        vaultTokenAddress: vault.vaultToken.address,
      })
    },
    rates: async () => {
      return fetchRates({
        assetAddresses: vault.depositAssets.map((t) => t.address),
        nucleus: nvs,
      })
    },
    recentFulfilledRequests: async () => {
      return fetchRecentFulfilledRequests({
        account,
        fulfilledRequestsFetcher,
        vaultToken: vault.vaultToken,
        wantAssets: vault.withdrawAssets.map((t) => t.address),
      })
    },
    sharesState: async () => {
      return fetchSharesState()
    },
    stats: async () => {
      return fetchVaultStats({
        fetcher: vaultStatsFetcher,
        vaultTokenAddress,
      })
    },
    supportedAssets: async () => {
      return fetchSupportedTokens({
        nvs,
      })
    },
    vaultState: async () => {
      return fetchVaultState({
        teller,
        nucleusClient,
        vaultTokenAddress,
        vault,
      })
    },
    withdrawRequest: async () => {
      const wantTokens = vault.withdrawAssets.map((t) => t.address)
      return fetchWithdrawRequest({
        account,
        nvs,
        wantTokens,
        withdrawAssetAddresses: vault.withdrawAssets.map((t) => t.address),
      })
    },
    points: async () => {
      return pointsFetcher.points({ account })
    },
    checkDeposit: async () => {
      return true
    },
    checkWithdrawalRequest: async ({ wantAssetAddress, withdrawRequest }) => {
      const res = await nucleusWalletClient.verifyWithdrawal({
        atomicPriceWei: withdrawRequest.atomicPrice.toString(),
        atomicQueueAddress,
        offerToken: vault.vaultToken.address,
        offerTokenAmount: withdrawRequest.offerAmount.toString(),
        user: account,
        wantToken: wantAssetAddress,
      })

      const { isValid, failMessage } = res
      if (!isValid) {
        return {
          valid: false,
          reason: failMessage,
        }
      }
      return {
        valid: true,
      }
    },
  }
}

function useNucleusVaultWriteImpl({
  atomicQueueAddress,
  vault,
}: {
  vault: NucleusVault
  atomicQueueAddress: string
}): INucleusVaultApiWrite {
  const { provider } = useSwellWeb3()

  const atomicQueue = useAtomicQueue(atomicQueueAddress)
  const boringVault = useBoringVault(vault.vaultToken.address)
  const teller = useBoringVaultTeller(vault.tellerAddress)

  return {
    approveAssetForDeposit: ({ assetAddress, amount }, opts) => {
      const erc20 = IERC20__factory.connect(assetAddress, provider.getSigner())
      return erc20.approve(vault.vaultToken.address, amount, opts)
    },
    approveAssetForDepositEstimateGas: async ({ assetAddress, amount }) => {
      const erc20 = IERC20__factory.connect(assetAddress, provider.getSigner())
      return erc20.estimateGas.approve(vault.vaultToken.address, amount)
    },
    approveVaultTokenForAtomicQueue: ({ amount }, opts) => {
      return boringVault.approve(atomicQueueAddress, amount, opts)
    },
    approveVaultTokenForAtomicQueueEstimateGas: ({ amount }) => {
      return boringVault.estimateGas.approve(atomicQueueAddress, amount)
    },
    deposit: ({ depositAsset, depositAmount, minimumMint }, userOpts) => {
      const opts: PayableOverrides = userOpts ?? {}
      if (depositAsset === TOKEN_LIST_ETH.address) {
        // native deposit requires value to be set
        opts.value = depositAmount
      }
      return teller.deposit(depositAsset, depositAmount, minimumMint, userOpts)
    },
    depositEstimateGas: ({ depositAsset, depositAmount, minimumMint }) => {
      const opts: PayableOverrides = {}
      if (depositAsset === TOKEN_LIST_ETH.address) {
        // native deposit requires value to be set
        opts.value = depositAmount
      }
      return teller.estimateGas.deposit(
        depositAsset,
        depositAmount,
        minimumMint,
        opts
      )
    },
    requestWithdrawal: ({ wantAssetAddress, withdrawRequest }, opts) => {
      return atomicQueue.updateAtomicRequest(
        vault.vaultToken.address,
        wantAssetAddress,
        {
          atomicPrice: withdrawRequest.atomicPrice,
          deadline: withdrawRequest.deadlineUnix,
          inSolve: false,
          offerAmount: withdrawRequest.offerAmount,
        },
        opts
      )
    },
    requestWithdrawalEstimateGas: ({ wantAssetAddress, withdrawRequest }) => {
      return atomicQueue.estimateGas.updateAtomicRequest(
        vault.vaultToken.address,
        wantAssetAddress,
        {
          atomicPrice: withdrawRequest.atomicPrice,
          deadline: withdrawRequest.deadlineUnix,
          inSolve: false,
          offerAmount: withdrawRequest.offerAmount,
        }
      )
    },
    cancelWithdrawal: ({ wantAssetAddress }, opts) => {
      return atomicQueue.updateAtomicRequest(
        vault.vaultToken.address,
        wantAssetAddress,
        {
          atomicPrice: BigNumber.from(0),
          deadline: BigNumber.from(0),
          inSolve: false,
          offerAmount: BigNumber.from(0),
        },
        opts
      )
    },
    cancelWithdrawalEstimateGas: ({ wantAssetAddress }) => {
      return atomicQueue.estimateGas.updateAtomicRequest(
        vault.vaultToken.address,
        wantAssetAddress,
        {
          atomicPrice: BigNumber.from(0),
          deadline: BigNumber.from(0),
          inSolve: false,
          offerAmount: BigNumber.from(0),
        }
      )
    },
  }
}
