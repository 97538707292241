export const AirdropStep = {
  Intro: 0 as const,
  TheJourney: 1 as const,
  TheFuture: 2 as const,
  NewSwellEra: 3 as const,
  Claim: 4 as const,
  Complete: 5 as const,
}
export type AirdropStep = (typeof AirdropStep)[keyof typeof AirdropStep]

export type VestingDatum = {
  date: string
  tokenLogoURI: string
  amount: string
  claimed: boolean
}

export type VestingClaimSchedule = {
  vesting: VestingDatum[]
  selectedOption2: boolean
  claimableNowRestaked: string
  claimableNowNonRestaked: string
  restakedLogoURI: string
  nonRestakedLogoURI: string
}

export type AirdropRewardSummary = {
  baseReward: string
  loyaltyBonus: string
  totalClaimable: string
}

export type AirdropCompletionSummary = {
  claimedToWalletAmount: string
  totalRestakedAmount: string
  totalClaimedAmount: string
  totalClaimedTokenSymbols: string[]
  callToAction: 'l2' | 'restake'
}

export type VestingKeyDatesList = {
  claimableToday: string
  futureVests: string[]
}

export type VestingBreakdown = {
  initialClaimablePercent: string
  unlocks: {
    date: string
    percent: string
    amount: string
  }[]
}
