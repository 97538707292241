import { FlexRow } from '@/swell-ui/FlexRow'
import { ReactNode, useRef } from 'react'
import styled, { css } from 'styled-components'
import { paragraphHintCSS, title1CSS } from '../styles/common'
import futureVis from '@/assets/images/airdrop/future_vis.png'
import { useMediaQuery } from '@mui/material'
import { v4 as uuid } from 'uuid'
import { usePageDimensions } from '@/hooks/usePageDimensions'
import { scaleLinear } from '@/util/charts'
import { keyframes } from 'styled-components'

const TOOLTIP_DOT_SIZE_PX = 12 // r=6

const BAKED_IMG_SCALE = 0.78
const IMAGE_DESKTOP_FULL_WIDTH_PX = 749 * BAKED_IMG_SCALE
const IMAGE_DESKTOP_FULL_HEIGHT_PX = 608 * BAKED_IMG_SCALE

const MAX_DESKTOP_TOOLTIP_LINE_WIDTH_PX = 253
const DESKTOP_TOOLTIP_TEXT_OVERSHOOT = 59
const MAX_TOOLTIP_WIDTH_DESKTOP =
  MAX_DESKTOP_TOOLTIP_LINE_WIDTH_PX + DESKTOP_TOOLTIP_TEXT_OVERSHOOT

const L2_YIELD_HTML =
  'Swell L2 activity generates yield which cascades down the stack'
const NETWORK_INFRA_HTML =
  'Network infrastructure secured by restaked Swell assets'
const RESTAKED_YIELD_HTML = 'Restaked Swell assets accrue yield'

const ROLLUP_RESTAKING_YIELD_LEARN_MORE_URL =
  'https://www.swellnetwork.io/post/restakingyieldlayer'

// https://www.figma.com/design/1trLPK52KCe6Nko8e2NCbU/Swell-Network-Design?node-id=10594-20749&node-type=text&m=dev
export function AirdropTheFuture() {
  const isDesktop = useMediaQuery(`(min-width: 1120px)`)

  return (
    <Layout direction="column" align="center">
      <h1>The Future</h1>
      <p>Swell L2 - The restaking yield layer.</p>
      {!isDesktop && <VisMobile />}
      {isDesktop && <VisDesktop />}
      <p className="space-top">
        The first vertically-integrated rollup with native restaking yield.{' '}
        <a href={ROLLUP_RESTAKING_YIELD_LEARN_MORE_URL}>Learn more...</a>
      </p>

      <div className="spacer-mob" />
      <div style={{ height: '46px' }} />
    </Layout>
  )
}

const fadeIn = keyframes`
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
`

const fadeInCSS = css`
  opacity: 0;
  animation: ${fadeIn} 0.65s ease-out forwards;
  animation-delay: 0.15s;
`

const Layout = styled(FlexRow)`
  ${fadeInCSS}
  margin: auto 0;
  h1 {
    ${title1CSS}
    margin: 0;
    text-align: center;
    margin-bottom: 24px;
  }
  h2 {
    color: var(--Swell-White-50, #fff);
    text-align: center;
    /* Headlines/H7 */
    font-family: Inter;
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: 130%; /* 26px */
    letter-spacing: -0.6px;
  }
  span.label {
    color: var(--Swell-White-50, #fff);
    text-align: center;
    /* Body/xsmall */
    font-family: Inter;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 160%; /* 19.2px */
    letter-spacing: -0.36px;
  }
  .bodysmall {
    color: var(--Swell-White-125, #b0b0b0);
    /* Body/small */
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 160%; /* 22.4px */
    letter-spacing: -0.42px;
  }

  .spacer-mob {
    height: 36px;
  }
  .space-top {
    margin-top: 24px;
  }
  @media (min-width: 1120px) {
    .spacer-mob {
      display: none;
    }
  }

  p {
    margin: 0;
  }

  p {
    text-align: center;
  }

  .round {
    border-radius: 50%;
  }

  h2 {
    margin-top: 42px;
    margin-bottom: 0;
  }

  .hint {
    ${paragraphHintCSS}
  }
`

function VisDesktop() {
  const { pageWidth } = usePageDimensions()

  let scale: number
  let hidden = false
  if (!pageWidth) {
    scale = 1
    hidden = true
  } else {
    scale = scaleLinear(pageWidth, [1120, 1316], [0.8, 1])
    scale = Math.max(0.6, Math.min(1, scale))
  }

  const imgHeight = IMAGE_DESKTOP_FULL_HEIGHT_PX * scale
  const imgWidth = IMAGE_DESKTOP_FULL_WIDTH_PX * scale

  const l2Yield = (
    <TooltipDesktop anchor="left">
      <span dangerouslySetInnerHTML={{ __html: L2_YIELD_HTML }} />
    </TooltipDesktop>
  )
  const networkInfra = (
    <TooltipDesktop anchor="right">
      <span dangerouslySetInnerHTML={{ __html: NETWORK_INFRA_HTML }} />
    </TooltipDesktop>
  )
  const restakedYield = (
    <TooltipDesktop anchor="left">
      <span dangerouslySetInnerHTML={{ __html: RESTAKED_YIELD_HTML }} />
    </TooltipDesktop>
  )

  return (
    <>
      <div style={{ height: '24px' }} />
      <VisDesktopLayout hidden={hidden}>
        <img src={futureVis} width={imgWidth} height={imgHeight} />
        <PositionedR x={`${imgWidth * 0.2}px`} y={`${imgHeight * 0.255}px`}>
          {l2Yield}
        </PositionedR>
        <PositionedL x={`${imgWidth * 0.12}px`} y={`${imgHeight * 0.465}px`}>
          {networkInfra}
        </PositionedL>
        <PositionedR x={`${imgWidth * -0.035}px`} y={`${imgHeight * 0.67}px`}>
          {restakedYield}
        </PositionedR>
      </VisDesktopLayout>
    </>
  )
}

const VisDesktopLayout = styled.div<{ hidden: boolean }>`
  margin: 0 auto;
  width: auto;

  display: flex;
  justify-content: center;
  align-items: center;
  > div {
    width: auto;
    overflow: visible;
  }

  position: relative;

  visibility: ${(props) => (props.hidden ? 'hidden' : 'visible')};
`

const PositionedL = styled.div<{ x: string; y: string }>`
  position: absolute;
  top: ${(props) => props.y};
  left: ${(props) => props.x};
  transform: translate(-50%, -50%);
`
const PositionedR = styled.div<{ x: string; y: string }>`
  position: absolute;
  top: ${(props) => props.y};
  right: ${(props) => props.x};
  transform: translate(50%, -50%);
`

function VisMobile() {
  const l2Yield = <li dangerouslySetInnerHTML={{ __html: L2_YIELD_HTML }} />
  const networkInfra = (
    <li dangerouslySetInnerHTML={{ __html: NETWORK_INFRA_HTML }} />
  )
  const restakedYield = (
    <li dangerouslySetInnerHTML={{ __html: RESTAKED_YIELD_HTML }} />
  )

  return (
    <VisMobileLayout>
      <img src={futureVis} className="vis" />
      <ul style={{ marginTop: '24px' }}>
        {l2Yield}
        {networkInfra}
        {restakedYield}
      </ul>
    </VisMobileLayout>
  )
}

const VisMobileLayout = styled.div`
  margin: 0 auto;
  width: auto;

  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;
  > div {
    width: auto;
    /* align-self: center; */
    overflow: visible;
  }

  position: relative;

  .vis {
    width: 100%;
    height: auto;
    max-width: 633px;
  }
`

function TooltipDesktop({
  children,
  anchor,
}: {
  children?: ReactNode
  anchor: 'left' | 'right'
}) {
  const ID = useRef({
    lineShape: uuid(),
    lineGradient: uuid(),
    dotShape: uuid(),
  })

  let gradientNodes = (
    <>
      <stop offset="0%" stopColor="#FFF" stopOpacity={1} />
      <stop offset="100%" stopColor="#FFF" stopOpacity={0} />
    </>
  )
  if (anchor === 'right') {
    gradientNodes = (
      <>
        <stop offset="0%" stopColor="#FFF" stopOpacity={0} />
        <stop offset="100%" stopColor="#FFF" stopOpacity={1} />
      </>
    )
  }

  let flexAlign: 'flex-start' | 'flex-end' = 'flex-start'
  if (anchor === 'right') {
    flexAlign = 'flex-end'
  }

  return (
    <TooltipDesktopLayout
      direction="column"
      gap="8"
      anchor={anchor}
      align={flexAlign}
      maxWidth={`${MAX_TOOLTIP_WIDTH_DESKTOP}px`}
    >
      <span className="txt">{children}</span>
      <svg
        width={MAX_DESKTOP_TOOLTIP_LINE_WIDTH_PX}
        height={TOOLTIP_DOT_SIZE_PX}
        overflow="visible"
      >
        <defs>
          <rect
            id={ID.current.lineShape}
            x="0"
            y="5.5"
            width="100%"
            height="1"
          />
          <circle
            id={ID.current.dotShape}
            cx={anchor === 'left' ? '0%' : '100%'}
            cy="6"
            r="6"
          />
          <linearGradient
            id={ID.current.lineGradient}
            x1="0"
            y1="0"
            x2="100%"
            y2="0"
          >
            {gradientNodes}
          </linearGradient>
        </defs>
        <use
          href={`#${ID.current.lineShape}`}
          fill={`url(#${ID.current.lineGradient})`}
        />
        <use
          href={`#${ID.current.dotShape}`}
          fill="#010508"
          stroke="#FFF"
          strokeWidth="1"
        />
      </svg>
    </TooltipDesktopLayout>
  )
}

const TooltipDesktopLayout = styled(FlexRow)<{ anchor: 'left' | 'right' }>`
  ${(props) =>
    props.anchor === 'left'
      ? css`
          transform: translate(50%, -50%) translate(0, 6px);
          .txt {
            margin: 0 0 0 24px;
            text-align: left;
          }
        `
      : css`
          transform: translate(-50%, -50%) translate(0, 6px);
          .txt {
            margin: 0 24px 0 0;
            text-align: right;
          }
        `};

  .txt {
    color: var(--Swell-White-50, #fff);
    /* Body/xsmall */
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 160%; /* 19.2px */
    letter-spacing: -0.36px;
  }
`
