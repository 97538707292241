import { useV3BackendClient } from '@/services/V3BackendService/hooks'
import { useWavedropsSettings } from '../deployments/hooks/useWavedropsSettings'
import { WavedropsContext } from './context'
import { useSwellWeb3 } from '@/swell-web3/core'
import { Wavedrop, WavedropPhase } from './types'
import { BigNumber } from 'ethers'
import { reconstructWavedropsFromStaticData } from './util'
import {
  useMerkleDropContract,
  useMulticallContract,
} from '@/hooks/useContract'
import { MERKLE_DROP_SWELL_WAVEDROPS_MAINNET } from '@/constants/merkleDrops'
import { useMemo } from 'react'
import { StaticWavedropService } from '@/services/StaticWavedrop'
import {
  CumulativeMerkleDrop__factory,
  IERC20__factory,
  Multicall3,
} from '@/abis/types'
import { TOKEN_LIST_SWELL } from '@/constants/tokens'

const MERKLE_DROP = MERKLE_DROP_SWELL_WAVEDROPS_MAINNET

export function useWavedropsApiImpl(): WavedropsContext {
  const { account: maybeAccount } = useSwellWeb3()
  const { preClaimBackendURL, wavedropServiceURL } = useWavedropsSettings()
  const merkleDrop = useMerkleDropContract(MERKLE_DROP.address)!

  const preClaimWalletSvc =
    useV3BackendClient(preClaimBackendURL).v3BackendClient.wallet

  const account = maybeAccount!

  const wavedropSvc = useMemo(
    () => new StaticWavedropService({ baseURL: wavedropServiceURL }),
    [wavedropServiceURL]
  )
  const multicall = useMulticallContract()!

  return {
    currentWaveNumber: 2,
    read: {
      wavedropContractState: async () => {
        // const claimIsOpen = await
        // const merkleRoot = 0
        const calls: Multicall3.Call3Struct[] = []
        calls.push({
          target: MERKLE_DROP.address,
          allowFailure: false,
          callData:
            CumulativeMerkleDrop__factory.createInterface().encodeFunctionData(
              'merkleRoot'
            ),
        })
        calls.push({
          target: MERKLE_DROP.address,
          allowFailure: false,
          callData:
            CumulativeMerkleDrop__factory.createInterface().encodeFunctionData(
              'claimIsOpen'
            ),
        })

        const [merkleRootAirdropResult, claimIsOpenAirdropResult] =
          await multicall.callStatic.tryAggregate(true, calls)

        const merkleRoot =
          CumulativeMerkleDrop__factory.createInterface().decodeFunctionResult(
            'merkleRoot',
            merkleRootAirdropResult.returnData
          )[0]
        const claimIsOpen =
          CumulativeMerkleDrop__factory.createInterface().decodeFunctionResult(
            'claimIsOpen',
            claimIsOpenAirdropResult.returnData
          )[0]

        return {
          merkleDrop: {
            claimIsOpen: claimIsOpen,
            merkleRoot: merkleRoot,
          },
          staking: { exists: false },
        }
      },
      wavedropUser: async () => {
        const cumulativeClaimed = await merkleDrop.cumulativeClaimed(account)
        return { cumulativeClaimed }
      },
      wavedrops: async () => {
        const staticWdsP = wavedropSvc.wavedrops(account)
        const reqWd2P = preClaimWalletSvc.wavedrop2User({
          walletAddress: account,
        })
        const staticWds = await staticWdsP
        const reqWd2 = await reqWd2P

        let wd1: Wavedrop | undefined
        const _wd1 = staticWds.wavedrops.find((wd) => wd.wavedropNumber === 1)
        if (_wd1) {
          wd1 = reconstructWavedropsFromStaticData(_wd1)
        }
        if (!wd1) {
          console.error('wd1 not found')
          wd1 = {
            waveNumber: 1,
            phase: WavedropPhase.claims,
            daoTokenAmount: BigNumber.from(0),
            blackPearls: 0,
            multiplier: 0,
            cumulativeAmount: BigNumber.from(0),
            merkleProof: [],
            merkleRoot: '',
            swellAmount: BigNumber.from(0),
          }
        }

        const wd2: Wavedrop = {
          waveNumber: 2,
          phase: WavedropPhase.ongoing,
          blackPearls: reqWd2.blackPearls,
          multiplier: reqWd2.multiplier,
        }

        return {
          wavedrops: [wd1, wd2],
        }
      },
      checkClaimWavedrop: async ({ cumulativeAmount, merkleProof }) => {
        return merkleDrop.verifyProof(merkleProof, cumulativeAmount, account)
      },
    },
    write: {
      claimWavedrop: async (
        { amountToLock, cumulativeAmount, merkleProof },
        opts
      ) => {
        return merkleDrop.claimAndLock(
          cumulativeAmount,
          amountToLock,
          merkleProof,
          opts
        )
      },
      claimWavedropEstimateGas: async ({
        amountToLock,
        cumulativeAmount,
        merkleProof,
      }) => {
        return merkleDrop.estimateGas.claimAndLock(
          cumulativeAmount,
          amountToLock,
          merkleProof
        )
      },
    },
  }
}
