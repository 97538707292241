import { NucleusVaultStateWithdraw } from '@/state/nucleusVault/types'
import { ColoredHeadingTypography } from '@/swell-ui/Typography/typographyPresets'
import styled, { css } from 'styled-components'
import { INucleusActiveWithdrawal } from '../../nucleusHooks'
import { FlexRow } from '@/swell-ui/FlexRow'
import { Token } from '@/types/tokens'
import { AsyncDiv } from '@/swell-ui/AsyncDiv'
import { useMediaQuery } from '@mui/material'
import {
  makeNucleusClaimSummary,
  makeNucleusVaultWithdrawalsSummary,
} from '../../nucleusFormatting'
import { ScrollableYArea } from '@/swell-ui/ScrollableArea'
import { NucleusRates, NucleusVault } from '@/types/nucleus'

const bodyMediumCSS = css`
  color: var(--Swell-White-50, #fff);
  /* Body/medium */
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 160%; /* 25.6px */
  letter-spacing: -0.48px;
`

const TOKEN_LOGO_SIZE = 22

export function NucleusClaimBreakdown({
  activeWithdrawal,
  vaultState,
  vault,
  rates,
}: {
  activeWithdrawal: INucleusActiveWithdrawal | undefined
  vaultState: NucleusVaultStateWithdraw | undefined
  vault: NucleusVault
  rates: NucleusRates | undefined
}) {
  const { baseAsset, vaultToken, withdrawAssets } = vault

  const is700Up = useMediaQuery('(min-width:700px)')
  const is900Up = useMediaQuery('(min-width:900px)')
  const is1100Up = useMediaQuery('(min-width:1100px)')

  if (!activeWithdrawal || activeWithdrawal?.status === 'NoActiveWithdrawal') {
    return null
  }

  const claimSummary = makeNucleusClaimSummary({
    activeWithdrawal,
    vaultToken,
    withdrawAssets,
    baseAsset,
  })

  let wantAsset: Token | undefined
  for (const asset of withdrawAssets) {
    if (asset.address === activeWithdrawal.wantToken) {
      wantAsset = asset
      break
    }
  }

  const withdrawalsSummary = makeNucleusVaultWithdrawalsSummary({
    vaultState,
    vault,
    wantAsset,
    requestWithdrawArguments: undefined,
    approveVaultTokenForAtomicQueueArguments: undefined,
    rates,
  })

  const withdrawingFrag = (
    <>
      <ColoredHeadingTypography>Withdrawing</ColoredHeadingTypography>
      <span>
        {claimSummary.withdrawingLogoURI && (
          <img
            src={claimSummary.withdrawingLogoURI}
            alt={claimSummary.withdrawingSymbol}
            width={TOKEN_LOGO_SIZE}
            height={TOKEN_LOGO_SIZE}
          />
        )}
        {claimSummary.withdrawing}
      </span>
    </>
  )

  const receivingFrag = (
    <>
      <ColoredHeadingTypography>Receiving</ColoredHeadingTypography>
      <span>
        {claimSummary.receivingLogoURI && (
          <img
            src={claimSummary.receivingLogoURI}
            alt={claimSummary.receivingSymbol}
            width={TOKEN_LOGO_SIZE}
            height={TOKEN_LOGO_SIZE}
          />
        )}
        {claimSummary.receiving}
      </span>
    </>
  )

  const processingTimeFrag = (
    <>
      <ColoredHeadingTypography>Processing Time</ColoredHeadingTypography>
      <AsyncDiv loading={!withdrawalsSummary.processingTime}>
        {() => withdrawalsSummary.processingTime}
      </AsyncDiv>
    </>
  )

  const targetTokenPriceFrag = (
    <>
      <ColoredHeadingTypography>Target token price</ColoredHeadingTypography>
      <AsyncDiv loading={!claimSummary.targetTokenPrice}>
        {() => claimSummary.targetTokenPrice}
      </AsyncDiv>
    </>
  )

  const arrangement2Col = [
    withdrawingFrag,
    receivingFrag,
    targetTokenPriceFrag,
    processingTimeFrag,
  ]

  const _arrangement4Col = [
    [withdrawingFrag, targetTokenPriceFrag],
    [receivingFrag, processingTimeFrag],
  ]
  const arrangement4Col: typeof arrangement2Col = []
  arrangement4Col.push(_arrangement4Col[0][0])
  arrangement4Col.push(_arrangement4Col[0][1])
  arrangement4Col.push(_arrangement4Col[1][0])
  arrangement4Col.push(_arrangement4Col[1][1])

  let numCols: 2 | 4 = 2
  if (is1100Up) {
    numCols = 4
  } else if (is900Up) {
    numCols = 2
  } else if (is700Up) {
    numCols = 4
  }

  let arrangement: typeof arrangement2Col
  if (numCols === 2) {
    arrangement = arrangement2Col
  } else {
    arrangement = arrangement4Col
  }

  return (
    <Layout
      direction="column"
      align="flex-start"
      gap="12"
      justify="center"
      maxWidth="586px"
    >
      <span className="bm left">Summary</span>
      <ScrollableYArea containerType="flex">
        <Grid>{arrangement}</Grid>
      </ScrollableYArea>
    </Layout>
  )
}

const Layout = styled(FlexRow)`
  align-self: stretch;
  color: var(--Swell-White-50, #fff);
  /* Body/medium */
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 160%; /* 25.6px */
  letter-spacing: -0.48px;

  span {
    display: flex;
    align-items: center;

    img {
      margin-right: 8px;
    }
  }

  .bm {
    ${bodyMediumCSS}
  }
  .left {
    align-self: start;
  }

  /* padding-right: 32px; */

  [aria-busy='false'] {
    min-width: unset;
  }

  ${ColoredHeadingTypography} {
    font-size: 12px;
    text-align: right;
    justify-self: end;

    ${({ theme }) => theme.breakpoints.up('sm')} {
      white-space: nowrap;
    }
  }
`

const Grid = styled.div`
  width: max-content;
  display: flex;
  flex-flow: column nowrap;
  gap: 12px;

  @media (min-width: 440px) {
    flex-flow: unset;
    gap: unset;

    margin: 0 auto;
    display: grid;
    grid-template-columns: auto max-content;
    grid-column-gap: 26px;
    grid-row-gap: 12px;
    align-items: center;
  }

  @media (min-width: 700px) {
    grid-template-columns: auto max-content auto max-content;
  }

  @media (min-width: 900px) {
    grid-template-columns: auto max-content;
  }

  @media (min-width: 1100px) {
    grid-template-columns: auto max-content auto max-content;
  }
`
