import React from 'react'
import styled, { createGlobalStyle } from 'styled-components'
import slippingFrog from '@/assets/images/slipping-frog.png'
import { ThemeData } from '@/swell-ui/theme/branding'
import { Button } from '@/swell-ui/Button'

const DISCORD_URL = 'https://discord.com/invite/swellnetworkdao'

const GlobalStyle = createGlobalStyle`
#root {
  position: relative;
}

html, body {
  overflow: hidden;
  color: #fff;
  font-family: "Inter", sans-serif !important;
  a {
    color: #A4ABF1;

    &:hover {
      color: #2F43EC;
    }
  }

  body {
    padding: 0;
    margin: 0;
  }

  h1 {
    color: var(--Swell-White-50, #FFF);
    /* Headlines/H4 */
    font-family: Inter;
    font-size: 40px;
    font-style: normal;
    font-weight: 600;
    line-height: 120%; /* 48px */
    letter-spacing: -1.2px;
  }

  button {

    margin-top: 32px;
    
    width: 220px;
    height: 46px;
    align-items: center;
    border-radius: 600px;
    background: var(--Swell-Lightblue-100, #2F43EC);
    display: flex;
    padding: 8px 16px;
    justify-content: center;
    align-items: center;
    gap: 8px;
    flex: 0 0 auto;
    align-self: center;
    color: var(--Swell-White-50, #FFF);
    text-align: center;
    /* Body/medium bold */
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 160%; /* 25.6px */
    border: none;
    outline: none;
    cursor: pointer;
  }


  @media (max-width: 768px) {
    img {
      width: 320px;
      height: 248px;
    }

    h1 {
      font-size: 26px;
    }
  }

  text-align: center;
}
`

export function ErrorPage({
  onClickForceReload,
}: {
  onClickForceReload: () => void
}) {
  return (
    <>
      <GlobalStyle />
      <Layout>
        <div>
          <img
            src={slippingFrog}
            alt="Slipping frog"
            width="416"
            height="323"
          />
          <h1>Uh oh, looks like something went wrong!</h1>
          <p>
            Don't worry our team is working on it, please come back and try
            again a little later or contact us on{' '}
            <a href={DISCORD_URL} target="_blank" rel="noopener noreferrer">
              Discord
            </a>
            .
          </p>
          <button onClick={onClickForceReload}>Reload page</button>
        </div>
      </Layout>
    </>
  )
}

const Layout = styled.div`
  width: 100vw;
  height: 100vh;
  position: relative;
  overflow: hidden;

  min-height: 100vh;
  max-width: 100%;

  background: ${ThemeData.Swell.SwellOceanBlue150};

  max-width: 100%;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  > div {
    margin: auto auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding-left: 0px;
    padding-right: 0px;
    z-index: 10;

    a[role='button'] {
      cursor: pointer;
    }
    > div:last-child {
      z-index: 11;
    }
    padding: 12px;
  }
`
