import { Token } from '@/types/tokens'
import { useDaoApiImpl } from './api'
import { SwellDaoContext } from './context'
import useChainDetection from '@/hooks/useChainDetection'
import {
  TOKEN_LIST_RSWELL,
  TOKEN_LIST_RSWELL_SEPOLIA,
  TOKEN_LIST_SWELL,
  TOKEN_LIST_SWELL_SEPOLIA,
} from '@/constants/tokens'
import { SupportedChainId } from '@uniswap/sdk-core'
import { MerkleDrop } from '@/types/merkle'
import {
  MERKLE_DROP_SWELL_AIRDROP_MAINNET,
  MERKLE_DROP_SWELL_AIRDROP_SEPOLIA,
} from '@/constants/merkleDrops'

export function useSwellDaoApiImpl(): SwellDaoContext {
  const { isTestnetChain, deploymentChainId, chainId, allowTestnet } =
    useChainDetection()

  let daoToken: Token | undefined
  let restakedDaoToken: Token | undefined
  let merkleDropAirdrop: MerkleDrop | undefined
  if (chainId === deploymentChainId) {
    if (chainId === SupportedChainId.MAINNET) {
      daoToken = TOKEN_LIST_SWELL
      restakedDaoToken = TOKEN_LIST_RSWELL
      merkleDropAirdrop = MERKLE_DROP_SWELL_AIRDROP_MAINNET
    } else {
      console.error('Invalid deployment chain id for Swell DAO', chainId)
    }
  }

  if (allowTestnet && isTestnetChain) {
    if (chainId === SupportedChainId.SEPOLIA) {
      daoToken = TOKEN_LIST_SWELL_SEPOLIA
      restakedDaoToken = TOKEN_LIST_RSWELL_SEPOLIA
      merkleDropAirdrop = MERKLE_DROP_SWELL_AIRDROP_SEPOLIA
    } else {
      console.error('Invalid testnet chain id for Swell DAO', chainId)
    }
  }

  let valid = true
  if (!daoToken || !merkleDropAirdrop || !restakedDaoToken) {
    valid = false
    console.error('Invalid deployment chain id for Swell DAO', chainId)
    daoToken = TOKEN_LIST_SWELL
    restakedDaoToken = TOKEN_LIST_RSWELL
    merkleDropAirdrop = MERKLE_DROP_SWELL_AIRDROP_MAINNET
  }

  const api = useDaoApiImpl({
    daoToken,
    merkleDropAirdrop,
    restakedDaoToken,
  })

  if (!valid) {
    return {
      ...api,
      write: {
        ...api.write,
        claimAirdrop: async () => {
          throw new Error('invalid chainId')
        },
      },
    }
  }

  return api
}
