import React from 'react'
import styled from 'styled-components'
import { useUserOperator } from '@/state/restakingUserOperator/hooks'
import { Grid } from '@swell-ui/Grid'
import { RestakingFeesEarned } from '@/components/FeesEarned'
import { PageTitle } from '@/components/PageTitleOld'
import { RestakingRecentTransactions } from '@/components/RecentTransactions'
import { RestakingDepositManager } from '@/components/SwellDepositManager'
import { useRestakingUserOperatorStatusWithWarning } from '@/hooks/useUserOperatorStatus'

const TitlePositioner = styled.div`
  position: relative;
`

const SectionGrid = styled(Grid)`
  max-width: 100% !important;
`

function Dashboard() {
  const userOperator = useUserOperator()

  // this hook produces a side effect which shows a warning banner if the user is not an operator
  useRestakingUserOperatorStatusWithWarning()

  let pageTitlePrefix = ''

  if (userOperator.data) {
    const {
      operator: { name },
    } = userOperator.data
    pageTitlePrefix = `${name} `
  }

  return (
    <>
      <TitlePositioner>
        <PageTitle text={`${pageTitlePrefix}Restaking dashboard`} />
      </TitlePositioner>
      <Grid container spacing={2}>
        <Grid item xs={12} lg={4}>
          <RestakingDepositManager />
        </Grid>
        <Grid item xs={12} lg={8}>
          <Grid container direction="column" spacing={2}>
            <SectionGrid item>
              <RestakingFeesEarned />
            </SectionGrid>
            <SectionGrid item>
              <RestakingRecentTransactions />
            </SectionGrid>
          </Grid>
        </Grid>
      </Grid>
    </>
  )
}

export { Dashboard }
