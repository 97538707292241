import { Wavedrop, WavedropPhase } from './types'
import { StaticWavedrop } from '@/services/StaticWavedrop/types'

export function reconstructWavedropsFromStaticData(
  staticWd: StaticWavedrop
): Wavedrop {
  const wd: Wavedrop = {
    phase: WavedropPhase.claims,
    waveNumber: staticWd.wavedropNumber,
    blackPearls: staticWd.weightedPearls,
    multiplier: staticWd.bonus,
    daoTokenAmount: staticWd.swellAmount,
    cumulativeAmount: staticWd.cumulativeAmount,
    merkleProof: staticWd.proofsHex,
    merkleRoot: staticWd.merkleRoot,
    swellAmount: staticWd.swellAmount,
  }
  return wd
}
