import React from 'react'
import styled from 'styled-components/macro'
import { ArrowUpRightIcon } from '@swell-ui/icons/ArrowUpRightIcon'
import { FlexRow } from '@swell-ui/FlexRow'
import { Typography } from '@/swell-ui/Typography'
import { useChainInfo, useDeploymentSetConfig } from '@/state/deployments/hooks'
import { getChainInfo } from '@/constants/chainInfo'
import { useBtcLrtVault } from '@/state/yearnVault/hooks'
import { useNucleusGlobalContracts } from '@/state/nucleusGlobal/hooks'
import { Token } from '@/types/tokens'

interface ContractLinkProps {
  address: string
  explorer: string
  label: string
}

const StyledArrowUpRightIcon = styled(ArrowUpRightIcon)`
  position: relative;
  top: -2px;
  width: 9px;
  height: 9px;
  margin-left: 12px;

  path:first-child {
    fill: ${({ theme }) => theme.footer.link.color};
  }

  path:last-child {
    stroke: ${({ theme }) => theme.footer.link.color};
  }
`

const LinkFlexRow = styled(FlexRow)`
  ${({ theme }) => theme.breakpoints.down('sm')} {
    flex-direction: column;
    align-items: flex-start;
  }

  a {
    color: ${({ theme }) => theme.footer.link.color};
  }

  a:hover {
    ${StyledArrowUpRightIcon} {
      path:first-child {
        fill: #2f43ec;
      }

      path:last-child {
        stroke: #2f43ec;
      }
    }
  }
`

function ContractLink(props: ContractLinkProps) {
  return (
    <div>
      <Typography variant="body" size="xsmall">
        <a
          href={`${props.explorer}address/${props.address}`}
          target="_blank"
          rel="noreferrer"
        >
          {props.label}
          <StyledArrowUpRightIcon />
        </a>
      </Typography>
    </div>
  )
}

function OperatorFooterLinks() {
  const { addresses, chainId } = useDeploymentSetConfig()
  const chainInfo = getChainInfo(chainId)

  return (
    <LinkFlexRow width="auto">
      <ContractLink
        address={addresses.swETH}
        explorer={chainInfo.explorer}
        label="swETH contract v1.0"
      />
      <ContractLink
        address={addresses.nodeOperatorRegistry}
        explorer={chainInfo.explorer}
        label="Node operator registry v1.0"
      />
    </LinkFlexRow>
  )
}

function StakingFooterLinks() {
  const { addresses, chainId } = useDeploymentSetConfig()
  const chainInfo = getChainInfo(chainId)

  return (
    <LinkFlexRow width="auto">
      <ContractLink
        address={addresses.swETH}
        explorer={chainInfo.explorer}
        label="swETH contract v1.0"
      />
      <ContractLink
        address={addresses.depositManager}
        explorer={chainInfo.explorer}
        label="Deposit manager contract v1.0"
      />
    </LinkFlexRow>
  )
}

function RestakingOperatorFooterLinks() {
  const { addresses, chainId } = useDeploymentSetConfig()
  const chainInfo = getChainInfo(chainId)

  return (
    <LinkFlexRow width="auto">
      <ContractLink
        address={addresses.rswETH}
        explorer={chainInfo.explorer}
        label="rswETH contract v1.0"
      />
      <ContractLink
        address={addresses.restakingNodeOperatorRegistry}
        explorer={chainInfo.explorer}
        label="rswETH node registry contract v1.0"
      />
    </LinkFlexRow>
  )
}

function RestakingFooterLinks() {
  const { addresses, chainId } = useDeploymentSetConfig()
  const chainInfo = getChainInfo(chainId)

  return (
    <LinkFlexRow width="auto">
      <ContractLink
        address={addresses.rswETH}
        explorer={chainInfo.explorer}
        label="rswETH contract v1.0"
      />
      <ContractLink
        address={addresses.restakingDepositManager}
        explorer={chainInfo.explorer}
        label="rswETH deposit manager contract v1.0"
      />
    </LinkFlexRow>
  )
}

function PreDepositFooterLinks() {
  const { addresses, chainId } = useDeploymentSetConfig()
  const chainInfo = getChainInfo(chainId)

  return (
    <LinkFlexRow width="auto">
      <ContractLink
        address={addresses.preDepositStaking}
        explorer={chainInfo.explorer}
        label="Swell L2 deposit contract v1.0"
      />
    </LinkFlexRow>
  )
}

export function VaultFooterLinks() {
  const { vaultToken } = useBtcLrtVault()
  const chainInfo = useChainInfo()

  return (
    <LinkFlexRow width="auto">
      <ContractLink
        address={vaultToken.address}
        explorer={chainInfo.explorer}
        label={`Symbiotic BTC vault contract v1.0`}
      />
    </LinkFlexRow>
  )
}

export function EarnVaultFooterLinks({ vaultToken }: { vaultToken: Token }) {
  const atomicQueue = useNucleusGlobalContracts().atomicQueueAddress
  const chainInfo = useChainInfo()

  return (
    <LinkFlexRow width="auto">
      <ContractLink
        address={vaultToken.address}
        explorer={chainInfo.explorer}
        label={`${vaultToken.symbol} vault contract`}
      />
      <ContractLink
        address={atomicQueue}
        explorer={chainInfo.explorer}
        label={`Atomic queue contract`}
      />
    </LinkFlexRow>
  )
}

export {
  OperatorFooterLinks,
  StakingFooterLinks,
  RestakingOperatorFooterLinks,
  RestakingFooterLinks,
  PreDepositFooterLinks,
}
