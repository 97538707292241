import { NucleusVault } from '@/types/nucleus'
import { useDeploymentSetConfig } from './useDeploymentSetConfig'
import {
  NUCLEUS_ATOMIC_QUEUE_ADDRESS,
  NUCLEUS_VAULT_EARN_BTC,
  NUCLEUS_VAULT_EARN_ETH,
} from '@/constants/nucleus'
import useChainDetection from '@/hooks/useChainDetection'

export function useNucleusVaultBackendSettings() {
  const nucleusBackendURL = useDeploymentSetConfig().v3BackendLrtUrl
  const pointsURL = useDeploymentSetConfig().v3BackendLrtUrl
  // const nucleusBackendURL = 'http://localhost:8081'
  // const pointsURL = 'http://localhost:8081'
  return { nucleusBackendURL, pointsURL }
}

type EarnVaultDeploymentResult = {
  valid: boolean
  vault: NucleusVault
  atomicQueueAddress: string
}
export function useEarnVaultDeploymentBtc(): EarnVaultDeploymentResult {
  const { isWrongChainId } = useChainDetection()
  const valid = !isWrongChainId

  return {
    atomicQueueAddress: NUCLEUS_ATOMIC_QUEUE_ADDRESS,
    vault: NUCLEUS_VAULT_EARN_BTC,
    valid,
  }
}
export function useEarnVaultDeploymentEth(): EarnVaultDeploymentResult {
  const { isWrongChainId } = useChainDetection()
  const valid = !isWrongChainId

  return {
    atomicQueueAddress: NUCLEUS_ATOMIC_QUEUE_ADDRESS,
    vault: NUCLEUS_VAULT_EARN_ETH,
    valid,
  }
}
