import { NucleusBalances, NucleusVaultPoints } from '@/state/nucleusVault/types'
import { Box } from '@/swell-ui/Box'
import styled from 'styled-components'
import { ColoredHeadingTypography } from '@/swell-ui/Typography/typographyPresets'
import { FlexRow } from '@/swell-ui/FlexRow'
import { AsyncDiv } from '@/swell-ui/AsyncDiv'
import { useSwellWeb3 } from '@/swell-web3/core'
import { Token } from '@/types/tokens'
import { useMediaQuery } from '@mui/material'
import { Divider } from '@/swell-ui/Divider'
import { NucleusPointsCampaigns } from './types'
import { makeNucleusHoldingsSummary } from './nucleusFormatting'
import { ReactNode } from 'react'
import { Tooltip } from '@/swell-ui/Tooltip'
import { InfoIcon } from '@/swell-ui/icons/InfoIcon'
import {
  TOKEN_LIST_FBTC,
  TOKEN_LIST_LBTC,
  TOKEN_LIST_LORENZO_STBTC,
  TOKEN_LIST_PUMPBTC,
  TOKEN_LIST_SOLV_BTC,
} from '@/constants/tokens'
import babylonLogo from '@/assets/images/babylon_logo-160x160.png'

const ICON_SIZE = 32

export function NucleusHoldings({
  balances,
  points,
  vaultToken,
  pointsCampaigns,
}: {
  pointsCampaigns: NucleusPointsCampaigns
  balances: NucleusBalances | undefined
  points: NucleusVaultPoints | undefined
  vaultToken: Token
}) {
  const {
    blackPearlsCampaign,
    ecosystemPtsCampaign,
    nucleusPtsCampaign,
    btcPartnerPtsCampaign,
  } = pointsCampaigns

  const { account } = useSwellWeb3()
  const is1000Up = useMediaQuery('(min-width:1000px)')
  const is602Up = useMediaQuery('(min-width:602px)')

  const summary = makeNucleusHoldingsSummary({
    account,
    balances,
    points,
    vaultToken,
  })

  const vaultTokenSection = (
    <FlexRow justify={is1000Up ? 'space-around' : 'start'}>
      <Holding
        title="Current Holdings"
        value={summary.vaultToken}
        icon={vaultToken.logoURI}
      />
    </FlexRow>
  )

  const pointsNodes: ReactNode[] = []
  if (ecosystemPtsCampaign) {
    pointsNodes.push(
      <Holding
        key="ecosystemPts"
        title={`${ecosystemPtsCampaign.name} (${ecosystemPtsCampaign.multiplier}x)`}
        value={summary.ecosystemPts}
        icon={ecosystemPtsCampaign.logoURI}
      />
    )
  }
  if (btcPartnerPtsCampaign) {
    pointsNodes.push(
      <Holding
        key="btcPartnerPts"
        title={`${btcPartnerPtsCampaign.name} (1x)`}
        value={<BtcPartnerPointsLogos />}
        tooltip={
          <ul style={{ padding: 0, margin: '0 0 0 12px' }}>
            <li>Babylon Points</li>
            <li>PumpBTC Points</li>
            <li>FBTC Sparks</li>
            <li>Solv Points</li>
            <li>Lorenzo Points (coming soon)</li>
          </ul>
        }
      />
    )
  }
  pointsNodes.push(
    <Holding
      key="blackPearls"
      title={`${blackPearlsCampaign.name} (${blackPearlsCampaign.multiplier}x)`}
      value={summary.blackPearls}
      icon={blackPearlsCampaign.logoURI}
    />
  )
  pointsNodes.push(
    <Holding
      key="nucleusPts"
      title={`${nucleusPtsCampaign.name} (${nucleusPtsCampaign.multiplier}x)`}
      value={summary.nucleusPts ? 'TBD' : ''}
      icon={nucleusPtsCampaign.logoURI}
    />
  )

  const pointsSection = (
    <FlexRow
      justify={is1000Up ? 'space-around' : 'space-between'}
      gap="16"
      direction={is602Up ? 'row' : 'column'}
    >
      {pointsNodes}
    </FlexRow>
  )

  let content = (
    <FlexRow justify="space-between" align="stretch">
      {vaultTokenSection}
      <VDivider />
      {pointsSection}
    </FlexRow>
  )
  if (!is1000Up) {
    content = (
      <FlexRow direction="column" gap="24">
        {vaultTokenSection}
        <HDivider />
        {pointsSection}
      </FlexRow>
    )
  }

  return <StyledBox padding="24px">{content}</StyledBox>
}

const HDivider = styled(Divider)`
  background-color: ${({ theme }) => theme.divider.lightColor};
`

const VDivider = styled.div`
  flex: 1 1 auto;
  width: 100%;

  position: relative;
  :before {
    content: '';
    position: absolute;
    top: 0;
    left: 50%;

    width: 1px;
    height: 100%;
    transform: translateX(-50%);
    background-color: ${({ theme }) => theme.divider.lightColor};
  }
`

const StyledBox = styled(Box)``

function Holding({
  icon,
  title,
  value,
  tooltip,
}: {
  title: string
  value: ReactNode | undefined
  icon?: string
  tooltip?: ReactNode
}) {
  const is1000Up = useMediaQuery('(min-width:1000px)')
  const is602Up = useMediaQuery('(min-width:602px)')
  const is470Up = useMediaQuery('(min-width:470px)')

  const titleContent = (
    <>
      {title}
      {tooltip && (
        <>
          {' '}
          <Tooltip title={tooltip} arrow>
            <InfoIcon />
          </Tooltip>
        </>
      )}
    </>
  )

  if (is602Up) {
    return (
      <HoldingLayout
        gap="12"
        width={is1000Up ? '218px' : ''}
        direction="column"
        align={is1000Up ? 'center' : 'start'}
      >
        <ColoredHeadingTypography nobreak={is1000Up}>
          {titleContent}
        </ColoredHeadingTypography>
        <FlexRow gap="12" justify={is1000Up ? 'center' : 'start'}>
          <AsyncDiv loading={!value}>
            {() => (
              <FlexRow gap="12" justify={is1000Up ? 'center' : 'start'}>
                {icon && (
                  <img src={icon} width={ICON_SIZE} height={ICON_SIZE} />
                )}
                <span className="value">{value}</span>
              </FlexRow>
            )}
          </AsyncDiv>
        </FlexRow>
      </HoldingLayout>
    )
  }

  return (
    <HoldingLayout gap="6">
      <ColoredHeadingTypography>{titleContent}</ColoredHeadingTypography>
      <FlexRow gap="12" justify={is470Up ? 'end' : 'start'}>
        <AsyncDiv loading={!value}>
          {() => (
            <FlexRow gap="12">
              {icon && <img src={icon} width={ICON_SIZE} height={ICON_SIZE} />}
              <span className="value">{value}</span>
            </FlexRow>
          )}
        </AsyncDiv>
      </FlexRow>
    </HoldingLayout>
  )
}

const HoldingLayout = styled(FlexRow)`
  flex-wrap: wrap;
  @media (min-width: 470px) {
    flex-wrap: nowrap;
    ${ColoredHeadingTypography} {
      flex: 1 0 auto;
    }
  }

  ${ColoredHeadingTypography} {
    svg {
      position: relative;
      top: 1px;

      path {
        stroke: currentColor;
      }
    }
  }

  span.value {
    color: var(--Swell-White-50, #fff);
    /* Headlines/H6 */
    font-family: Inter;
    font-size: 26px;
    font-style: normal;
    font-weight: 600;
    line-height: 130%; /* 33.8px */
    letter-spacing: -0.78px;
  }

  [aria-busy='false'] {
    min-width: unset;
    width: min-content;
  }

  [aria-busy='true'] {
    height: 33.8px;
  }
`

function BtcPartnerPointsLogos() {
  const size = 22

  return (
    <FlexRow height="33.8px" gap="12">
      <img src={TOKEN_LIST_PUMPBTC.logoURI} width={size} height={size} />
      <img src={TOKEN_LIST_LORENZO_STBTC.logoURI} width={size} height={size} />
      <img src={TOKEN_LIST_FBTC.logoURI} width={size} height={size} />
      <img src={TOKEN_LIST_SOLV_BTC.logoURI} width={size} height={size} />
      <img src={babylonLogo} width={size} height={size} />
    </FlexRow>
  )
}
