import React from 'react'
import { title1CSS } from '../styles/common'
import styled from 'styled-components'
import { ButtonAnchor } from '../AirdropButtons'
import { FlexRow } from '@/swell-ui/FlexRow'

const whitePearlUrl =
  'https://uploads-ssl.webflow.com/63d9b0d075e8161e9281ebdd/66ac75489816f0a5806aaeed_White%20Pearl.svg'
const PEARL_SIZE = 20

// TODO
const learnMoreHref = ''

export function BelowThreshold({ pearlsStr }: { pearlsStr: string }) {
  return (
    <Layout
      direction="column"
      margin="0 auto"
      maxWidth="575px"
      gap="24"
      align="center"
    >
      <h1>You are not eligible... yet</h1>
      <p className="major">
        You earned{' '}
        <img
          className="pearl"
          src={whitePearlUrl}
          width={PEARL_SIZE}
          height={PEARL_SIZE}
        />{' '}
        {pearlsStr} White Pearls
      </p>
      <p>
        Your pearl count of {pearlsStr} is below the minimum claimable threshold
        and it is likely to cost you more gas to claim than its current value in
        $SWELL.
      </p>
      <p>
        However you will be able to claim your SWELL tokens on Swell L2 almost
        gas free on the launch of Swell L2.
      </p>
      <div style={{ height: '24px' }} />
      <ButtonAnchor href={learnMoreHref}>Learn more</ButtonAnchor>
    </Layout>
  )
}

const Layout = styled(FlexRow)`
  h1 {
    ${title1CSS}
    margin: 0;
    text-align: center;
    width: 100%;
  }

  p,
  ul {
    margin: 0;
  }

  a,
  button {
    align-self: center;
    min-width: 186px;
  }

  p.major {
    color: var(--Swell-White-50, #fff);
    /* Body/large */
    font-family: Inter;
    font-size: 24px;
    font-style: normal;
    font-weight: 500;
    line-height: 160%; /* 38.4px */
    letter-spacing: -0.72px;
  }

  p {
    text-align: center;
  }

  img.pearl {
    margin-left: 2px;
    transform: translateY(2px);
  }
`
