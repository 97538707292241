import { AirdropOffchainState } from '@/state/dao/types'

export const AIRDROP_VESTING_DATES: AirdropOffchainState = {
  tgeUnix: 1730937600, // 1730937600000 ~= TGE (Monday, 7th November 2024 00:00:00)
  optionOneUnlockTimes: {
    firstUnlockUnix: 1733529600, // TGE + 30 days: Saturday, 7 December 2024 00:00:00
    secondUnlockUnix: 1736208000, // TGE + 60 days: Tuesday, 7 January 2025 00:00:00
    thirdUnlockUnix: 1738886400, // TGE + 90 days: Tuesday, 7 February 2025 00:00:00
  },
  optionTwoUnlockTimes: {
    firstUnlockUnix: 1738886400, // TGE + 90 days:  Tuesday, 7 February 2025 00:00:00
    secondUnlockUnix: 1746576000, // TGE + 180 days: Wednesday, 7 May 2025 00:00:00
  },
}
