import { FlexRow } from '@/swell-ui/FlexRow'
import React, { ReactNode, useEffect, useRef, useState } from 'react'
import styled, { css, keyframes, useTheme } from 'styled-components'
import { title1CSS } from '../styles/common'
import swellL2Logo from '@/assets/images/swell-l2-logo-56x56.png'
import swellEarnBtcLogo from '@/assets/images/swell-earn-btc-120x120.png'
import swellEarnEthLogo from '@/assets/images/swell-earn-eth-120x120.png'
import { useMediaQuery } from '@mui/material'
import { v4 as uuid } from 'uuid'
import { usePageDimensions } from '@/hooks/usePageDimensions'
import swbtcLogo from '@/assets/images/swbtc-logo-64x64.png'

const swethLogo =
  'https://uploads-ssl.webflow.com/63d9b0d075e8161e9281ebdd/65b1bb090e5ae9484a6d6c57_swETH.svg'
const rswethLogo =
  'https://uploads-ssl.webflow.com/63d9b0d075e8161e9281ebdd/65a18d05b8a1d66b3deb64e2_rswETH.svg'

const MAX_AREA_WIDTH_PX = 438
const MIN_AREA_HEIGHT_MOBILE_PX = 150
const MAX_AREA_HEIGHT_DESKTOP_PX = 253
const ICON_SIZE_PX = 40

const LOREM_IPSUM_HTML = `
<p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s.</p>
<p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s.</p>
`
const SWETH_HTML = `
<p>Swell burst into the Ethereum ecosystem with liquid staking token swETH.</p>
`
const RSWETH_HTML = `
<p>As momentum grew, Swell introduced rswETH to offer liquid access to restaking rewards on EigenLayer.</p>
`
const SWELL_L2_HTML = `
<p>Swell revealed its vision for the Restaking Yield Layer, pushing TVL beyond $1 billion as stakers looked towards new ecosystem rewards.</p>
`
const SWBTC_HTML = `
<p>Moving into BTCFi, Swell introduced a bitcoin liquid restaking token to capture rewards from Eigenlayer, Symbiotic, and Karak.</p>
`
const EARN_VAULTS_HTML = `
<p>The introduction of Swell Earn Vaults enabled stakers to earn Swell L2 points and DeFi yield with one deposit.</p>
`

// const SWETH_HTML = LOREM_IPSUM_HTML
// const RSWETH_HTML = LOREM_IPSUM_HTML
// const SWELL_L2_HTML = LOREM_IPSUM_HTML
// const SWBTC_HTML = LOREM_IPSUM_HTML
// const EARN_VAULTS_HTML = LOREM_IPSUM_HTML

// https://www.figma.com/design/1trLPK52KCe6Nko8e2NCbU/Swell-Network-Design?node-id=10594-19163&node-type=text&m=dev
export function AirdropTheJourney() {
  const desktop = useMediaQuery(`(min-width: 900px)`)

  const nodeSwETH = (
    <VisArea
      title="swETH Liquid Staking"
      icons={[{ src: swethLogo, alt: 'swETH', round: true }]}
      html={SWETH_HTML}
    />
  )
  const nodeRswETH = (
    <VisArea
      title="rswETH Liquid Restaking"
      icons={[{ src: rswethLogo, alt: 'rswETH', round: true }]}
      html={RSWETH_HTML}
    />
  )
  const nodeSwellL2 = (
    <VisArea
      title="Swell L2 Pre Launch Deposit"
      icons={[{ src: swellL2Logo, alt: 'Swell L2', round: false }]}
      html={SWELL_L2_HTML}
    />
  )
  const nodeSwBTC = (
    <VisArea
      title="swBTC Liquid Restaking"
      icons={[{ src: swbtcLogo, alt: 'swBTC', round: true }]}
      html={SWBTC_HTML}
    />
  )
  const nodeEarnVaults = (
    <VisArea
      title="Swell Earn Vaults"
      icons={[
        { src: swellEarnEthLogo, alt: 'earnETH', round: true },
        { src: swellEarnBtcLogo, alt: 'earnBTC', round: true },
      ]}
      html={EARN_VAULTS_HTML}
    />
  )

  let vis = (
    <VisMobile
      {...{ nodeSwETH, nodeRswETH, nodeSwellL2, nodeSwBTC, nodeEarnVaults }}
    />
  )
  if (desktop) {
    vis = (
      <VisDesktop
        {...{ nodeSwETH, nodeRswETH, nodeSwellL2, nodeSwBTC, nodeEarnVaults }}
      />
    )
  }

  return (
    <Layout
      direction="column"
      gap="24"
      align="center"
      maxWidth="1536px"
      margin="auto 0"
    >
      <h1>The Journey</h1>
      <p className="narrow-mob">
        With $1.5 Billion TVL from 150,000+ addresses, we've come a long way
        since launch.
      </p>
      <div className="spacer" />
      {vis}
    </Layout>
  )
}

const fadeIn = keyframes`
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
`

const fadeInCSS = css`
  opacity: 0;
  animation: ${fadeIn} 0.65s ease-out forwards;
  animation-delay: 0.15s;
`

const Layout = styled(FlexRow)`
  ${fadeInCSS}
  h1 {
    ${title1CSS}
    margin: 0;
    text-align: center;
  }
  span.heading {
    color: var(--Swell-White-50, #fff);
    /* Headlines/H7 */
    font-family: Inter;
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: 130%; /* 26px */
    letter-spacing: -0.6px;
  }
  .bodysmall {
    color: var(--Swell-White-125, #b0b0b0);
    /* Body/small */
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 160%; /* 22.4px */
    letter-spacing: -0.42px;
  }

  p {
    margin: 0;
  }

  p {
    text-align: center;
  }

  .narrow-mob {
    max-width: 460px;
  }
  @media (min-width: 600px) {
    max-width: unset;
  }

  .spacer {
    height: 86px;
  }
  @media (min-width: 900px) {
    .spacer {
      height: 16px;
    }
  }

  .custom {
    /* flex: 1 1 auto; */
    max-width: 100%;
    overflow: auto;

    > div {
      width: 100%;
      height: 100%;
    }

    ${({ theme }) =>
      theme.scrollbar &&
      css`
        padding-right: 24px;

        &::-webkit-scrollbar {
          width: ${theme.scrollbar.width};
        }

        &::-webkit-scrollbar-track {
          background: ${theme.scrollbar.trackBg};
          border-radius: ${theme.scrollbar.borderRadius};
        }

        &::-webkit-scrollbar-thumb {
          background: ${theme.scrollbar.thumbBg};
          border-radius: ${theme.scrollbar.borderRadius};
        }
      `}

    @media (min-width: 900px) {
      height: ${MAX_AREA_HEIGHT_DESKTOP_PX}px;
    }
  }

  .round {
    border-radius: 50%;
  }

  .h-div {
    height: 0px;
    position: relative;
    > svg {
      z-index: -1;
      position: absolute;
      top: 0;
      left: 50%;
      transform: translateX(-50%) scaleX(1.1);
    }

    @media (min-width: 1600px) {
      > svg {
        transform: translateX(-50%) scaleX(1.05);
      }
    }
  }
`

function VisDesktop({
  nodeEarnVaults,
  nodeRswETH,
  nodeSwBTC,
  nodeSwETH,
  nodeSwellL2,
}: {
  nodeSwETH: ReactNode
  nodeRswETH: ReactNode
  nodeSwellL2: ReactNode
  nodeSwBTC: ReactNode
  nodeEarnVaults: ReactNode
}) {
  // constrain height of top row to match bottom row
  const [maxWidthTopRow, setMaxWidthTopRow] = useState(0)
  const bottomElRef = useRef<HTMLDivElement>(null)

  useEffect(() => {
    let done = false

    function animate() {
      if (done) return
      if (!bottomElRef.current) return
      const rect = bottomElRef.current.getBoundingClientRect()
      setMaxWidthTopRow(rect.width)
      requestAnimationFrame(animate)
    }

    animate()

    return () => {
      done = true
    }
  }, [])

  return (
    <VisLayoutDesktop direction="column" gap="0" align="center">
      <FlexRow justify="center">
        <VerticalDivider dot="bottom" />
        <div
          style={{
            width: maxWidthTopRow ? `${maxWidthTopRow}px` : undefined,
            visibility: maxWidthTopRow ? 'visible' : 'hidden',
          }}
        >
          {nodeRswETH}
        </div>
        <VerticalDivider dot="bottom" />
        <div
          style={{
            width: maxWidthTopRow ? `${maxWidthTopRow}px` : undefined,
            visibility: maxWidthTopRow ? 'visible' : 'hidden',
          }}
        >
          {nodeSwBTC}
        </div>
      </FlexRow>
      <div className="h-div">
        <LargeHorizontalDivider />
      </div>
      <FlexRow justify="center">
        <VerticalDivider dot="top" />
        <div ref={bottomElRef}>{nodeSwETH}</div>
        <VerticalDivider dot="top" />
        <div>{nodeSwellL2}</div>
        <VerticalDivider dot="top" />
        <div>{nodeEarnVaults}</div>
      </FlexRow>
    </VisLayoutDesktop>
  )
}
const VisLayoutDesktop = styled(FlexRow)`
  flex: 0 0 auto;
`

function VisMobile({
  nodeEarnVaults,
  nodeRswETH,
  nodeSwBTC,
  nodeSwETH,
  nodeSwellL2,
}: {
  nodeSwETH: ReactNode
  nodeRswETH: ReactNode
  nodeSwellL2: ReactNode
  nodeSwBTC: ReactNode
  nodeEarnVaults: ReactNode
}) {
  return (
    <VisLayoutMobile
      direction="row"
      gap="0"
      align="start"
      padding="0 0 0 24px"
      height="auto"
    >
      <div className="div-positioner">
        <LargeVerticalDivider />
      </div>
      <FlexRow direction="column" gap="0" align="start">
        <HorizontalDivider dot="left" />
        <div>{nodeSwETH}</div>
        <HorizontalDivider dot="left" />
        <div>{nodeRswETH}</div>
        <HorizontalDivider dot="left" />
        <div>{nodeSwellL2}</div>
        <HorizontalDivider dot="left" />
        <div>{nodeSwBTC}</div>
        <HorizontalDivider dot="left" />
        <div>{nodeEarnVaults}</div>
      </FlexRow>
    </VisLayoutMobile>
  )
}

const VisLayoutMobile = styled(FlexRow)`
  flex: 1 100%;
  overflow: visible;

  position: relative;
  .div-positioner {
    z-index: -1;
    position: absolute;
    top: -80px;
    left: 24px;
    bottom: -80px;
    transform: translateX(-50%);
  }
  width: auto;
`

function VisArea({
  title,
  html,
  icons,
}: {
  title: string
  icons: { src: string; alt: string; round?: boolean }[]
  html: string
}) {
  return (
    <VisAreaLayout direction="column" gap="12" padding="24px">
      <FlexRow gap="6">
        {icons.map((icon) => {
          let className = 'icon'
          if (icon.round) {
            className += ' round'
          }
          return (
            <img
              key={icon.alt}
              src={icon.src}
              alt={icon.alt}
              className={className}
              width={ICON_SIZE_PX}
              height={ICON_SIZE_PX}
            />
          )
        })}
        <span className="heading">{title}</span>
      </FlexRow>
      <div className="bodysmall custom">
        <div>
          <div dangerouslySetInnerHTML={{ __html: html }} />
        </div>
      </div>
    </VisAreaLayout>
  )
}
const VisAreaLayout = styled(FlexRow)`
  flex: 1 100%;
  p {
    text-align: left;
  }
  max-width: ${MAX_AREA_WIDTH_PX}px;

  min-height: ${MIN_AREA_HEIGHT_MOBILE_PX}px;

  @media (min-width: 900px) {
    height: ${MAX_AREA_HEIGHT_DESKTOP_PX}px;
  }
`

function LargeHorizontalDivider() {
  const { pageWidth } = usePageDimensions()

  let lineWidth = 0
  if (pageWidth) {
    lineWidth = pageWidth - 150
  }

  const ID = useRef({
    shape: uuid(),
    gradient: uuid(),
  })

  return (
    <svg height="2" overflow="visible" width={lineWidth}>
      <defs>
        <rect id={ID.current.shape} x="0" y="0" width="100%" height="2" />
        <linearGradient id={ID.current.gradient} x1="0" y1="0" x2="100%" y2="0">
          <stop offset="0%" stopColor="#FFF" stopOpacity={0} />
          <stop offset="50%" stopColor="#FFF" stopOpacity={1} />
          <stop offset="100%" stopColor="#FFF" stopOpacity={0} />
        </linearGradient>
      </defs>
      <use
        href={`#${ID.current.shape}`}
        fill={`url(#${ID.current.gradient})`}
      />
    </svg>
  )
}
function LargeVerticalDivider() {
  const ID = useRef({
    shape: uuid(),
    gradient: uuid(),
  })

  return (
    <svg width="2" height="100%" overflow="visible">
      <defs>
        <rect id={ID.current.shape} x="0" y="0" width="2" height="100%" />
        <linearGradient id={ID.current.gradient} x1="0" y1="0" x2="0" y2="100%">
          <stop offset="0%" stopColor="#FFF" stopOpacity={0} />
          <stop offset="50%" stopColor="#FFF" stopOpacity={1} />
          <stop offset="100%" stopColor="#FFF" stopOpacity={0} />
        </linearGradient>
      </defs>
      <use
        href={`#${ID.current.shape}`}
        fill={`url(#${ID.current.gradient})`}
      />
    </svg>
  )
}

function VerticalDivider({ dot }: { dot: 'top' | 'bottom' }) {
  const ID = useRef({
    lineShape: uuid(),
    gradient: uuid(),
    dotShape: uuid(),
  })

  let gradientNodes = (
    <>
      <stop offset="0%" stopColor="#FFF" stopOpacity={0} />
      <stop offset="100%" stopColor="#FFF" stopOpacity={1} />
    </>
  )
  if (dot === 'top') {
    gradientNodes = (
      <>
        <stop offset="0%" stopColor="#FFF" stopOpacity={1} />
        <stop offset="100%" stopColor="#FFF" stopOpacity={0} />
      </>
    )
  }

  return (
    <svg width="12" height={MAX_AREA_HEIGHT_DESKTOP_PX} overflow="visible">
      <defs>
        <rect id={ID.current.lineShape} x="5.5" y="0" width="1" height="100%" />
        <circle
          id={ID.current.dotShape}
          cx="6"
          cy={dot === 'top' ? '0' : '100%'}
          r="6"
        />
        <linearGradient id={ID.current.gradient} x1="0" y1="0" x2="0" y2="100%">
          {gradientNodes}
        </linearGradient>
      </defs>
      <use
        href={`#${ID.current.lineShape}`}
        fill={`url(#${ID.current.gradient})`}
      />
      <use
        href={`#${ID.current.dotShape}`}
        fill="#010508"
        stroke="#FFF"
        strokeWidth="1"
      />
    </svg>
  )
}

function HorizontalDivider({ dot }: { dot: 'left' | 'right' }) {
  const ID = useRef({
    lineShape: uuid(),
    gradient: uuid(),
    dotShape: uuid(),
  })

  let gradientNodes = (
    <>
      <stop offset="0%" stopColor="#FFF" stopOpacity={0} />
      <stop offset="100%" stopColor="#FFF" stopOpacity={1} />
    </>
  )
  if (dot === 'left') {
    gradientNodes = (
      <>
        <stop offset="0%" stopColor="#FFF" stopOpacity={1} />
        <stop offset="100%" stopColor="#FFF" stopOpacity={0} />
      </>
    )
  }

  return (
    <svg width={'100%'} height="12" overflow="visible">
      <defs>
        <rect id={ID.current.lineShape} x="0" y="5.5" width="100%" height="1" />
        <circle
          id={ID.current.dotShape}
          cx={dot === 'left' ? '0' : '100%'}
          cy="6"
          r="6"
        />
        <linearGradient id={ID.current.gradient} x1="0" y1="0" x2="100%" y2="0">
          {gradientNodes}
        </linearGradient>
      </defs>
      <use
        href={`#${ID.current.lineShape}`}
        fill={`url(#${ID.current.gradient})`}
      />
      <use
        href={`#${ID.current.dotShape}`}
        fill="#010508"
        stroke="#FFF"
        strokeWidth="1"
      />
    </svg>
  )
}
