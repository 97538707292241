import { YearnAeraVault } from '@/types/yearnAera'
import { useDeploymentSetConfig } from './useDeploymentSetConfig'
import useChainDetection from '@/hooks/useChainDetection'
import { SupportedChainId } from '@/constants/chains'
import {
  YEARN_AERA_VAULT_RSWELL_HOLESKY,
  YEARN_AERA_VAULT_RSWELL_MAINNET,
  YEARN_AERA_VAULT_SWBTC_HOLESKY,
  YEARN_AERA_VAULT_SWBTC_MAINNET,
  YEARN_AERA_VAULT_SWBTC_SEPOLIA,
} from '@/constants/yearnAera'

export function useSwBTCBackendURLs() {
  const { v3BackendLrtUrl, v3BackendLstUrl } = useDeploymentSetConfig()

  return {
    pointsURL: v3BackendLstUrl, // sentio points (not symbiotic vault points)

    statsURL: v3BackendLrtUrl, // LRT stats and symbiotic vault points (not sentio)
  }
}

export function useRswellBackendURLs() {
  const { v3BackendLrtUrl } = useDeploymentSetConfig()

  return {
    pointsURL: v3BackendLrtUrl,
    statsURL: v3BackendLrtUrl,
  }
}

type VaultDeploymentResult = {
  valid: boolean
  vault: YearnAeraVault
}
export function useSwBTCVaultDeployment(): VaultDeploymentResult {
  const { chainId, deploymentChainId, isTestnetChain, allowTestnet } =
    useChainDetection()

  let vault: YearnAeraVault | undefined
  if (chainId === deploymentChainId) {
    if (chainId === SupportedChainId.MAINNET) {
      vault = YEARN_AERA_VAULT_SWBTC_MAINNET
    } else {
      console.error('Invalid deployment chain id for BTC-LRT vault', chainId)
    }
  }

  if (allowTestnet && isTestnetChain) {
    if (chainId === SupportedChainId.SEPOLIA) {
      vault = YEARN_AERA_VAULT_SWBTC_SEPOLIA
    } else if (chainId === SupportedChainId.HOLESKY) {
      vault = YEARN_AERA_VAULT_SWBTC_HOLESKY
    } else {
      console.error('Invalid testnet chain id for BTC-LRT vault', chainId)
    }
  }

  let valid = true
  if (!vault) {
    valid = false
    console.error('Invalid deployment chain id for BTC-LRT vault', chainId)
    vault = YEARN_AERA_VAULT_SWBTC_MAINNET
  }

  return { valid, vault }
}
export function useRswellVaultDeployment(): VaultDeploymentResult {
  const { chainId, deploymentChainId, isTestnetChain, allowTestnet } =
    useChainDetection()
  let vault: YearnAeraVault | undefined
  if (chainId === deploymentChainId) {
    if (chainId === SupportedChainId.MAINNET) {
      vault = YEARN_AERA_VAULT_RSWELL_MAINNET
    } else {
      console.error('Invalid deployment chain id for RSWELL vault', chainId)
    }
  }

  if (allowTestnet && isTestnetChain) {
    if (chainId === SupportedChainId.SEPOLIA) {
      console.error('Invalid testnet chain id for RSWELL vault', chainId)
    } else if (chainId === SupportedChainId.HOLESKY) {
      vault = YEARN_AERA_VAULT_RSWELL_HOLESKY
    } else {
      console.error('Invalid testnet chain id for RSWELL vault', chainId)
    }
  }

  let valid = true
  if (!vault) {
    valid = false
    console.error('Invalid deployment chain id for RSWELL vault', chainId)
    vault = YEARN_AERA_VAULT_RSWELL_MAINNET
  }

  return { valid, vault }
}
